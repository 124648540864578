import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { QuestionActionType, SurveyObservation } from '../../types'
import InfoIcon from '../../components/InfoIcon'
import { css } from 'styled-components/macro'

type HealthDataSelectorProps = {
	conceptTitle: string
	observations: SurveyObservation[]
	observationId: number
	questionDispatch: React.Dispatch<QuestionActionType>
}

const HealthDataSelector = ({
	conceptTitle,
	observations,
	observationId: currentId,
	questionDispatch,
}: HealthDataSelectorProps) => {
	const [selectedObservation, setSelectedObservation] = useState(observations.find((o) => o.id === currentId)?.title ?? '')
	const [isHealthDataSelected, setIsHealthDataSelected] = useState(currentId > 0 ? true : false)

	const observationSelectHandler = useCallback(() => {
		if (!observations) return

		const observationId =
			observations.find((o) => o.title === selectedObservation)?.id ?? 0

		switch (true) {
			case typeof observationId != 'undefined' && observationId >= 0:
				if (currentId !== observationId) {
					questionDispatch({
						type: 'updateQuestion',
						payload: { observationId },
					})
				}

				break

			default:
				throw new Error('Invalid observation id')
		}
	}, [currentId, observations, questionDispatch, selectedObservation])

	const handleIsHealthData = useCallback(() => {
		if (isHealthDataSelected) setSelectedObservation('')

		setIsHealthDataSelected(!isHealthDataSelected)
	}, [isHealthDataSelected])

	useEffect(
		() => observationSelectHandler(),
		[observationSelectHandler, observations, selectedObservation]
	)

	return (
		<div
			id='healthdata-selector-container'
			css={css`
				border: 1px solid black;
				padding: 1.5rem;
				border-radius: 0.5rem;
				background-color: #2888da1a;

				#healthdata-container {
					display: flex;
					align-items: flex-end;
					margin: 1rem 0 0 0;
				}

				#concept-display {
					margin: 1rem 0 0 0;
					width: fit-content;
					background-color: #f8f9fa;
					padding: 0 0.5rem;
					border: 1px solid #dee2e6;
					border-radius: 0.3em;
					inner-shadow: 0 0 0.5rem #dee2e6;
				}

				#concept-display-text {
					margin: 0.5rem;
					font-size: 1.2rem;
					font-weight: normal;
					text-transform: capitalize;
					font-style: italic;
				}

				.option-selector {
					display: flex;
					flex-direction: column;
				}

				#isHealthdata-toggle {
					margin: 0 0 0 1rem;
					width: 2rem;
				}

				#concept-select {
					text-transform: capitalize;
					width: 14rem;
				}

				#observation-select {
					text-transform: capitalize;
					width: 20rem;
				}

				#selection-display {
					width: fit-content;
					margin: 1rem 0 0 0;
					padding: 0.5rem;
					color: white;
					border-radius: 0.3em;
					background-color: ${selectedObservation !== ''
						? '#28a745'
						: '#ed2323cc'};
				}

				#info-button {
					margin-left: 1rem;
				}

				#concept-not-found {
					font-size: 1rem;
					color: red;
				}

				@media (max-width: 790px) {
					#healthdata-container {
						display: block;
					}
				}
			`}
		>
			<div
				css={css`
					display: flex;
				`}
			>
				<label>Is this a health data related question?</label>
				<input
					id='isHealthdata-toggle'
					type='checkbox'
					defaultChecked={isHealthDataSelected}
					onChange={() => handleIsHealthData()}
				></input>
			</div>

			{isHealthDataSelected && (
				<Fragment>
					<div id='concept-display'>
						<h3
							id='concept-display-text'
							css={css``}
						>{`Selected concept: ${conceptTitle}`}</h3>
					</div>

					{conceptTitle !== '[None selected]' ? (
						<Fragment>
							<div id='healthdata-container'>
								<div className='option-selector'>
									<label htmlFor='observation-select'>Observation</label>
									<select
										name='observation-select'
										id='observation-select'
										value={selectedObservation}
										onChange={(evt) => setSelectedObservation(evt.target.value)}
									>
										<option value='' />
										{observations.map((observation, i) => (
											<option
												key={`observation-option-${i}`}
												value={observation.title}
											>{`${observation.code} - ${observation.title}`}</option>
										))}
									</select>
								</div>
								<div id='info-button'>
									<InfoIcon
										width='25px'
										description={`Select an observation to associate with this question. This is required for all health data related questions. \n\n
						The observation selection is assigned via the chosen Concept in the Editor.
						`}
									/>
								</div>
							</div>

							<p id='selection-display'>
								{'Selected Observation: '}
								{selectedObservation !== ''
									? selectedObservation
									: 'None (required)'}
							</p>
						</Fragment>
					) : (
						<p id='concept-not-found'>
							*Please select a Concept in the editor before proceeding
						</p>
					)}
				</Fragment>
			)}
		</div>
	)
}

export default HealthDataSelector
