import * as React from 'react'
import { css } from 'styled-components/macro'

import {
	ChoiceType,
	QuestionActionType,
	SurveyDefinitionQuestionType,
	isBasicQuestionType,
	isChoiceType,
	isChoicesQuestionType,
} from '../types'

const VariantSelect: React.FC<VariantSelectProps> = ({
	questionDispatch,
	variantObject,
	choiceIndex,
}) => {
	const onVariantChange = (newType: string) => {
		const choicesVariants = ['radio', 'checkbox', 'slider']

		if (
			isChoicesQuestionType(variantObject) ||
			isBasicQuestionType(variantObject)
		) {
			if (
				newType === 'basic' &&
				!window.confirm(
					`Are you sure you wish to change to basic question type?\nAll changes that have been made to this questions choices will be lost.`
				)
			) {
				return
			}

			if (
				choicesVariants.includes(newType) &&
				variantObject.choicesVariant === 'basic' &&
				!window.confirm(
					`Are you sure you wish to change from basic question type?\nAll changes that have been made to this questions BMI ranges will be lost.`
				)
			) {
				return
			}

			questionDispatch({
				type: 'updateQuestionType',
				payload: newType,
			})
		} else if (isChoiceType(variantObject)) {
			questionDispatch({
				type: 'updateChoice',
				payload: {
					choiceIndex:
						typeof choiceIndex === 'number' && choiceIndex >= 0
							? choiceIndex
							: variantObject.order,
					choicesVariant: newType,
				},
			})
		}
	}

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin: 1rem 0;
			`}
		>
			<label
				css={css`
					font-size: 0.9rem;
				`}
				htmlFor='type-select'
			>
				Variant
			</label>
			<select
				name='type-select'
				id='type-select'
				value={variantObject.choicesVariant}
				onChange={(event) => onVariantChange(event.currentTarget.value)}
			>
				<option value='checkbox'>Multiple choice</option>
				<option value='radio'>Single choice</option>
				<option value='slider'>Slider</option>
				{!isChoiceType(variantObject) ? (
					<option value='basic'>Basic Info</option>
				) : null}
			</select>
		</div>
	)
}

export default VariantSelect

type VariantSelectProps = {
	questionDispatch: React.Dispatch<QuestionActionType>
	variantObject: SurveyDefinitionQuestionType | ChoiceType
	choiceIndex?: number
}
