import React from 'react'
import { useState } from 'react'
import info from '../images/info-icon.svg'
import { css } from 'styled-components/macro'

const infoDescription = css`
	font-size: 0.8rem !important;
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	border: 1px solid #34373b;
	border-radius: 5px;
	padding: 5px;
`

const InfoIcon = ({ description, width }: { description: string, width? :string }) => {
	const [showDescription, setShowDescription] = useState(false)

	return (
		<>
			<img
				src={info}
				alt='infoIcon'
				width={width ?? '15px'}
				onMouseOver={() => setShowDescription(true)}
				onMouseOut={() => setShowDescription(false)}
				onBlur={() => setShowDescription(false)}
				onFocus={() => setShowDescription(true)}
			/>
			{showDescription && <span css={infoDescription}>{description}</span>}
		</>
	)
}

export default InfoIcon
