import { SurveyConcept, ConceptActionType } from '../../types'

const conceptReducer = (draftState: SurveyConcept[] | undefined,
	action: ConceptActionType): SurveyConcept[] => {
	if (!draftState) return []
    
    switch (action.type) {
        case 'setConcepts':
            return action.payload
        default:
            return draftState
    }
}

export default conceptReducer