import * as React from 'react'
import { useLocation, useRouteMatch, useHistory } from 'react-router'
import { css } from 'styled-components/macro'
import color from 'color'

import Button, { ButtonVariant } from '../../components/Button'

const PageMenu: React.FC<{ clearStatusText: () => void }> = ({
	clearStatusText,
}) => {
	const { search } = useLocation()
	const { url } = useRouteMatch()
	const history = useHistory()

	const onPreviewClick = () => {
		window.scrollTo(0, 0)
		clearStatusText()

		history.push({
			pathname: `${url}/tagpreview`,
			search: `${search}`,
		})
	}

	const onTranslateClick = () => {
		window.scrollTo(0, 0)
		clearStatusText()

		history.push({
			pathname: `${url}/translations`,
			search,
		})
	}

	const onTagRuleClick = () => {
		window.scrollTo(0, 0)
		clearStatusText()

		history.push({
			pathname: `${url}/tagrules`,
			search,
		})
	}

	return (
		<div
			css={css`
				display: flex;
				justify-content: center;
				padding: 1rem;
				background-color: #efefef;

				> button {
					padding: 0.5rem 1rem;
					color: #499dff;
					text-decoration: none;
					border-radius: 0;

					&:not(:last-child) {
						border-right: 2px solid #acacac;
					}

					&:hover {
						color: ${color('#499dff').darken(0.2).hex()};
					}
				}
			`}
		>
			<Button
				type='button'
				variant={ButtonVariant.icon}
				onClick={onTranslateClick}
			>
				Translate survey
			</Button>
			<Button
				type='button'
				variant={ButtonVariant.icon}
				onClick={onPreviewClick}
			>
				Preview tag combinations
			</Button>
			<Button
				type='button'
				variant={ButtonVariant.icon}
				onClick={onTagRuleClick}
			>
				Edit tag rules
			</Button>
		</div>
	)
}

export default PageMenu
