import { HealthData } from './HealthDataTable'
import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useState,
	MouseEvent,
	SyntheticEvent,
} from 'react'
import { css } from 'styled-components/macro'
import addNew from '../../images/addNew.svg'
import deleteIcon from '../../images/delete-red.svg'
import { capitalize } from 'lodash'
import React from 'react'

const iconStyle = css`
	display: block;
	background-size: cover;
	width: 20px;
	height: 20px;
	border: none;
`

const removeBtnStyle = css`
	display: block;
	background-size: cover;
	background-color: transparent;
	width: 20px;
	height: 20px;
	border: none;
	margin-left: 5px;
`

export enum Outcome {
	Good = 'good',
	Borderline = 'borderline',
	Bad = 'bad',
}

const ThresholdInput: FC<ThresholdInputProps> = ({
	threshold,
	index,
	addThresholdEntry,
	removeThresholdEntry,
	minimum,
	maximum,
	toggleMax,
}) => {
	const handleAddThresholdEntry = (
		key: string,
		event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		return addThresholdEntry(key, index, event, minimum, maximum)
	}

	const [maxIsEnabled, setMaxIsEnabled] = useState(
		threshold?.max ? true : false
	)

	const handleEnableMax = useCallback(
		(index: number) => {
			toggleMax(index, maxIsEnabled)
			return setMaxIsEnabled(!maxIsEnabled)
		},
		[maxIsEnabled, toggleMax]
	)

	const handleClick = useCallback((e: MouseEvent<HTMLInputElement>): void => {
		e.currentTarget.select()
	}, [])

	const currentOutcome =
		threshold?.outcome?.toString().toLocaleLowerCase() ?? ''

	return (
		<section
			css='
		.thresholdMinMax{width: 50px;}
		'
		>
			<label htmlFor='description' key='label_description'>
				Description:
				<input
					type='text'
					id='description'
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						handleAddThresholdEntry('description', event)
					}
					value={
						threshold && typeof threshold.description === 'string'
							? threshold.description
							: ''
					}
				/>
			</label>
			<div
				className='threshold'
				key={`threshold_${threshold ? threshold.id : index}`}
			>
				<label htmlFor='min' key='label_min'>
					Min:
					<input
						type='number'
						id='min'
						className='thresholdMinMax'
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							handleAddThresholdEntry('min', event)
						}
						onClick={handleClick}
						min={minimum}
						value={
							threshold && typeof threshold.min === 'number'
								? threshold?.min
								: minimum
						}
					/>
				</label>
				{maxIsEnabled ? (
					<label htmlFor='max' key='label_max'>
						Max:
						<input
							type='number'
							id='max'
							className='thresholdMinMax'
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								handleAddThresholdEntry('max', event)
							}
							onClick={handleClick}
							max={maximum}
							value={
								threshold && typeof threshold.max === 'number'
									? threshold.max
									: ''
							}
						/>
						<button
							css={css`
								margin-left: 0.2em;
							`}
							onClick={() => handleEnableMax(index)}
						>
							X
						</button>
					</label>
				) : (
					<button onClick={() => handleEnableMax(index)}>Add Max</button>
				)}
				<label htmlFor='outcome' key='label_outcome'>
					Outcome:
					<select
						id='outcome'
						onChange={(event: ChangeEvent<HTMLSelectElement>) =>
							handleAddThresholdEntry('outcome', event)
						}
						value={currentOutcome}
					>
						<option></option>
						<option value={Outcome.Bad}>{capitalize(Outcome.Bad)}</option>
						<option value={Outcome.Borderline}>
							{capitalize(Outcome.Borderline)}
						</option>
						<option value={Outcome.Good}>{capitalize(Outcome.Good)}</option>
					</select>
				</label>
				{threshold && (
					<button
						title='remove threshold'
						type='button'
						css={css`
							${removeBtnStyle}
						`}
						onClick={() => removeThresholdEntry(index)}
					>
						<span
							css={`
								${iconStyle} background-image: url(${deleteIcon});
								:hover {
									cursor: pointer;
								}
							`}
						></span>
					</button>
				)}
			</div>
		</section>
	)
}

const ThresholdInputs: FC<ThresholdInputsProps> = ({
	thresholds,
	thresholdCount,
	addThreshold,
	...rest
}) => {
	const [isPreviousFilled, setIsPreviousFilled] = useState(false)

	const handleIsPreviousFilled = useCallback(() => {
		const lastThreshold = thresholds[thresholds.length - 1]

		if (lastThreshold === null || lastThreshold === undefined) {
			return false
		}

		const isFilled = !Object.entries(lastThreshold)
			.filter(([key, _]) => ['description', 'outcome', 'min'].includes(key))
			.some(([_, value]) => value === undefined || value === '')

		const descriptionVal = Object.entries(lastThreshold).find(
			([key]) => key === 'description'
		)?.[1]

		const descriptionNotEmpty =
			descriptionVal !== undefined && descriptionVal !== ''

		const validated = isFilled && descriptionNotEmpty

		if (validated !== isPreviousFilled) {
			setIsPreviousFilled(validated)
		}
	}, [isPreviousFilled, thresholds])

	const getMinimumBoundary = useCallback(
		(index: number) => {
			if (index > 0) {
				if (!thresholds[index - 1].max)
					return thresholds[index - 1].min as string
				else return thresholds[index - 1].max as string
			}

			return ''
		},
		[thresholds]
	)

	const getMaximumBoundary = useCallback(
		(index: number) =>
			index < thresholds.length - 1
				? (thresholds[index + 1].min as string)
				: '',
		[thresholds]
	)

	useEffect(() => {
		if (thresholds.length) {
			handleIsPreviousFilled()
		}
	}, [thresholds, handleIsPreviousFilled])

	return (
		<>
			{thresholds.map((_, index) => (
				<ThresholdInput
					key={`thresholdElement_${thresholds && thresholds[index] && thresholds[index].id ? thresholds[index].id : thresholdCount + index}`}
					threshold={thresholds[index]}
					thresholdCount={thresholdCount}
					index={index}
					minimum={getMinimumBoundary(index)}
					maximum={getMaximumBoundary(index)}
					{...rest}
				/>
			))}

			{isPreviousFilled && (
				<button
					title='addBtn'
					type='button'
					css={css`
						background: none;
						border: none;
					`}
					onClick={() => {
						addThreshold()
						setIsPreviousFilled(false)
					}}
				>
					<span
						css={`
							${iconStyle} background-image: url(${addNew});
							:hover {
								cursor: pointer;
							}
						`}
					></span>
				</button>
			)}
		</>
	)
}

const Threshold: FC<ThresholdProps> = ({
	addThreshold,
	removeThreshold,
	updateThreshold,
	thresholds,
}) => {
	const thresholdCount = thresholds.length

	const toggleMax = useCallback(
		(index: number, isToggled: boolean) => {
			const updatedThresholds = [...thresholds]

			const maxVal = !isToggled ? thresholds[index].min : undefined

			const updatedThreshold: HealthData = {
				...updatedThresholds[index],
				max: maxVal,
			}

			updatedThresholds[index] = updatedThreshold

			updateThreshold({ threshold: { ...updatedThreshold }, index })
			return updatedThresholds
		},
		[thresholds, updateThreshold]
	)

	const addThresholdEntry = useCallback(
		(
			key: string,
			index: number,
			event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
			minimum: string,
			maximum: string
		) => {
			if (!['description', 'min', 'max', 'outcome'].includes(key)) return

			const target = event.target as HTMLInputElement

			const resolveMinimum = (str: string) => {
				if (str === '') return minimum === '' ? undefined : parseFloat(minimum)
				return parseFloat(str)
			}

			const resolveMaximum = (str: string) => {
				if (str === '') return maximum === '' ? undefined : parseFloat(maximum)
				return parseFloat(str)
			}

			const getInputValue = (key: string) => {
				switch (key) {
					case 'min':
						return resolveMinimum(target.value)
					case 'max':
						return resolveMaximum(target.value)
					default:
						return target.value
				}
			}

			const inputValue = getInputValue(key)
			const updatedThresholds = [...thresholds]

			let updatedThreshold = {
				...updatedThresholds[index],
				[key]: inputValue ?? target.value,
			}

			const keyVals = Object.entries(updatedThreshold)
			if (!keyVals.some(([key]) => key === 'outcome')) {
				keyVals.push(['outcome', ''])
				updatedThreshold = Object.fromEntries(keyVals)
			}

			updatedThresholds[index] = updatedThreshold

			updateThreshold({ threshold: { ...updatedThreshold }, index })
			return updatedThresholds
		},
		[updateThreshold, thresholds]
	)

	const removeThresholdEntry = useCallback(
		(index: number) => removeThreshold(index),
		[removeThreshold]
	)

	return (
		<ThresholdInputs
			thresholds={thresholds}
			removeThresholdEntry={removeThresholdEntry}
			addThresholdEntry={addThresholdEntry}
			addThreshold={addThreshold}
			thresholdCount={thresholdCount}
			toggleMax={toggleMax}
		/>
	)
}

export default Threshold

interface ThresholdProps {
	addThreshold: () => void
	removeThreshold: (index: number) => void
	updateThreshold: (item: { threshold: HealthData; index: number }) => void
	thresholds: HealthData[]
}

interface ThresholdInputsProps {
	threshold?: HealthData
	thresholds: HealthData[]
	thresholdCount: number
	removeThresholdEntry: (index: number) => void
	addThresholdEntry: (
		key: string,
		index: number,
		event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
		minimum: string,
		maximum: string
	) => void
	addThreshold: () => void
	toggleMax: (
		index: number,
		isEnabled: boolean,
		minimum?: string
	) => HealthData[]
}

interface ThresholdInputProps {
	threshold?: HealthData
	thresholdCount: number
	index: number
	removeThresholdEntry: (index: number) => void
	addThresholdEntry: (
		key: string,
		index: number,
		event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
		minimum: string,
		maximum: string
	) => void
	minimum: string
	maximum: string
	toggleMax: (
		index: number,
		isEnabled: boolean,
		minimum?: string
	) => HealthData[]
}
