//
// 1000039  Helkropp   - whole body
// 1000041  Underkropp - lower body
// 1000042  Overkropp  - upper body
//

const focusOptions = [
	{
		id: '1000039',
		description: 'Whole body',
	},
	{
		id: '1000042',
		description: 'Upper body',
	},
	{
		id: '1000041',
		description: 'Lower body',
	},
	{
		id: '1000018',
		description: 'Head',
	},
	{
		id: '1000035',
		description: 'The Vestibular System',
	},
	{
		id: '1000032',
		description: 'Face',
	},
	{
		id: '1000034',
		description: 'Jaw',
	},
	{
		id: '1000036',
		description: 'Mouth, tongue, throat',
	},
	{
		id: '1000037',
		description: 'Eyes',
	},
	{
		id: '10',
		description: 'Neck',
	},
	{
		id: '1000021',
		description: 'Neck -flexors',
	},
	{
		id: '1000022',
		description: 'Neck -extensors',
	},
	{
		id: '1000023',
		description: 'Neck -rotators',
	},
	{
		id: '1000024',
		description: 'Neck -lateral flexors',
	},
	{
		id: '1000027',
		description: 'Nerve',
	},
	{
		id: '1000004',
		description: 'Columna',
	},
	{
		id: '1000005',
		description: 'Columna -cervicalis',
	},
	{
		id: '1000006',
		description: 'Columna -thoracalis',
	},
	{
		id: '1000007',
		description: 'Columna -lumbalis',
	},
	{
		id: '13',
		description: 'Back',
	},
	{
		id: '29',
		description: 'Back -superficial upper part',
	},
	{
		id: '30',
		description: 'Back -superficial lower part',
	},
	{
		id: '76',
		description: 'Back -deep part',
	},
	{
		id: '12',
		description: 'Chest',
	},
	{
		id: '11',
		description: 'Shoulder',
	},
	{
		id: '64',
		description: 'Shoulder -flexors',
	},
	{
		id: '72',
		description: 'Shoulder -extensors',
	},
	{
		id: '73',
		description: 'Shoulder -adductors',
	},
	{
		id: '74',
		description: 'Shoulder -abductors',
	},
	{
		id: '75',
		description: 'Shoulder -internal rotators',
	},
	{
		id: '80',
		description: 'Shoulder -external rotators',
	},
	{
		id: '1000014',
		description: 'Shoulder -protraction',
	},
	{
		id: '1000015',
		description: 'Shoulder -retraction',
	},
	{
		id: '1000009',
		description: 'Shoulder -traction',
	},
	{
		id: '1000010',
		description: 'Shoulder -depression',
	},
	{
		id: '1000011',
		description: 'Shoulder -elevation',
	},
	{
		id: '14',
		description: 'Upper arm - Elbow',
	},
	{
		id: '31',
		description: 'Upper arm -elbow flexors',
	},
	{
		id: '32',
		description: 'Upper arm -elbow extensors',
	},
	{
		id: '15',
		description: 'Forearm - Wrist',
	},
	{
		id: '81',
		description: 'Forearm -wrist flexors',
	},
	{
		id: '82',
		description: 'Forearm -wrist extensors',
	},
	{
		id: '83',
		description: 'Forearm -pronators',
	},
	{
		id: '84',
		description: 'Forearm -supinators',
	},
	{
		id: '1000016',
		description: 'Hand',
	},
	{
		id: '1000026',
		description: 'Finger',
	},
	{
		id: '16',
		description: 'Abdomen',
	},
	{
		id: '87',
		description: 'Abdomen -straight part',
	},
	{
		id: '88',
		description: 'Abdomen -oblique part',
	},
	{
		id: '105',
		description: 'Abdomen -transverse part',
	},
	{
		id: '1000017',
		description: 'Diaphragm',
	},
	{
		id: '1000020',
		description: 'Pelvic floor',
	},
	{
		id: '17',
		description: 'Hip',
	},
	{
		id: '33',
		description: 'Hip -flexors',
	},
	{
		id: '34',
		description: 'Hip -extensors',
	},
	{
		id: '67',
		description: 'Hip -adductors',
	},
	{
		id: '68',
		description: 'Hip -abductors',
	},
	{
		id: '79',
		description: 'Hip -internal rotators',
	},
	{
		id: '85',
		description: 'Hip -external rotators',
	},
	{
		id: '1000013',
		description: 'Hip -traction',
	},
	{
		id: '18',
		description: 'Thigh - Knee',
	},
	{
		id: '35',
		description: 'Thigh -knee flexors',
	},
	{
		id: '36',
		description: 'Thigh -knee extensors',
	},
	{
		id: '19',
		description: 'Leg - Ankle',
	},
	{
		id: '39',
		description: 'Leg -dorsal flexors',
	},
	{
		id: '40',
		description: 'Leg -plantar flexors',
	},
	{
		id: '58',
		description: 'Leg -stabilizing muscles',
	},
	{
		id: '101',
		description: 'Foot',
	},
	{
		id: '1000019',
		description: 'Nerve',
	},
	{
		id: '1000025',
		description: 'Toe',
	},
]

export default focusOptions
