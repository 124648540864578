import React from 'react'
import { css } from 'styled-components/macro'

import { useHandlerDispatch } from '../context/HandlerProvider'

import { ChoiceType } from '../types'

const SliderWithText: React.FC<SliderWithTextProps> = ({
	translations,
	sliderItems,
	potentialAnswerIndex,
	customSliderUpdate,
}) => {
	const min = 0
	const max = sliderItems.length - 1
	const sliderRange = max - min

	const position = ((potentialAnswerIndex - min) / sliderRange) * 100
	const positionOffset = Math.round((24 * position) / 100) - 24 / 2

	const sliderIngress =
		translations?.[sliderItems?.[potentialAnswerIndex]?.ingressTextKey]

	return (
		<div
			css={css`
				padding: 1rem 0;
			`}
		>
			<div
				css={css`
					display: flex;
					justify-content: space-between;
				`}
			>
				<span
					css={css`
						color: ${({ theme }) => theme.fadedTextColor};
					`}
				>
					{translations[sliderItems[0].headerTextKey]}
				</span>
				<span
					css={css`
						color: ${({ theme }) => theme.fadedTextColor};
					`}
				>
					{translations[sliderItems[sliderItems.length - 1].headerTextKey]}
				</span>
			</div>
			<Slider
				sliderVal={potentialAnswerIndex}
				sliderItems={sliderItems}
				customSliderUpdate={customSliderUpdate}
			/>
			<div
				css={css`
					position: relative;
					justify-content: center;
					background-color: #ffffff;
					border: 1px solid #c0c0c0;
					border-radius: 7px;
					margin: 0 1rem;
					padding-inline: 1rem;
					text-align: center;
				`}
			>
				<span
					id='textbox-arrow'
					css={css`
						left: calc(${position}% - (${positionOffset}px));
						position: absolute;
						content: '';
						top: -0.55rem;
						width: 1rem;
						height: 1rem;
						transform: translateX(-50%) rotate(45deg);
						background-color: white;
						border-top: 1px solid #c0c0c0;
						border-left: 1px solid #c0c0c0;
						border-radius: 4px 0 0 0;
					`}
				/>
				<p
					css={css`
						font-weight: 600;
					`}
				>
					{translations?.[sliderItems?.[potentialAnswerIndex]?.headerTextKey]}
				</p>
				{sliderIngress ? (
					<p
						css={css`
							color: rgba(0, 0, 0, 0.67);
							white-space: pre;
						`}
					>
						{sliderIngress}
					</p>
				) : null}
			</div>
		</div>
	)
}

const Slider: React.FC<SliderProps> = ({
	sliderVal,
	sliderItems,
	customSliderUpdate,
}) => {
	const handlerDispatch = useHandlerDispatch()

	const onSliderUpdate = (choiceIndex: number) => {
		handlerDispatch({
			type: 'updatePotentialAnswers',
			payload: {
				sliderIndex: choiceIndex,
			},
		})
	}

	return (
		<div
			css={css`
				padding: 0 1rem;
				margin-bottom: 1rem;
			`}
		>
			<input
				css={inputStyle}
				type='range'
				name='slider'
				min='0'
				value={sliderVal}
				max={sliderItems.length - 1}
				onChange={(event) =>
					customSliderUpdate
						? customSliderUpdate(parseInt(event.target.value))
						: onSliderUpdate(parseInt(event.target.value))
				}
			/>
			<div
				css={css`
					display: flex;
					justify-content: space-between;
					margin-top: -3.25rem;

					@media only screen and (min-width: 766px) {
						margin-top: -3.5rem;
					}
				`}
			>
				{sliderItems.map((sliderItem) => (
					<p
						key={sliderItem.id}
						css={css`
							position: relative;
							display: flex;
							justify-content: center;
							text-align: center;
							width: 0.5rem;
							height: 0.5rem;
							border-radius: 50%;
							background: #c0c0c0;
							line-height: 40px;
						`}
					></p>
				))}
			</div>
		</div>
	)
}

export default SliderWithText

type SliderProps = {
	sliderVal: number
	sliderItems: Array<ChoiceType>
	customSliderUpdate?: (args: number) => void
}

type SliderWithTextProps = {
	translations: {
		[translationKey: string]: string
	}
	sliderItems: ChoiceType[]
	potentialAnswerIndex: number
	customSliderUpdate?: (args: number) => void
}

/* Start of styling */

const inputStyle = css`
	appearance: none;
	margin: 1rem 0;
	width: 100%;
	box-sizing: border-box;
	display: table-cell;
	height: 2rem;
	background: transparent;
	padding: 0;
	vertical-align: middle;
	position: relative;
	z-index: 1000;

	/* Webkit specific styling */
	&::-webkit-slider-runnable-track {
		background: #c0c0c0;
		height: 3.5px;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 1.5rem;
		width: 1.5rem;
		margin-top: -0.7rem;
		border: 0;
		background-color: ${({ theme }) => theme.secondaryColor};
		border-radius: 1rem;
	}

	/* Firefox specific styling */
	&::-moz-range-thumb {
		height: 1.5rem;
		width: 1.5rem;
		border: 0;
		background-color: ${({ theme }) => theme.secondaryColor};
		border-radius: 1rem;
	}

	&::-moz-range-track {
		background: #c0c0c0;
		height: 3.5px;
	}

	/* Explorer specific styling */
	&::-ms-tooltip {
		display: none;
	}

	&::-ms-track {
		border-color: transparent;
		background: transparent;
		color: transparent;
		height: 3.5px;
	}

	&::-ms-thumb {
		box-sizing: border-box;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.secondaryColor};
		margin: 0;
	}

	&::-ms-fill-upper {
		background: #c0c0c0;
		border-radius: 1rem;
	}

	&::-ms-fill-lower {
		background: #c0c0c0;
		border-radius: 1rem;
	}
`

/* End of styling */
