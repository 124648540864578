import React, { useState } from 'react'
import { css } from 'styled-components/macro'
import { highLightStyle } from '../../FormDialog'
import InputControlOptions from './InputControlOptions'
import { HealthDataModelType } from '../../globaltypes'
import RelationalUpdater from '../../Form/RelationalContent/RelationalUpdater'
import { EditDialogProps } from './types'

const ConceptInputEdit = ({
	editedRow,
	editRowData,
	data,
	relationalEditState: state,
	relationalDataHandlers,
}: EditDialogProps) => {
	const [highlightedElement, setHighlightedElement] = useState<string>('')

	return (
		<>
			{Object.entries(editedRow)
                .slice(1)
				.map(([key, value], cellIndex) => {
					const uniqueId = `${key}${cellIndex}`
					let inputType = 'text'
					if (typeof value === 'number' && key === 'interval') {
						inputType = 'number'
					}
					return key === 'type' ? (
						<label
							htmlFor='type'
							key={`${uniqueId}_select`}
							onFocus={() => setHighlightedElement(uniqueId)}
							css={css`
								${highlightedElement === uniqueId ? highLightStyle : ''}
							`}
						>
							Type:
							<select
								title='type'
								id='type'
								onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
									editRowData(key, event)
								}
								required
							>
								<option value={value?.toString()}>
									{value?.toString().toUpperCase()}
								</option>
								<option value='SUM of observations'>SUM of observations</option>
								<option value='SUM of depressionscale'>
									SUM of depressionscale
								</option>
								<option value='SUM of agstscaleT'>SUM of agstscaleT</option>
								<option value='number'>number</option>
								<option value='points'>points</option>
							</select>
						</label>
					) : (
						<label
							htmlFor={`${uniqueId}_input`}
							key={`${uniqueId}_label`}
							onFocus={() => setHighlightedElement(uniqueId)}
							css={css`
								${highlightedElement === uniqueId ? highLightStyle : ''}
							`}
						>
							{key.toUpperCase()}:
							<input
								title={uniqueId}
								id={`${uniqueId}_input`}
								key={uniqueId}
								type={inputType}
								{...(key === 'category' && {
									list: 'categories',
								})}
								defaultValue={value?.toString().toUpperCase()}
								{...(typeof value === 'boolean' && {
									defaultChecked: value,
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									editRowData(key, event)
								}
								autoFocus={cellIndex === 0}
								required
								{...(inputType === 'number' && {
									step: '0.01',
								})}
							/>
							{key === 'category' && (
								<InputControlOptions
									id='categories'
									providesFor='category'
									data={data}
								/>
							)}
						</label>
					)
				})}

			<RelationalUpdater
				updateHandlers={relationalDataHandlers}
				state={state}
				context={HealthDataModelType.Concept}
			/>
		</>
	)
}

export default ConceptInputEdit
