import React from 'react'
import { css } from 'styled-components/macro'
import Tab from './Tab'
import { useEditorState } from '../context/EditorProvider'
import { useHistory } from 'react-router-dom'

const Topbar: React.FC = () => {
	const { surveyDefinition } = useEditorState()
	const history = useHistory()

	return (
		<div
			css={css`
				position: sticky;
				display: flex;
				align-items: center;
				top: 0rem;
				min-height: 1rem;
				background-color: #34373b;
				color: white;
				padding: 0 2rem;
				z-index: 99999;
				height: 5rem;
			`}
		>
			<div
				css={css`
					flex-grow: 1;
					display: flex;
					align-items: center;
					gap: 1rem;
				`}
			>
				<Tab
					tabs={[
						{
							tabId: 'SE_I0',
							tabName: 'Survey Editor',
							route: `${history.location.pathname.slice(1)}${
								history.location.search
							}`,
						},
						{
							tabId: 'HD_I1',
							tabName: 'Health Data',
							route: 'healthdata/observations',
						},
					]}
				/>
				{surveyDefinition &&
				history.location.pathname.startsWith('/editor/') &&
				typeof surveyDefinition.sourceOrgId === 'number' &&
				typeof surveyDefinition.sourceDeptId === 'number' ? (
					<div
						css={css`
							display: flex;
							flex-direction: column;
						`}
					>
						<h4
							css={css`
								margin-bottom: 0;
								margin-top: 0;
							`}
						>
							Loaded Survey:
						</h4>
						<small>Organization id: {surveyDefinition.sourceOrgId}</small>
						<small>Department id: {surveyDefinition.sourceDeptId}</small>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default Topbar
