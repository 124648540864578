import * as React from 'react'
import { useImmerReducer } from 'use-immer'

import handlerReducer from './reducers/handlerReducer'

import { HandlerDispatchType, HandlerStateType } from '../types'

const HandlerStateContext = React.createContext<HandlerStateType | undefined>(
	undefined
)
const HandlerDispatchContext = React.createContext<
	HandlerDispatchType | undefined
>(undefined)

const HandlerProvider = ({
	answerData,
	children,
}: HandlerProviderProps): React.ReactElement => {
	const [handlerState, handlerDispatch] = useImmerReducer(
		handlerReducer,
		answerData
	)

	return (
		<HandlerStateContext.Provider value={handlerState}>
			<HandlerDispatchContext.Provider value={handlerDispatch}>
				{children}
			</HandlerDispatchContext.Provider>
		</HandlerStateContext.Provider>
	)
}

const useHandlerState = (): HandlerStateType => {
	const context = React.useContext(HandlerStateContext)

	if (context === undefined) {
		throw new Error('useHandlerState must be used within a HandlerProvider')
	}

	return context
}

const useHandlerDispatch = (): HandlerDispatchType => {
	const context = React.useContext(HandlerDispatchContext)

	if (context === undefined) {
		throw new Error('useHandlerDispatch must be used within a HandlerProvider')
	}

	return context
}

export { HandlerProvider, useHandlerState, useHandlerDispatch }

type HandlerProviderProps = {
	children: React.ReactNode
	answerData: HandlerStateType
}
