import React from 'react'
import { css } from 'styled-components/macro'

const Spinner: React.FC = () => (
	<div css={spinnerStyle}>
		<div className='loader'>
			<svg className='loader_circular' viewBox='25 25 50 50'>
				<circle
					className='loader_path'
					cx='50'
					cy='50'
					r='20'
					fill='none'
					strokeWidth='3'
					strokeMiterlimit='10'
				/>
			</svg>
		</div>
	</div>
)

const spinnerStyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-direction: column;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: -1;

	.loader {
		position: relative;
		margin: 0 auto;
		width: 75px; // can be changed to adjust size
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}

	.loader_circular {
		animation: loader_rotate 2s linear infinite;
		height: 100%;
		transform-origin: center center;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

	.loader_path {
		stroke: ${({ theme }) =>
			theme.secondaryColor}; // this stroke lives in branded styling
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		animation: loader_dash 1.5s ease-in-out infinite;
		stroke-linecap: round;
	}

	@keyframes loader_rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes loader_dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -35px;
		}
		100% {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -124px;
		}
	}
`

export default Spinner
