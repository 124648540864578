const formOptions = [
	{
		id: '1',
		description: 'Strength training',
	},
	{
		id: '9',
		description: 'Stretching exercises',
	},
	{
		id: '5',
		description: 'Cardiovascular exercise',
	},
	{
		id: '1059',
		description: 'Amputation',
	},
	{
		id: '1021',
		description: 'Relief/decompensation',
	},
	{
		id: '1028',
		description: 'Relaxation',
	},
	{
		id: '7',
		description: 'Basic training',
	},
	{
		id: '49',
		description: 'Balance and coordination',
	},
	{
		id: '70',
		description: 'Stability',
	},
	{
		id: '1016',
		description: 'Aquatic exercise',
	},
	{
		id: '200',
		description: 'Blank',
	},
	{
		id: '1053',
		description: 'Crossfit',
	},
	{
		id: '1069',
		description: 'Daily Activity',
	},
	{
		id: '1063',
		description: 'Occupational therapy',
	},
	{
		id: '1070',
		description: 'Motor skills',
	},
	{
		id: '1071',
		description: 'Patient transfer',
	},
	{
		id: '1072',
		description: 'Home exercises',
	},
	{
		id: '1012',
		description: 'Office exercises',
	},
	{
		id: '212',
		description: 'Body awareness and posture',
	},
	{
		id: '210',
		description: 'Mechanical Diagnosis and Therapy (MDT)',
	},
	{
		id: '211',
		description: 'Medical Training Therapy (MTT)',
	},
	{
		id: '1029',
		description: 'Mobilisation',
	},
	{
		id: '1067',
		description: 'Warm up',
	},
	{
		id: '1002',
		description: 'Pilates',
	},
	{
		id: '1086',
		description: 'Active',
	},
	{
		id: '1087',
		description: 'Passive',
	},
	{
		id: '1047',
		description: 'Partner exercises',
	},
	{
		id: '1030',
		description: 'Rehabilitation',
	},
	{
		id: '1019',
		description: 'Respiration',
	},
	{
		id: '1003',
		description: 'Sling exercises',
	},
	{
		id: '1079',
		description: 'Hospital exercises',
	},
	{
		id: '1018',
		description: 'Guidance',
	},
	{
		id: '1032',
		description: 'Yoga',
	},
	{
		id: '1146',
		description: 'ADL (degree of independence)',
	},
	{
		id: '1147',
		description: 'Risk of falling',
	},
	{
		id: '1148',
		description: 'Level of physical function',
	},
]
export default formOptions
