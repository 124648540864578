import * as React from 'react'
import { css } from 'styled-components/macro'
import { CssFunction } from '../types/styled'

type ReactTextArea = React.DetailedHTMLProps<
	React.TextareaHTMLAttributes<HTMLTextAreaElement>,
	HTMLTextAreaElement
>

type TextAreaProps = {
	id: string
	inputCss?: CssFunction
	children?: never
	label?: string
} & ReactTextArea

const TextArea: React.FC<TextAreaProps> = ({
	id,
	inputCss,
	label,
	...props
}) => (
	<div
		css={css`
			display: flex;
			flex-direction: column;
			margin-top: 2rem;
		`}
	>
		{label && (
			<label
				css={css`
					margin: 0 0 0.25rem;
					font-size: 0.9rem;
				`}
				htmlFor={id}
			>
				{label}
			</label>
		)}

		<textarea
			id={id}
			css={[
				css`
					border: 0;
					border-bottom: 2px solid #d3d3d3;
					padding: 10px;
					resize: vertical;
				`,
				inputCss,
			]}
			{...props}
		/>
	</div>
)

export default TextArea
