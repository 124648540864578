import * as React from 'react'
import { css } from 'styled-components/macro'

import { CssFunction } from '../types/styled'

const ToggleButton: React.FC<ToggleButtonProps> = ({
	buttonCss,
	on: initialOn,
	onChange,
	onIcon,
	offIcon,
	...props
}) => {
	const [on, setOn] = React.useState<boolean>(!!initialOn || false)
	return (
		<button
			css={[
				css`
					border-radius: 7px;
					font-weight: 600;
					outline-offset: 5px;
					font-size: 100%;
					background-color: transparent;
					font-family: inherit;
					border: 0;
					padding: 0.5rem;

					&:not(:disabled) {
						&:hover {
							cursor: pointer;
						}

						&:active {
							transform: scale(0.98);
						}
					}
				`,
				buttonCss,
			]}
			type='button'
			role='checkbox'
			aria-checked={on}
			onClick={() =>
				setOn((prev) => {
					if (onChange) {
						onChange(!prev)
					}
					return !prev
				})
			}
			{...props}
		>
			{on ? onIcon : offIcon}
		</button>
	)
}

export default ToggleButton

type ReactButton = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>

type ToggleButtonProps = ReactButton & {
	onIcon: React.ReactElement
	offIcon: React.ReactElement
	on?: boolean
	buttonCss?: CssFunction
	onChange?: (state: boolean) => void
}
