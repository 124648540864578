import { TagsSortedBySkipsType } from '../../../types'

export const getSurveyPaths = (
	tagsSortedBySkips: TagsSortedBySkipsType[],
	preceedingTags: string[][] = []
) => {
	const getPaths = (
		question: TagsSortedBySkipsType,
		questionIndex: number,
		numberOfQuestions: number,
		path: string[][] = []
	): string[][][] => {
		return Object.entries(question).reduce(
			(accumulator: string[][][], [skipValue, tags]: [string, string[]]) => {
				const nextQuestionIndex = questionIndex + parseInt(skipValue) + 1

				if (nextQuestionIndex > numberOfQuestions) {
					//Add preceedingTags here
					let newPath = [...path, tags]

					if (preceedingTags.length) {
						newPath = [...preceedingTags, ...newPath]
					}

					accumulator.push(newPath)
				} else {
					const finishedPath = getPaths(
						tagsSortedBySkips[nextQuestionIndex],
						nextQuestionIndex,
						numberOfQuestions,
						[...path, tags]
					)

					accumulator.push(...finishedPath)
				}

				return accumulator
			},
			[]
		)
	}

	return tagsSortedBySkips.length
		? getPaths(tagsSortedBySkips[0], 0, tagsSortedBySkips.length - 1)
		: [preceedingTags]
}
