import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'promise-polyfill/src/polyfill'
import 'unfetch/polyfill'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'url-search-params-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from 'styled-components/cssprop'

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById('root')
)
