export const getApproxNumberOfEndpoints = (surveyPaths: string[][][]) => {
	const valuesToSum = surveyPaths.map((surveyPath) => {
		return surveyPath.reduce((numberOfTags: number, tags) => {
			return (numberOfTags *= tags.length)
		}, 1)
	})

	return valuesToSum.reduce(
		(sum: number, numberOfPossibleTags: number) =>
			(sum += numberOfPossibleTags),
		0
	)
}
