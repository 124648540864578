import * as React from 'react'
import { css } from 'styled-components/macro'
import color from 'color'
import Button, { ButtonVariant } from '../../components/Button'
import { useHistory, useRouteMatch } from 'react-router-dom'

const PageMenu: React.FC = () => {
	const { url } = useRouteMatch()
	const history = useHistory()

	const showObservations = () => {
		window.scrollTo(0, 0)

		history.push({
			pathname: `${url}/observations`,
		})
	}

	const showGroup = () => {
		window.scrollTo(0, 0)

		history.push({
			pathname: `${url}/group`,
		})
	}

	const showConcept = () => {
		window.scrollTo(0, 0)

		history.push({
			pathname: `${url}/concept`,
		})
	}

	return (
		<div
			css={css`
				display: flex;
				justify-content: center;
				padding: 1rem;
				background-color: #efefef;

				> button {
					padding: 0.5rem 1rem;
					color: #499dff;
					text-decoration: none;
					border-radius: 0;

					&:not(:last-child) {
						border-right: 2px solid #acacac;
					}

					&:hover {
						color: ${color('#499dff').darken(0.2).hex()};
					}
				}
			`}
		>
			<Button
				type='button'
				variant={ButtonVariant.icon}
				onClick={showObservations}
			>
				Observations
			</Button>
			<Button type='button' variant={ButtonVariant.icon} onClick={showGroup}>
				Groups
			</Button>
			<Button type='button' variant={ButtonVariant.icon} onClick={showConcept}>
				Concept
			</Button>
		</div>
	)
}

export default PageMenu
