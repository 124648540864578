import { useEffect, useCallback, RefObject } from 'react'

export default function useDetectOutsideClick(
	ref: RefObject<HTMLDivElement>,
	closeFunction: () => void
) {
	const handleHideDropdown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				event.stopPropagation()
				closeFunction()
			}
		},
		[closeFunction]
	)

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				event.stopPropagation()
				closeFunction()
			}
		},
		[ref, closeFunction]
	)

	useEffect(() => {
		document.addEventListener('keydown', handleHideDropdown, true)
		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('keydown', handleHideDropdown, true)
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [handleHideDropdown, handleClickOutside])
}
