import { Row } from 'react-table'

export const guidSearch = (rows: Row[], id: string[], filterValue: string) => {
	const filteredRows = rows.filter((row) => {
		const rowValue = row.values[id[0]]

		if (typeof rowValue === 'string')
			return rowValue.toLowerCase().includes(filterValue.toLowerCase())
		else if (Array.isArray(rowValue)) {
			return rowValue.find(
				(rowValue) =>
					rowValue.title.toLowerCase().includes(filterValue.toLowerCase()) ||
					rowValue.guid === filterValue
			)
		} else return false
	})

	return filteredRows
}
