import React from 'react'
import { HealthData } from '../../HealthDataTable'
import ObservationInputEdit from './ObservationInputEdit'
import ObservationInputAdd from './ObservationInputAdd'
import {
	TRelationalDataHandlers,
	State,
} from '../../../../hooks/useHandleRelationalData'
import { HealthDataModelType } from '../../globaltypes'
import GroupInputAdd from './GroupInputAdd'
import GroupInputEdit from './GroupInputEdit'
import ConceptInputAdd from './ConceptInputAdd'
import ConceptInputEdit from './ConceptInputEdit'

export type FormInputContainerProps = {
	editedRow: HealthData
	data: HealthData[]
	addRowData: (
		key: string,
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
		value?: string
	) => void
	editRowData: (
		key: string,
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => void
	addMode: boolean
	editMode: boolean
	invalidInput?: boolean
	relationalEditState: State
	relationalDataHandlers: TRelationalDataHandlers
	context: string
}

const FormInputContainer = ({
	addMode,
	editMode,
	context,
	...rest
}: FormInputContainerProps) => {
	switch (context) {
		case HealthDataModelType.Observation:
			if (editMode) return <ObservationInputEdit {...rest} />

			if (addMode) return <ObservationInputAdd {...rest} />
			else return <p>Unable to load Observation inputs</p>

		case HealthDataModelType.Group:
			if (editMode) return <GroupInputEdit {...rest} />

			if (addMode) return <GroupInputAdd {...rest} />
			else return <p>Unable to load Group inputs</p>

		case HealthDataModelType.Concept:
			if (editMode) return <ConceptInputEdit {...rest} />

			if (addMode) return <ConceptInputAdd {...rest} />
			else return <p>Unable to load Concept inputs</p>
	}
	throw new Error(`Error occurred whilst loading ${context} Input`)
}

export default FormInputContainer
