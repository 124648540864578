import { TagRuleType } from '../../../types'

export const matchTagCombinationsWithRules = (
	surveyPath: string[][],
	tagRules: TagRuleType[],
	showAllTags: boolean
): { tagCombinations: string[]; processedTagCombinations: string[] } => {
	const tagCombinations: string[] = []
	const processedTagCombinations: string[] = []

	function matchTagCombinationWithRule(
		surveyPaths: string[][],
		whichArray = 0,
		output = ''
	) {
		surveyPaths[whichArray].forEach((arrayElement) => {
			if (whichArray === surveyPath.length - 1) {
				let postProcessedTags = (output + ' ' + arrayElement)
					.split(' ')
					.filter(Boolean)

				tagCombinations.push(postProcessedTags.toString())

				tagRules.forEach((tagRule) => {
					const isMatchingTagRule =
						tagRule.tagFilters.length &&
						tagRule.tagFilters.every((tagFilter) =>
							postProcessedTags.find(
								(postProcessedTag) =>
									postProcessedTag.toLowerCase() === tagFilter.toLowerCase()
							)
						)

					if (isMatchingTagRule) {
						postProcessedTags = [
							...postProcessedTags.filter((unFilteredTag) => {
								return !tagRule.tagFilters.find(
									(tagFilter) =>
										tagFilter.toLowerCase() === unFilteredTag.toLowerCase()
								)
							}),
							...tagRule.tagsToInsert,
						]
					}
				})

				processedTagCombinations.push(postProcessedTags.toString())
			} else {
				matchTagCombinationWithRule(
					surveyPaths,
					whichArray + 1,
					`${output}${output.length ? ' ' : ''}${arrayElement}`
				)
			}
		})
	}

	matchTagCombinationWithRule(surveyPath)

	return {
		tagCombinations: showAllTags
			? tagCombinations
			: Array.from(new Set(tagCombinations)),
		processedTagCombinations: showAllTags
			? processedTagCombinations
			: Array.from(new Set(processedTagCombinations)),
	}
}
