import * as React from 'react'
import { guidSearch, includeExcludeSearch } from './utils'
import { useTable, useFilters, usePagination, FilterProps } from 'react-table'
import { css } from 'styled-components/macro'

import { ReactComponent as SaveIcon } from '../../images/save.svg'
import { ReactComponent as InfoIcon } from '../../images/info-icon.svg'
import { TagPreviewColumnsType } from '../../types'

const Table: React.FC<TableProps> = ({
	columns,
	data,
	totalNumberOfUniqueEndpoints,
	showAllTags,
	workoutLanguages,
}) => {
	const filterTypes = React.useMemo(
		() => ({
			guidSearch,
			includeExcludeSearch,
		}),
		[]
	)

	const defaultColumn = React.useMemo(
		() => ({
			Filter: DefaultColumnFilter,
		}),
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		pageSize,
		state: { pageIndex },
	} = useTable(
		{ columns, data, defaultColumn, filterTypes },
		useFilters,
		usePagination
	)

	const onExportClick = () => {
		const keys = Object.keys(data[0])

		// Build headers
		let result = keys.join(',') + '\n'

		// Add the rows
		data.forEach((column) => {
			result +=
				keys
					.map((key) => {
						const columnData = column[key]

						if (Array.isArray(columnData) && columnData[0]?.guid) {
							return columnData
								.map(
									(workoutProgram: { guid: string; title: string }) =>
										workoutProgram.title
								)
								.join(' ')
						}

						return column[key]
					})
					.join(',') + '\n'
		})

		const csvContent =
			'data:text/csv;charset=utf-8,' + encodeURIComponent(result)
		const link = document.createElement('a')

		link.setAttribute('href', csvContent)
		link.setAttribute('download', 'tag_preview.csv')
		document.body.appendChild(link)
		link.click()
	}

	return (
		<React.Fragment>
			<div
				css={css`
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1px solid black;
					padding: 2rem 0 1rem;
				`}
			>
				<button
					css={css`
						display: flex;
					`}
					onClick={onExportClick}
					type='button'
				>
					<SaveIcon
						css={css`
							margin-right: 0.25rem;
						`}
					/>
					Export tag preview
				</button>
				<p
					css={css`
						padding-left: 1rem;
					`}
				>
					{showAllTags ? (
						''
					) : (
						<React.Fragment>
							<span>{`${data.length.toLocaleString(
								'nb-NO'
							)} processed tags`}</span>
							<span
								css={css`
									margin: 1rem;
								`}
							>
								|
							</span>
						</React.Fragment>
					)}
					<span>
						{totalNumberOfUniqueEndpoints
							? totalNumberOfUniqueEndpoints.toLocaleString('nb-NO')
							: 0}{' '}
						unprocessed tags
					</span>
				</p>
			</div>
			<div>
				<table
					css={css`
						width: 100%;
						border-spacing: 0;

						tr:nth-child(even) {
							background-color: #f2f2f2;
						}

						tr:nth-child(odd) {
							background-color: #ffffff;
						}

						th {
							position: sticky;
							top: 78px;
							border-bottom: 1px solid black;
							text-align: left;
							vertical-align: top;
							padding: 0.5rem 1rem;
							background-color: #d9d8d8;
						}

						td {
							padding: 0.25rem 1rem;
						}
					`}
					{...getTableProps()}
				>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									//zindex
									return (
										<th {...column.getHeaderProps()}>
											{column.render('Header')}
											<div
												css={css`
													display: flex;
													align-items: center;
												`}
											>
												{column.id !== 'index' ? column.render('Filter') : null}
												{column.id === 'processedTagCombination' ||
												column.id === 'unprocessedTagCombination' ? (
													<HoverIcon />
												) : null}
											</div>
										</th>
									)
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell, cellIndex: number) => {
										const toRender = workoutLanguages?.reduce(
											(
												formattedMatchingWorkouts: JSX.Element[],
												workoutLanguage: string
											) => {
												if (
													cell.column.id ===
														'workoutMatches_' + workoutLanguage ||
													cell.column.id === 'planMatches_' + workoutLanguage
												) {
													if (Array.isArray(cell.value) && cell.value?.length) {
														cell.value.forEach((value) => {
															formattedMatchingWorkouts.push(
																<li key={value.title + cellIndex}>
																	<a
																		href={`${
																			process.env.REACT_APP_EXORLIVE_API_URL
																		}/app/${
																			value.type === 'workout'
																				? 'default.aspx#workouts'
																				: '#Plan'
																		}/${value.guid}`}
																		target='blank'
																	>
																		{value.title}
																	</a>
																</li>
															)
														})
													}
												}
												return formattedMatchingWorkouts
											},
											[]
										)

										return (
											<td {...cell.getCellProps()}>
												{toRender?.length ? (
													<ol>{toRender}</ol>
												) : (
													cell.render('Cell')
												)}
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>

			<div
				css={css`
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-top: 1px solid black;
					padding-top: 1rem;

					.goto {
						margin-right: 1rem;
					}

					.paginationWithCount {
						display: flex;
						flex-direction: column;
					}
				`}
			>
				<div>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{'<<'}
					</button>
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						{'<'}
					</button>
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						{'>'}
					</button>
					<button
						onClick={() => {
							gotoPage(pageCount - 1)
						}}
						disabled={!canNextPage}
					>
						{'>>'}
					</button>
				</div>
				<span
					css={css`
						margin-right: 1rem;
					`}
				>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length.toLocaleString('nb-NO')}
					</strong>
				</span>
				<div>
					<select
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[10, 20, 30, 40, 50].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
				</div>
			</div>
		</React.Fragment>
	)
}

const HoverIcon = () => {
	const [hovering, setHovering] = React.useState(false)

	function mouseOver() {
		setHovering(true)
	}

	function mouseLeave() {
		setHovering(false)
	}

	return (
		<div
			css={css`
				position: relative;
				font-size: 0.9rem;

				.pop-up {
					position: absolute;
					background-color: #ffffff;
					left: 30px;
					bottom: -100px;
					width: 15rem;
					border: 1px solid #c0c0c0;
					padding: 0.5rem;

					span {
						font-weight: 400;
					}
				}

				button {
					display: flex;
					background-color: transparent;
					margin: 0;
					padding: 0;
					border: 0;
				}

				&.hidden {
					.pop-up {
						visibility: hidden;
					}
				}
			`}
			className={hovering ? '' : 'hidden'}
			onMouseOver={mouseOver}
			onMouseLeave={mouseLeave}
		>
			<div className='pop-up'>
				<span>
					Filter tag combinations by writing one or more tags separated by
					space. To exclude tags prepend exclamation mark (!). <br /> Example;
					if you write in the field "tagToInclude !tagToExclude", it will only
					list results that contain "tagToInclude" and does not contain
					tagToExclude.
				</span>
			</div>
			<button>
				<InfoIcon />
			</button>
		</div>
	)
}

const DefaultColumnFilter: React.FC<FilterProps<Record<string, unknown>>> = ({
	column: { filterValue, preFilteredRows, setFilter },
}) => {
	const count = preFilteredRows.length

	return (
		<input
			value={filterValue || ''}
			onChange={(event) => {
				setFilter(event.target.value || undefined)
			}}
			placeholder={`Filter ${count} rows...`}
		/>
	)
}

export default Table

type TableProps = {
	columns: TagPreviewColumnsType
	data: DataType
	totalNumberOfUniqueEndpoints: number
	showAllTags: boolean
	workoutLanguages?: string[]
}

type DataType = {
	index?: string
	unprocessedTagCombination?: string
	trainingPrograms?: Array<number>
	numberOfMatches?: number
	[key: string]: unknown
}[]
