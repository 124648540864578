import React, { useCallback } from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { DefaultEntry } from '../../screens/HealthData'

const cssStyle = css`
	::selection {
		color: #fff;
		background: #664aff;
	}

	.container .searchInput {
		background: #fff;
		width: 100%;
		border-radius: 5px;
		box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
	}
	.searchInput {
		position: relative;
	}

	.searchInput input {
		margin: 0 0 1em 0;
		height: 2em;
		width: -webkit-fill-available;
		outline: none;
		border: 1px solid rgba(66, 184, 55, 0.5);
		border-radius: 5px;
		padding: 0 60px 0 20px;
		font-size: 18px;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	}

	.searchInput.active input {
		border-radius: 5px 5px 0 0;
	}

	.search-reset-btn {
		display: none;
	}

	.searchInput .resultBox {
		padding: 0;
		opacity: 0;
		pointer-events: none;
		max-height: 280px;
		overflow-y: auto;
	}

	.searchInput.active .resultBox {
		position: absolute;
		width: -webkit-fill-available;
		background-color: white;
		padding: 10px 8px;
		opacity: 1;
		pointer-events: auto;
		box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.3);
		border-radius: 0 0 5px 5px;
	}

	.search-reset-btn.active {
		position: absolute;
		border: 1px solid gray;
		top: 10px;
		right: 10px;
		display: block;
	}

	.resultBox li {
		list-style: none;
		padding: 8px 12px;
		display: none;
		width: -webkit-fill-available;
		max-height: 20px;
		cursor: default;
		border-radius: 3px;
	}

	.searchInput.active .resultBox li {
		display: block;
	}

	.resultBox li:hover {
		background: #efefef;
	}

	.no-matches {
		padding: 8px 12px;
		font-size: 1em;
		color: rgba(0, 0, 0, 0.5);
	}
`

const Styler = styled.div`
	${cssStyle}
`

const excludeAddedItems = <T extends DefaultEntry>(
	searchList: T[],
	exclusions: T[]
) =>
	searchList.filter(
		(item) => !exclusions.some((exclusion) => exclusion.code === item.code)
	)
const handleFilter = (key: string, value: string) =>
	key.toLowerCase().includes(value.toLowerCase())

const SearchInput = <T extends DefaultEntry>({
	addItem,
	list,
	searchItems = [],
}: SearchInputProps<T>) => {
	const handleExcludeAddedItems = useCallback(
		() => excludeAddedItems(searchItems, list),
		[list, searchItems]
	)

	const [searchTerm, setSearchTerm] = useState<string>('')
	const [filteredItems, setFilteredItems] = useState<T[]>(
		handleExcludeAddedItems()
	)

	const handleSearch = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const target = event.target as HTMLInputElement
			const value = target.value
			setSearchTerm(value)
			if (value) {
				const filtered = handleExcludeAddedItems().filter(
					(item) =>
						handleFilter(item.title, value) ||
						handleFilter(item.title, value) ||
						handleFilter(item.category ?? '', value) ||
						handleFilter(item.code, value)
				)
				setFilteredItems(filtered)
			} else {
				setFilteredItems([])
			}
		},
		[handleExcludeAddedItems]
	)

	const handleSuggestionClick = useCallback(
		(item: T) => {
			addItem(item)
			setFilteredItems((state) => state.filter((elm) => elm !== item))
		},
		[addItem]
	)

	const handleResetSearchInput = useCallback(() => setSearchTerm(''), [])

	return (
		<Styler>
			<div className={`searchInput ${searchTerm.length ? 'active' : ''}`}>
				<input
					type='text'
					placeholder='Search to add to existing'
					value={searchTerm}
					onChange={handleSearch}
					onKeyUp={handleSearch}
				/>
				{searchTerm.length > 0 && (
					<ul className='resultBox'>
						{filteredItems.length ? (
							filteredItems.map((item) => (
								<li
									key={`search-result-${item.code}`}
									onClick={() => handleSuggestionClick(item)}
								>
									{item.code.toUpperCase()} - {item.title}
								</li>
							))
						) : (
							<span className='no-matches'>* None *</span>
						)}
					</ul>
				)}
				<button
					type='button'
					className={`search-reset-btn ${searchTerm.length ? 'active' : ''}`}
					onClick={handleResetSearchInput}
				>
					<span className='removeIcon'></span>
				</button>
			</div>
		</Styler>
	)
}

export default SearchInput

interface SearchInputProps<T> {
	addItem: (item: T) => void
	searchItems: T[] | undefined
	list: T[]
}
