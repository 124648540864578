import { css } from 'styled-components/macro'
import addNew from '../../images/addNew.svg'
import remove from '../../images/clear-icon.svg'
import edit from '../../images/edit.svg'
import view from '../../images/view.svg'
import React, {
	useCallback,
	useState,
	useEffect,
	ChangeEvent,
	useMemo,
	useRef,
	Fragment,
} from 'react'
import { FormDialog } from './FormDialog'
import { ObservationData } from '../../screens/HealthData/Observation'
import { HealthDataModelType } from './globaltypes'
import ConfirmDeleteRow from './Overlays/ConfirmDeleteRow'
import RelationsView from './Overlays/RelationsView'
import useHandleRelationalData from '../../hooks/useHandleRelationalData'
import FormInputContainer, {
	FormInputContainerProps,
} from './Form/FormInputs/FormInputContainer'
import { useHealthDataContext } from '../../screens/HealthData'
import { GroupData } from '../../screens/HealthData/Group'

const tableRowStyle = css`
	width: 1100px;
`
const tableHeaderStyle = css`
	border: 1px solid #34373b;
	border-radius: 3px;
	padding: 5px;
	text-align: center;
	max-width: 800px;
`
const tableDataStyle = css`
	border: 1px solid #34373b;
	border-radius: 3px;
	padding: 5px;
	text-align: center;
	max-width: 800px;
	height: 20px;
`
const iconStyleMd = css`
	display: block;
	background-size: cover;
	width: 30px;
	height: 30px;
	border: none;
`

const iconStyleSm = css`
	display: block;
	background-size: cover;
	width: 15px;
	height: 15px;
	border: none;
`

export const formStyle = css`
	margin-top: 10px;
	border: 1px solid;
	border-radius: 5px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: left;
	background-color: #ffff;
	width: 450px;
	margin: auto;

	input {
		width: 100%;
		margin-bottom: 5px;
	}

	input[type='submit'] {
		background-color: green;
		color: white;
		border: none;
		border-radius: 5px;
		padding: 5px;
		margin-top: 10px;
	}

	span {
		font-size: 1.5rem;
	}

	.threshold {
		margin-left: 5px;
		display: flex;
		flex-direction: row;
		gap: 3px;
		align-items: left;
	}
`

export const infoDescription = css`
	font-size: 0.8rem !important;
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	border: 1px solid #34373b;
	border-radius: 5px;
	padding: 5px;
`

export const highLightStyle = css`
	display: block;
	background-color: #f5f5f5;
	border-radius: 5px;
	box-shadow: 0px 0px 5px #333333;
	padding: 10px;
`

const TableRow = ({
	entry,
	rowIndex,
	editRow,
	removeRow,
	onViewContents,
	viewContents,
	context,
}: TableRowProps) => {
	const viewBtnRef = useRef<HTMLButtonElement | null>(null)

	const handleViewclick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			viewBtnRef.current = event.currentTarget
			const tableRow = viewBtnRef.current?.parentElement?.parentElement

			if (tableRow) {
				tableRow.classList.toggle('selected')
			}

			onViewContents?.(entry.id as number)
		},
		[entry.id, onViewContents]
	)

	useEffect(() => {
		const index: number = parseInt(
			viewBtnRef.current?.children[0].id.split('-')[1] ?? ''
		)
		const tableRow = viewBtnRef.current?.parentElement?.parentElement

		if (Number.isNaN(index)) return

		if (!viewContents) {
			if (tableRow) {
				tableRow.classList.toggle('selected')
			}

			viewBtnRef.current = null
		}
	}, [viewContents])

	return (
		<>
			<tr
				css={css`
					&:hover {
						background-color: rgba(43, 209, 17, 0.2);
					}
				`}
				key={entry.id?.toString()}
			>
				{Object.entries(entry).map(([key, value], cellIndex) => (
					<td key={`${rowIndex}_${cellIndex}`} className={`tableData`}>
						{typeof value === 'object' && key === 'thresholds'
							? value.map((threshold, index) => (
									<span
										css='display: block; max-width: 1000p x; text-align: left;'
										key={`${index}`}
									>
										{`${threshold.description}: Min: ${threshold.min}, Max: ${threshold.max}, Outcome: ${threshold.outcome}\n`}
									</span>
								))
							: value?.toString().toUpperCase()}
					</td>
				))}
				<td>
					<button
						title='rmvRowBtn'
						type='button'
						css={css`
							background: none;
							border: none;
							padding: none;
							margin: none;
						`}
						onClick={() => removeRow(entry.title as string)}
					>
						<span className='removeRowIcon'></span>
					</button>
				</td>
				<td>
					<button
						title='editRowBtn'
						type='button'
						css={css`
							background: none;
							border: none;
							padding: none;
							margin: none;
						`}
						onClick={() => editRow(entry, entry.id as number)}
					>
						<span className='editRowIcon'></span>
					</button>
				</td>
				{context !== HealthDataModelType.Observation ? (
					<td>
						<button
							title='View Contents'
							type='button'
							css={css`
								background: none;
								border: none;
								padding: none;
								margin: none;
							`}
							onClick={handleViewclick}
						>
							<span id={`view-${entry.id}`} className={'viewIcon'}></span>
						</button>
					</td>
				) : null}
			</tr>
		</>
	)
}

const Table = ({
	getRelationalData,
	error,
	addHealthData,
	updateHealthData,
	removeHealthData,
	context,
}: TableProps<HealthData>) => {
	const initialRelData = useMemo(
		() => ({
			observations: [],
			groups: [],
		}),
		[]
	)

	const [editedRow, setEditedRow] = useState<HealthData>({})
	const [newRow, setNewRow] = useState<HealthData>({})
	const [editMode, setEditMode] = useState(false)
	const [addMode, setAddMode] = useState(false)
	const [viewMode, setViewMode] = useState(false)
	const [viewRemoveConfirmation, setViewRemoveConfirmation] = useState(false)
	const [invalidInput, setInvalidInput] = useState(false)
	const [toRemove, setToRemove] = useState<number | null>(null)
	const [filter, setFilter] = useState('code')
	const [filteredData, setFilteredData] = useState<HealthData[]>([])
	const [relationalData, setRelationalData] =
		useState<RelationalData>(initialRelData)
	const { groups, observations, concepts } = useHealthDataContext().data

	const viewData: HealthData[] = useMemo(() => {
		switch (context) {
			case 'observations':
				return [...observations]
			case 'group':
				return [...groups]
			case 'concept':
				return [...concepts]

			default:
				throw new Error('unknown context')
		}
	}, [concepts, context, groups, observations])

	const handleRemoveConfirmation = useCallback(
		(title?: string) => {
			const id = viewData.find((v) => v.title === title)?.id as number

			setToRemove(() => (!viewRemoveConfirmation ? id ?? null : null))
			setViewRemoveConfirmation(!viewRemoveConfirmation)
		},
		[viewData, viewRemoveConfirmation]
	)
	const handleViewContents = useCallback(
		async (entryId?: number) => {
			if (context === HealthDataModelType.Observation) return

			if (!viewMode && entryId) {
				let observations
				let groups

				switch (context) {
					case HealthDataModelType.Group:
						observations = await getRelationalData(entryId, 'observations')
						setRelationalData({ ...relationalData, observations })
						break
					case HealthDataModelType.Concept:
						observations = await getRelationalData(entryId, 'observations')
						groups = await getRelationalData(entryId, 'groups')
						setRelationalData({ groups, observations })
				}
			}

			if (viewMode) setRelationalData(initialRelData)

			setViewMode(!viewMode)
		},
		[context, viewMode, initialRelData, getRelationalData, relationalData]
	)

	const { relationalEditState, ...relationalDataHandlers } =
		useHandleRelationalData()

	const getColumns = useCallback((context: string): string[] => {
		// This array is deciding the table's column order for the view data
		switch (context) {
			case 'observations':
			case 'group':
				return [
					'id',
					'code',
					'title',
					'type',
					'interval',
					'thresholds',
					'category',
				]
			case 'concept':
				return ['id', 'title', 'objective', 'documentationUrl']

			default:
				throw new Error('Unknown context')
		}
	}, [])

	const sortDataColumns = useCallback(
		(data: HealthData[]): HealthData[] => {
			const columns = getColumns(context)

			return data.map((b) =>
				Object.fromEntries([
					...Object.entries(b)
						.filter(([key]) => columns.includes(key))
						.sort(
							([keyA], [keyB]) => columns.indexOf(keyA) - columns.indexOf(keyB)
						),
				])
			)
		},
		[context, getColumns]
	)

	useEffect(() => {
		setFilteredData(() => sortDataColumns(viewData))
	}, [sortDataColumns, viewData])

	const tableStyle = css`
		padding: 20px;
		margin: auto;
		opacity: ${editMode || addMode ? 0.2 : 1};
	`

	const editRow = useCallback(
		async (row: HealthData, entryId: number) => {
			try {
				let observations
				let groups

				switch (context) {
					case HealthDataModelType.Observation:
						break
					case HealthDataModelType.Group:
						observations = (await getRelationalData(entryId)) as ObservationData
						observations.forEach((o) =>
							relationalDataHandlers.addObservation(o)
						)
						break
					case HealthDataModelType.Concept:
						observations = (await getRelationalData(
							entryId,
							'observations'
						)) as ObservationData
						observations.forEach((o) =>
							relationalDataHandlers.addObservation(o)
						)

						groups = (await getRelationalData(entryId, 'groups')) as GroupData
						groups.forEach((g) => relationalDataHandlers.addGroup(g))
						break
				}
			} finally {
				setEditedRow(row)
				setEditMode(true)
			}
		},
		[relationalDataHandlers, context, getRelationalData]
	)

	const handleCloseAddMode = useCallback(
		(isOn: boolean) => {
			setAddMode(isOn)
			if (!isOn) relationalDataHandlers.revertChanges()
		},
		[relationalDataHandlers]
	)

	const handleCloseEditMode = useCallback(
		(isOn: boolean) => {
			setEditMode(isOn)
			if (!isOn) relationalDataHandlers.revertChanges()
		},
		[relationalDataHandlers]
	)

	const addRowData = useCallback(
		(
			key: string,
			event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
			value?: string
		) => {
			let groupId: number | undefined
			let inputValue: string | number | boolean = ''
			if (key === 'code') {
				const isFound = viewData.some((entry) => {
					if (value) {
						return entry.code?.toString().toLowerCase() === value.toLowerCase()
					}
					return (
						entry.code?.toString().toLowerCase() ===
						event.target.value.toLocaleLowerCase()
					)
				})
				if (isFound) {
					setInvalidInput(true)
					return
				}

				const group = groups.find((g) => g.code === event.target.value)
				if (group) groupId = group.id

				setInvalidInput(false)
			}

			if (event.target instanceof HTMLSelectElement) {
				if (value) {
					inputValue = value
				} else {
					inputValue = event.target.value
				}
			} else {
				inputValue = +event.target.value
					? parseFloat(event.target.value)
					: event.target.value.toLowerCase()
			}

			const healthDataRow = groupId
				? { ...newRow, [key]: inputValue, groupId }
				: { ...newRow, [key]: inputValue }

			setNewRow(healthDataRow)
		},
		[newRow, viewData, groups]
	)

	const editRowData = useCallback(
		(key: string, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			let inputValue: string | number | boolean = ''

			if (event.target instanceof HTMLSelectElement) {
				inputValue = event.target.value
			} else {
				if (event.target.type === 'checkbox') {
					inputValue = event.target.checked
				} else {
					inputValue = +event.target.value
						? parseFloat(event.target.value)
						: event.target.value.toLowerCase()
				}
			}

			const healthDataRow = { ...editedRow, [key]: inputValue }
			setEditedRow(healthDataRow)
		},
		[editedRow]
	)

	const sendObservationData = useCallback(() => {
		if (invalidInput) return

		const { validateHasThresholds, revertChanges } = relationalDataHandlers
		const thresholds = validateHasThresholds()
			? relationalEditState.thresholds
			: undefined

		if (addMode) {
			const data = {
				Code: newRow.code,
				Title: newRow.title,
				Type: newRow.type,
				Interval: newRow.interval,
				Thresholds: thresholds,
				Category: newRow.category,
				...(newRow.groupId ? { GroupId: newRow.groupId } : {}),
			}
			addHealthData(data)
			revertChanges()
			setAddMode(false)
		}
		if (editMode) {
			const data = {
				Id: editedRow.id,
				Code: editedRow.code,
				Title: editedRow.title,
				Type: editedRow.type,
				Interval: editedRow.interval,
				Thresholds: thresholds,
				Category: editedRow.category,
				...(newRow.groupId ? { GroupId: newRow.groupId } : {}),
			}
			updateHealthData(data)
			revertChanges()
			setEditMode(false)
		}
	}, [
		addHealthData,
		updateHealthData,
		addMode,
		editMode,
		editedRow,
		newRow,
		invalidInput,
		relationalEditState,
		relationalDataHandlers,
	])

	const sendGroupData = useCallback(() => {
		if (invalidInput) return

		const { validateHasThresholds, revertChanges } = relationalDataHandlers
		const thresholds = validateHasThresholds()
			? relationalEditState.thresholds
			: undefined

		if (addMode) {
			const data = {
				Code: newRow.code,
				Title: newRow.title,
				Type: newRow.type,
				Interval: newRow.interval,
				Thresholds: thresholds,
				Category: newRow.category,
				Observations: relationalEditState.observations,
			}
			addHealthData(data)
			revertChanges()
			setAddMode(false)
		}
		if (editMode) {
			const data = {
				Id: editedRow.id,
				Code: editedRow.code,
				Title: editedRow.title,
				Type: editedRow.type,
				Interval: editedRow.interval,
				Thresholds: thresholds,
				Category: editedRow.category,
				Observations: relationalEditState.observations,
			}
			updateHealthData(data)
			revertChanges()
			setEditMode(false)
		}
	}, [
		addHealthData,
		updateHealthData,
		addMode,
		editMode,
		editedRow,
		newRow,
		invalidInput,
		relationalEditState,
		relationalDataHandlers,
	])

	const sendConceptData = useCallback(() => {
		if (invalidInput) return

		const { revertChanges } = relationalDataHandlers

		if (addMode) {
			const data = {
				Title: newRow.title,
				Objective: newRow.objective,
				DocumentationURl: newRow.documentationUrl,
				Observations: relationalEditState.observations,
				Groups: relationalEditState.groups,
			}
			addHealthData(data)
			revertChanges()
			setAddMode(false)
		}
		if (editMode) {
			const data = {
				Id: editedRow.id,
				Title: editedRow.title,
				Objective: editedRow.objective,
				DocumentationURl: editedRow.documentationUrl,
				Observations: relationalEditState.observations,
				Groups: relationalEditState.groups,
			}
			updateHealthData(data)
			revertChanges()
			setEditMode(false)
		}
	}, [
		addHealthData,
		updateHealthData,
		addMode,
		editMode,
		editedRow,
		newRow,
		invalidInput,
		relationalEditState,
		relationalDataHandlers,
	])

	const handleSendData = useCallback(
		(context: string): VoidFunction => {
			switch (context) {
				case HealthDataModelType.Observation:
					return sendObservationData
				case HealthDataModelType.Group:
					return sendGroupData
				case HealthDataModelType.Concept:
					return sendConceptData
				default:
					throw new Error('Invalid Context')
			}
		},
		[sendConceptData, sendGroupData, sendObservationData]
	)
	const sendData = handleSendData(context)

	const removeEntry = useCallback(
		(id: number) => {
			removeHealthData(id)
			setViewRemoveConfirmation(!viewRemoveConfirmation)
		},
		[removeHealthData, viewRemoveConfirmation]
	)

	const filterData = useCallback(
		(filter: string, search: string) => {
			if (search === '') {
				const data = sortDataColumns(viewData)
				setFilteredData(() => data)
				return data
			}

			const filteredData = viewData.filter((entry) =>
				entry[filter]?.toString().toLowerCase().includes(search.toLowerCase())
			)
			setFilteredData(sortDataColumns(filteredData))
		},
		[sortDataColumns, viewData]
	)

	const formInputProperties: FormInputContainerProps = {
		editedRow,
		data: viewData,
		addRowData,
		editRowData,
		addMode,
		editMode,
		invalidInput,
		context,
		relationalEditState,
		relationalDataHandlers,
	}

	return (
		<div
			css={css`
				display: block;
				position: relative;
				max-width: 1500px;
				margin: auto;
				border: 2px solid #34373b;
				border-radius: 5px;
				box-shadow: 1px 1px 1px 1px #34373b;
				font-size: 1rem;

				table {
					${tableStyle}
				}

				table tr {
					${tableRowStyle}
				}

				table tr th {
					${tableHeaderStyle}
				}

				table tr .tableData {
					${tableDataStyle}
				}

				table tr .tableData.highlight {
					background-color: green;
				}

				.addIcon {
					${iconStyleMd}
					background-image: url(${addNew});
				}

				.addIcon:hover {
					cursor: pointer;
				}

				.editRowIcon {
					${iconStyleMd}
					background-image: url(${edit});
				}

				.removeRowIcon {
					${iconStyleMd}
					background-image: url(${remove});
				}

				.viewIcon {
					${iconStyleMd}
					background-image: url(${view});
				}

				.editRowIcon:hover {
					cursor: pointer;
				}

				.closeIcon {
					${iconStyleMd}
					background-image: url(${remove});
				}
				.removeIcon {
					${iconStyleSm}
					background-image: url(${remove});
				}

				.closeIcon:hover {
					cursor: pointer;
				}

				.selected {
					background-color: rgba(43, 209, 17, 0.5);
				}
			`}
		>
			<table>
				<thead>
					<tr>
						<td>
							<button
								title='addBtn'
								type='button'
								css={css`
									background: none;
									border: none;
								`}
								onClick={() => setAddMode(true)}
							>
								<span className='addIcon'></span>
							</button>
						</td>
						<td>
							<label htmlFor='filter'>Velg filter: </label>
							<select
								name='filter'
								id='filter'
								title='filter'
								onChange={(event) => setFilter(event.target.value)}
							>
								<option value='code'>Code</option>
								<option value='title'>Title</option>
								<option value='category'>Category</option>
							</select>
						</td>
						<td>
							<input
								type='text'
								placeholder='Søk...'
								onChange={(event) => filterData(filter, event.target.value)}
							/>
						</td>
					</tr>
					{filteredData && filteredData.length > 0 ? (
						<tr>
							{Object.keys(filteredData[0]).map((key, index) => (
								<th key={index}>{key.toUpperCase()}</th>
							))}
						</tr>
					) : null}
				</thead>
				{filteredData && filteredData.length > 0 ? (
					<tbody>
						{filteredData.map((entry, rowIndex) => (
							<TableRow
								key={entry.id?.toString() || rowIndex}
								entry={entry}
								rowIndex={rowIndex}
								editRow={editRow}
								removeRow={handleRemoveConfirmation}
								onViewContents={handleViewContents}
								viewContents={viewMode}
								context={context}
							/>
						))}
					</tbody>
				) : null}
			</table>
			<div
				className='healthDataEditorDialog'
				css={css`
					position: absolute;
					top: 10px;
					width: 100%;
					margin: auto;
				`}
			>
				{(editMode || addMode) && (
					<FormDialog
						sendData={sendData}
						editMode={editMode}
						goAddMode={handleCloseAddMode}
						goEditMode={handleCloseEditMode}
					>
						<FormInputContainer {...formInputProperties} />
					</FormDialog>
				)}
			</div>

			{viewRemoveConfirmation && (
				<ConfirmDeleteRow
					entryId={toRemove}
					onClose={handleRemoveConfirmation}
					onConfirm={removeEntry}
				/>
			)}

			{viewMode && (
				<RelationsView
					contents={relationalData}
					context={context}
					onClose={handleViewContents}
				/>
			)}

			{error?.message && (
				<p css='margin: 10px;'>
					{error.name} {error.message}
				</p>
			)}
		</div>
	)
}

interface TableProps<HealthData> {
	viewData: HealthData[]
	getRelationalData: (
		groupId: number,
		relationalDataType?: string
	) => Promise<HealthData[]>
	error?: Error
	addHealthData: (viewData: HealthData) => void //will have different types
	updateHealthData: (viewData: HealthData) => void //will have different types
	removeHealthData: (id: number) => void //will have different types
	context: string
}

interface TableRowProps {
	entry: HealthData
	rowIndex: number
	editRow: (row: HealthData, entryId: number) => void
	removeRow: (entryTitle: string) => void
	onViewContents?: (entryId: number) => void
	viewContents: boolean
	context: string
}

export type HealthData = Record<
	string,
	string | number | boolean | undefined | HealthData[]
>

export type RelationalData = {
	observations: HealthData[]
	groups: HealthData[]
}

export default Table
