import * as React from 'react'
import { cloneDeep } from 'lodash'
import { css } from 'styled-components/macro'
import color from 'color'

import IdGenerator from '../../utils/IdGenerator'

import Alert, { AlertVariant } from '../../components/Alert'
import Button, { ButtonVariant } from '../../components/Button'

import { ReactComponent as DeleteIcon } from '../../images/delete.svg'

import { BasicQuestionType, QuestionActionType } from '../../types'
import TextInput from '../../components/TextInput'

// TODO: refactor out classnames
const BasicInfoFields: React.FC<BasicInfoFieldsProps> = ({
	questionState,
	questionDispatch,
}) => {
	const onAddTagRangeClick = () => {
		const bmiRangesCopy = [...questionState.bmiTagRanges]

		bmiRangesCopy.push({
			id: IdGenerator(),
			bmiStart: 0.0,
			bmiEnd: 0.0,
			bmiTag: '',
		})

		questionDispatch({
			type: 'updateQuestion',
			payload: { bmiTagRanges: bmiRangesCopy },
		})
	}

	const onDeleteTagRangeClick = (bmiRangeIndex: number) => {
		const bmiRangesCopy = cloneDeep(questionState.bmiTagRanges)

		bmiRangesCopy.splice(bmiRangeIndex, 1)

		questionDispatch({
			type: 'updateQuestion',
			payload: { bmiTagRanges: bmiRangesCopy },
		})
	}

	const onBmiRangeChange = (
		inputValue: string,
		fieldToUpdate: string,
		bmiRangeIndex: number
	) => {
		const bmiRangesCopy = cloneDeep(questionState.bmiTagRanges)

		let parsedValue = 0

		if (parseFloat(inputValue) % 1 > 0) {
			parsedValue = parseFloat(inputValue.slice(0, inputValue.indexOf('.') + 3))
		} else {
			parsedValue = parseInt(inputValue)
		}

		bmiRangesCopy[bmiRangeIndex] = {
			...bmiRangesCopy[bmiRangeIndex],
			[fieldToUpdate]: parsedValue,
		}

		questionDispatch({
			type: 'updateQuestion',
			payload: { bmiTagRanges: bmiRangesCopy },
		})
	}

	const onBmiTagChange = (inputValue: string, bmiRangeIndex: number) => {
		const bmiRangesCopy = cloneDeep(questionState.bmiTagRanges)

		bmiRangesCopy[bmiRangeIndex].bmiTag = inputValue

		questionDispatch({
			type: 'updateQuestion',
			payload: { bmiTagRanges: bmiRangesCopy },
		})
	}

	const onShowOnlyYearClick = (value: boolean) => {
		questionDispatch({
			type: 'updateQuestion',
			payload: { showOnlyYear: value },
		})
	}

	/* NB: If you make changes to this function remember to also change the disable logic in the Question component */
	const bmiTagRangesErrors = questionState.bmiTagRanges.reduce(
		(bmiTagRangeErrors: string[][], bmiTagRangeOne) => {
			const overlapsWithIndexes = questionState.bmiTagRanges.reduce(
				(
					overlapsWithIndex: number[],
					bmiTagRangeTwo,
					bmiTagRangeTwoIndex: number
				) => {
					if (bmiTagRangeOne === bmiTagRangeTwo) {
						return overlapsWithIndex
					}

					if (
						bmiTagRangeOne.bmiEnd - bmiTagRangeTwo.bmiStart >= 0 &&
						bmiTagRangeTwo.bmiEnd - bmiTagRangeOne.bmiStart >= 0
					) {
						overlapsWithIndex.push(bmiTagRangeTwoIndex)
					}

					return overlapsWithIndex
				},
				[]
			)

			const errors: string[] = []

			if (bmiTagRangeOne.bmiStart >= bmiTagRangeOne.bmiEnd)
				errors.push(
					'The range is invalid, BMI start is larger than or equal to BMI end.'
				)

			if (overlapsWithIndexes.length)
				errors.push(
					`This range overlaps with Range ${overlapsWithIndexes.map(
						(overlap: number, overlapIndex: number) =>
							`${overlapsWithIndexes.slice(overlapIndex + 1).length ? ' ' : ''}
						${overlap + 1}`
					)}`
				)

			if (isNaN(bmiTagRangeOne.bmiStart))
				errors.push('The value in BMI start is not a valid number.')
			if (isNaN(bmiTagRangeOne.bmiEnd))
				errors.push('The value in BMI end is not a valid number.')

			bmiTagRangeErrors.push(errors)

			return bmiTagRangeErrors
		},
		[]
	)

	return (
		<div
			css={css`
				margin-top: 2rem;
			`}
		>
			<div
				css={css`
					margin-bottom: 2rem;
				`}
			>
				<div css={checkBoxContainerStyle}>
					<input
						type='checkbox'
						id='gender-toggle'
						checked={questionState.showGenderQuestion}
						onChange={(event) => {
							questionDispatch({
								type: 'updateQuestion',
								payload: {
									showGenderQuestion: event.currentTarget.checked,
								},
							})
						}}
					/>
					<label htmlFor='gender-toggle'>Show gender question</label>
				</div>
				<div
					css={[
						checkBoxContainerStyle,
						css`
							flex-direction: column;
							align-items: flex-start;
						`,
					]}
				>
					<div>
						<input
							type='checkbox'
							id='birthday-toggle'
							checked={questionState.showBirthdayQuestion}
							onChange={(event) => {
								questionDispatch({
									type: 'updateQuestion',
									payload: {
										showBirthdayQuestion: event.currentTarget.checked,
									},
								})
							}}
						/>
						<label htmlFor='birthday-toggle'>Show birthday question</label>
					</div>
					{questionState.showBirthdayQuestion ? (
						<div
							css={css`
								margin-left: 1.5rem;
							`}
						>
							<div css={radioInputContainerStyle}>
								<input
									name='year-toggle'
									type='radio'
									id='full-date'
									checked={!questionState.showOnlyYear}
									onChange={() => onShowOnlyYearClick(false)}
								/>
								<label htmlFor='full-date'>Full date</label>
							</div>
							<div css={radioInputContainerStyle}>
								<input
									name='year-toggle'
									type='radio'
									id='only-year'
									checked={questionState.showOnlyYear}
									onChange={() => onShowOnlyYearClick(true)}
								/>
								<label htmlFor='only-year'>Only year</label>
							</div>
						</div>
					) : null}
				</div>
				<div css={checkBoxContainerStyle}>
					<input
						type='checkbox'
						id='height-toggle'
						checked={questionState.showHeightQuestion}
						onChange={(event) => {
							questionDispatch({
								type: 'updateQuestion',
								payload: {
									showHeightQuestion: event.currentTarget.checked,
								},
							})
						}}
					/>
					<label htmlFor='height-toggle'>Show height question</label>
				</div>
				<div css={checkBoxContainerStyle}>
					<input
						type='checkbox'
						id='weight-toggle'
						checked={questionState.showWeightQuestion}
						onChange={(event) => {
							questionDispatch({
								type: 'updateQuestion',
								payload: {
									showWeightQuestion: event.currentTarget.checked,
								},
							})
						}}
					/>
					<label htmlFor='weight-toggle'>Show weight question</label>
				</div>
				<div css={checkBoxContainerStyle}>
					<input
						type='checkbox'
						id='bmi-toggle'
						checked={questionState.calculateBmi}
						onChange={(event) =>
							questionDispatch({
								type: 'updateQuestion',
								payload: { calculateBmi: event.currentTarget.checked },
							})
						}
					/>
					<label htmlFor='bmi-toggle'>Calculate BMI and add tags</label>
				</div>
				{questionState.calculateBmi &&
				(!questionState.showWeightQuestion ||
					!questionState.showHeightQuestion) ? (
					<Alert variant={AlertVariant.warning}>
						<p>
							Both weight and height have to be enabled to use BMI tag ranges.
						</p>
					</Alert>
				) : null}
				<p>
					When using BMI ranges make sure to cover ALL ranges within 0 to 50.
				</p>
			</div>
			<div
				css={css`
					display: flex;
					flex-direction: column;
				`}
			>
				<div
					css={css`
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 0.5rem;
						border-bottom: 2px solid black;
						margin-bottom: 0.5rem;
					`}
				>
					<h4
						css={css`
							margin: 0;
							margin-left: 1rem;
						`}
					>
						BMI tag ranges
					</h4>
					<Button
						variant={ButtonVariant.secondary}
						onClick={onAddTagRangeClick}
					>
						Add tag range
					</Button>
				</div>
				{questionState?.bmiTagRanges.map((bmiRange, bmiRangeIndex: number) => {
					const bmiTagRangeErrors = bmiTagRangesErrors[bmiRangeIndex]

					return (
						<div
							key={bmiRangeIndex}
							css={css`
								padding: 1rem;
								margin-bottom: 1rem;
								background-color: #eeee;
								border-radius: 5px;
							`}
						>
							<div
								css={css`
									align-self: end;
									display: flex;
									align-items: center;
									justify-content: space-between;
									margin-bottom: 0.25rem;
								`}
							>
								<span
									css={css`
										cursor: default;
										border-bottom: 2px solid #a80047;
										font-weight: bold;
									`}
								>
									Range {bmiRangeIndex + 1}
								</span>
								<Button
									variant={ButtonVariant.icon}
									buttonCss={css`
										background-color: #a80047;
										align-self: end;
										&:hover {
											background-color: ${color('#a80047').darken(0.06).hex()};

											&:active {
												background-color: ${color('#a80047').darken(0.1).hex()};

												svg {
													fill: ${color('#ffffff').darken(0.1).hex()};
												}
											}
										}
									`}
									onClick={() => onDeleteTagRangeClick(bmiRangeIndex)}
								>
									<DeleteIcon
										css={css`
											fill: white;
										`}
									/>
								</Button>
							</div>
							<div
								css={css`
									display: flex;
									gap: 1rem;
								`}
							>
								<TextInput
									containerCss={css`
										margin: 0;
										width: 100%;
									`}
									label='BMI start'
									lang='en'
									id={'bmiStart' + bmiRangeIndex}
									name='bmiStart'
									type='number'
									value={isNaN(bmiRange.bmiStart) ? '' : bmiRange.bmiStart}
									onChange={(event) =>
										onBmiRangeChange(
											event.currentTarget.value,
											event.currentTarget.name,
											bmiRangeIndex
										)
									}
								/>
								<TextInput
									containerCss={css`
										margin: 0;
										width: 100%;
									`}
									label='BMI end'
									id={'bmiEnd' + bmiRangeIndex}
									name='bmiEnd'
									type='number'
									value={isNaN(bmiRange.bmiEnd) ? '' : bmiRange.bmiEnd}
									onChange={(event) =>
										onBmiRangeChange(
											event.currentTarget.value,
											event.currentTarget.name,
											bmiRangeIndex
										)
									}
								/>
								<TextInput
									containerCss={css`
										margin: 0;
										width: 100%;
									`}
									label='Tag'
									id={'bmiTag' + bmiRangeIndex}
									value={bmiRange.bmiTag}
									onChange={(event) =>
										onBmiTagChange(event.currentTarget.value, bmiRangeIndex)
									}
								/>
							</div>
							{bmiTagRangeErrors.length ? (
								<Alert
									variant={AlertVariant.warning}
									containerCss={css`
										margin: 1rem 0;
										flex-direction: column;
										gap: 1rem;
										padding-block: 1rem;
									`}
								>
									{bmiTagRangeErrors.map(
										(bmiTagRangeError, bmiTagRangeErrorIndex) => (
											<p
												css={css`
													margin-block: 0;
												`}
												key={bmiTagRangeErrorIndex}
											>
												{bmiTagRangeError}
											</p>
										)
									)}
								</Alert>
							) : null}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default BasicInfoFields

type BasicInfoFieldsProps = {
	questionState: BasicQuestionType
	questionDispatch: React.Dispatch<QuestionActionType>
}

const radioInputContainerStyle = css`
	display: flex;
	font-size: 1rem;
`

const checkBoxContainerStyle = css`
	display: flex;
	align-items: center;

	input {
		margin-right: 0.5rem;
	}
`
