import * as React from 'react'
import {
	DropResult,
	DragDropContext,
	Droppable,
	Draggable,
} from 'react-beautiful-dnd'
import { useLocation, useParams, useHistory } from 'react-router'
import { css } from 'styled-components/macro'

import { useEditorState, useEditorDispatch } from '../../context/EditorProvider'

import Button, { ButtonVariant } from '../../components/Button'

import { ReactComponent as DeleteIcon } from '../../images/delete.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'
import { ReactComponent as VisibilityIcon } from '../../images/visibility-black.svg'
import { ReactComponent as VisibilityOffIcon } from '../../images/visibility_off.svg'
import { ReactComponent as FileCopyIcon } from '../../images/file-copy.svg'
import ToggleButton from '../../components/ToggleButton'

import {
	SurveyQueryParams,
	isSurveyStartType,
	isSurveySubmitType,
	isChoicesQuestionType,
	isBasicQuestionType,
	SurveyStartType,
	ChoicesQuestionType,
	BasicQuestionType,
	SurveySubmitType,
} from '../../types'

const QuestionList: React.FC<{ clearStatusText: () => void }> = ({
	clearStatusText,
}) => {
	const { surveyDefinition } = useEditorState()
	const surveyDispatch = useEditorDispatch()

	if (!surveyDefinition) return null

	const questions = surveyDefinition.questions

	const onDragEnd = (result: DropResult) => {
		if (
			!result.destination ||
			result.destination.index >= questions.length - 1 ||
			result.destination.index <= 0
		) {
			return
		}

		surveyDispatch({
			type: 'updateQuestionPosition',
			payload: {
				oldIndex: result.source.index,
				newIndex: result.destination.index,
			},
		})
	}

	return (
		<ul
			css={css`
				list-style: none;
				padding: 0;
				margin-top: 5rem;
				border-bottom: 2px solid #acacac;
			`}
		>
			<div
				css={css`
					display: grid;
					grid-template-columns: 1fr auto;

					> div {
						display: flex;
						align-items: center;
					}
				`}
			>
				<h3
					css={css`
						flex-grow: 1;
					`}
				>
					Questions
				</h3>
				<div>
					<Button
						type='button'
						variant={ButtonVariant.secondary}
						onClick={() =>
							surveyDispatch({
								type: 'addQuestion',
								payload: 'checkbox',
							})
						}
					>
						Add question
					</Button>
				</div>
			</div>
			<li
				className='question-list-top-row'
				css={[
					listItemStyle,
					css`
						&.question-list-top-row {
							border-top: 2px solid #acacac;
							border-bottom: 2px solid #acacac;
						}
					`,
				]}
			>
				<span
					css={css`
						padding-left: 0.75rem;
					`}
				>
					Order
				</span>
				<span>Title</span>
				<span>Question Type</span>
			</li>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable_questions'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{questions.map((question, index: number) => (
								<Question
									key={index}
									question={question}
									questionIndex={index}
									clearStatusText={clearStatusText}
								/>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</ul>
	)
}

const Question: React.FC<QuestionProps> = ({ question, questionIndex }) => {
	const { surveyDefinition, translations } = useEditorState()
	const surveyDispatch = useEditorDispatch()
	const { search } = useLocation()
	const { deptToRequest, orgToRequest } = useParams<SurveyQueryParams>()
	const history = useHistory()

	if (!surveyDefinition || !translations) return null

	const currentLanguage = 'en-US'
	const currentLanguageTranslations = translations[currentLanguage]

	const onEditClick = () => {
		window.scrollTo(0, 0)
		history.push({
			pathname: `/editor/${orgToRequest}/${deptToRequest}/question/${questionIndex}`,
			search: search,
		})
	}

	const onTypeChange = (questionType: string) => {
		if (
			questionType === 'basic' &&
			!window.confirm(
				`Are you sure you wish to change to basic question type?\nAll changes that have been made to this questions choices will be lost.`
			)
		)
			return

		surveyDispatch({
			type: 'updateQuestionType',
			payload: {
				questionIndex: question.order,
				newQuestionVariant: questionType,
			},
		})
	}

	const onDeleteClick = () => {
		if (window.confirm('Are you sure you wish to delete this question?')) {
			surveyDispatch({
				type: 'deleteQuestion',
				payload: { questionIndex: questionIndex },
			})
		}
	}

	return (
		<Draggable
			draggableId={'question_' + questionIndex}
			index={questionIndex}
			isDragDisabled={
				isSurveyStartType(question) || isSurveySubmitType(question)
			}
		>
			{(provided, snapshot) => (
				<li
					css={[
						listItemStyle,
						!question.visible &&
							css`
								span,
								select {
									color: #bdbdbd;
								}
							`,
					]}
					className={snapshot.isDragging ? 'dragging' : ''}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					{!isSurveyStartType(question) && !isSurveySubmitType(question) ? (
						<div css={gridItemStyle}>
							<span
								css={css`
									paddingleft: 1.5rem;
								`}
							>
								{question.order}
							</span>
						</div>
					) : (
						<div />
					)}
					<div css={gridItemStyle}>
						<span>
							{currentLanguageTranslations[question.headerTextKey]?.text || ''}
						</span>
					</div>
					{isChoicesQuestionType(question) || isBasicQuestionType(question) ? (
						<div css={gridItemStyle}>
							<select
								id='question-type'
								value={question.choicesVariant}
								onChange={(event) => onTypeChange(event.currentTarget.value)}
							>
								<option value='checkbox'>Multiple choice</option>
								<option value='radio'>Single choice</option>
								<option value='slider'>Slider</option>
								<option value='basic'>Basic Info</option>
							</select>
						</div>
					) : (
						<div />
					)}
					{question.choicesVariant !== 'SurveySubmit' ? (
						<div css={gridItemStyle}>
							<ToggleButton
								on={question.visible}
								onIcon={
									<VisibilityIcon
										css={css`
											cursor: pointer;
										`}
									/>
								}
								offIcon={
									<VisibilityOffIcon
										css={css`
											cursor: pointer;
										`}
									/>
								}
								onChange={() => {
									surveyDispatch({
										type: 'toggleVisibleQuestion',
										payload: { questionIndex: questionIndex },
									})
								}}
							/>
						</div>
					) : (
						<div />
					)}
					<div css={gridItemStyle}>
						<Button variant={ButtonVariant.icon} onClick={onEditClick}>
							<EditIcon />
						</Button>
					</div>
					{isChoicesQuestionType(question) || isBasicQuestionType(question) ? (
						<React.Fragment>
							<div css={gridItemStyle}>
								<Button
									variant={ButtonVariant.icon}
									onClick={() =>
										surveyDispatch({ type: 'copyQuestion', payload: question })
									}
								>
									<FileCopyIcon />
								</Button>
							</div>
							<div css={gridItemStyle}>
								<Button variant={ButtonVariant.icon} onClick={onDeleteClick}>
									<DeleteIcon />
								</Button>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div />
							<div />
						</React.Fragment>
					)}
				</li>
			)}
		</Draggable>
	)
}

export default QuestionList

type QuestionProps = {
	question:
		| SurveyStartType
		| ChoicesQuestionType
		| BasicQuestionType
		| SurveySubmitType
	questionIndex: number
	clearStatusText: () => void
}

const listItemStyle = css`
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 0.5fr minmax(0, 2fr) 1.5fr 0.25fr 0.25fr 0.25fr 0.25fr;
	grid-column-gap: 1rem;

	padding: 1rem 0.5rem;

	&:not(:first-child) {
		border-top: 1px solid #eeee;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #eeee;
	}

	&.dragging {
		background-color: #dbffe3;
	}
`

const gridItemStyle = css`
	display: flex;
	align-items: center;

	> span {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`
