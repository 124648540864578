import { cloneDeep } from 'lodash'

import { NEW_ID } from '../../constants'

import { getAnswerDefinition } from '../../utils/getAnswerDefinition'
import { getPropertiesAndTags } from '../../utils/getPropertiesAndTags'
import { getObjectById } from '../../utils/getObject'

import {
	isSurveyStartType,
	isChoicesQuestionType,
	isChoiceGroupAnswerType,
	ChoiceGroupAnswerType,
	isSliderAnswerType,
	isBasicQuestionType,
	isBasicAnswerType,
	QuestionAnswerType,
	HandlerActionType,
	HandlerStateType,
	isUpdatePotentialBasicAnswerType,
	isUpdatePotentialChoicesAnswerType,
	isUpdatePotentialSliderAnswerType,
} from '../../types'

export const handlerReducer = (
	draftState: HandlerStateType,
	action: HandlerActionType
) => {
	if (action.type === 'navigateForward') {
		const { surveyDefinition, potentialAnswers, slug } = draftState
		const questions = surveyDefinition.questions

		const currentQuestionIndex = questions.findIndex(
			(question) => question.id === slug
		)
		const currentQuestion = questions[currentQuestionIndex]

		const potentialAnswer = potentialAnswers.find(
			(potentialAnswer) => potentialAnswer.questionId === slug
		)

		if (isSurveyStartType(currentQuestion)) {
			draftState.slug = questions[currentQuestionIndex + 1].id

			return
		}

		if (!currentQuestion || !potentialAnswer) return

		let nextQuestionId = questions[currentQuestionIndex + 1].id

		if (isChoicesQuestionType(currentQuestion)) {
			if (
				Array.isArray(potentialAnswer.answer) &&
				isChoiceGroupAnswerType(potentialAnswer.answer[0])
			) {
				const choiceAnswer = potentialAnswer.answer.reduce(
					(choiceAnswer: ChoiceGroupAnswerType | undefined, answer) => {
						if (answer.selected) {
							choiceAnswer = answer

							if (answer?.answers?.length) {
								const subAnswer = answer.answers.find(
									(subAnswer) => subAnswer.selected
								)

								if (subAnswer) {
									choiceAnswer = subAnswer
								}
							}
						}

						return choiceAnswer
					},
					undefined
				)

				if (choiceAnswer) {
					const choice = getObjectById(choiceAnswer.id, currentQuestion.choices)

					if (choice) {
						nextQuestionId =
							questions[currentQuestionIndex + (choice.offset + 1)]?.id
					}
				}
			} else if (isSliderAnswerType(potentialAnswer.answer)) {
				const choice =
					currentQuestion.choices[potentialAnswer.answer.sliderIndex]

				nextQuestionId =
					questions[currentQuestionIndex + (choice.offset + 1)].id
			}
		}

		const answerEntry = cloneDeep(potentialAnswer)

		if (isChoicesQuestionType(currentQuestion)) {
			if (isSliderAnswerType(potentialAnswer.answer)) {
				const currentChoice =
					currentQuestion.choices[potentialAnswer.answer.sliderIndex]

				answerEntry.tags = currentChoice.tags
				answerEntry.properties = {
					...currentChoice.properties,
				}
			} else if (
				Array.isArray(potentialAnswer.answer) &&
				isChoiceGroupAnswerType(potentialAnswer.answer[0])
			) {
				const newAnswerProperties = getPropertiesAndTags(
					potentialAnswer.answer,
					currentQuestion.choices
				)

				answerEntry.tags = newAnswerProperties.tags
				answerEntry.properties = newAnswerProperties.properties
			}
		} else if (
			isBasicQuestionType(currentQuestion) &&
			isBasicAnswerType(potentialAnswer.answer)
		) {
			if (
				Array.isArray(currentQuestion.bmiTagRanges) &&
				currentQuestion.calculateBmi &&
				currentQuestion.showHeightQuestion &&
				currentQuestion.showWeightQuestion &&
				potentialAnswer.answer.weight &&
				potentialAnswer.answer.height
			) {
				let userBmi =
					potentialAnswer.answer.weight /
					Math.pow(potentialAnswer.answer.height / 100, 2)

				userBmi = Math.round(userBmi * 100) / 100

				const matchingBmiRange = currentQuestion.bmiTagRanges.find(
					(bmiTagRange) =>
						userBmi >= bmiTagRange.bmiStart && userBmi <= bmiTagRange.bmiEnd
				)

				answerEntry.tags = matchingBmiRange ? matchingBmiRange.bmiTag : ''
			} else {
				answerEntry.tags = ''
			}
		}

		draftState.confirmedAnswers.push(answerEntry)
		draftState.slug =
			typeof nextQuestionId !== 'undefined'
				? nextQuestionId
				: questions[currentQuestionIndex + 1].id
	} else if (action.type === 'navigateBack') {
		const previousAnswer =
			draftState.confirmedAnswers[draftState.confirmedAnswers.length - 1]

		const confirmedAnswerIndex = draftState.confirmedAnswers.findIndex(
			(confirmedAnswer) =>
				confirmedAnswer.questionId === previousAnswer.questionId
		)

		draftState.confirmedAnswers.splice(confirmedAnswerIndex, 1)
		draftState.slug = previousAnswer.questionId
	} else if (action.type === 'updatePotentialAnswers') {
		const { surveyDefinition, potentialAnswers, slug } = draftState

		const currentQuestion = surveyDefinition.questions.find(
			(question) => question.id === slug
		)
		const potentialAnswerIndex = potentialAnswers.findIndex(
			(potentialAnswer) => potentialAnswer.questionId === slug
		)

		const potentialAnswer = potentialAnswers[potentialAnswerIndex]

		if (!currentQuestion) return

		if (
			isUpdatePotentialBasicAnswerType(action.payload) &&
			isBasicQuestionType(currentQuestion) &&
			isBasicAnswerType(potentialAnswer.answer) &&
			potentialAnswerIndex >= 0
		) {
			Object.assign(potentialAnswer.answer, action.payload)
		} else if (
			isChoicesQuestionType(currentQuestion) &&
			currentQuestion.choicesVariant === 'slider' &&
			isUpdatePotentialSliderAnswerType(action.payload)
		) {
			potentialAnswer.answer = action.payload
		} else if (
			isChoicesQuestionType(currentQuestion) &&
			isUpdatePotentialChoicesAnswerType(action.payload)
		) {
			const { choiceId, isChoiceChecked, choicesVariant } = action.payload

			if (
				potentialAnswer &&
				Array.isArray(potentialAnswer.answer) &&
				isChoiceGroupAnswerType(potentialAnswer?.answer[0])
			) {
				const { answer: clickedChoiceAnswer, answers: answerGroup } = {
					...getAnswerDefinition(choiceId, potentialAnswer.answer),
				}

				const clickedChoiceDefinition = getObjectById(
					choiceId,
					currentQuestion.choices
				)

				if (!clickedChoiceAnswer) return

				if (choicesVariant === 'radio') {
					answerGroup?.forEach((answer) => {
						answer.selected = false

						answer.answers?.forEach((subChoice) => {
							subChoice.selected = false
						})
					})

					clickedChoiceAnswer.selected = true

					if (clickedChoiceDefinition?.choicesVariant === 'slider') {
						clickedChoiceDefinition?.choices?.forEach((choice, choiceIndex) => {
							const numberOfChoices = clickedChoiceDefinition?.choices?.length

							if (
								numberOfChoices &&
								Math.floor((numberOfChoices - 1) / 2) === choiceIndex
							) {
								const medianValue = clickedChoiceAnswer?.answers?.[choiceIndex]

								if (medianValue) {
									medianValue.selected = true
								}
							}
						})
					}
				} else {
					const numberOfChoices = clickedChoiceDefinition?.choices?.length
					clickedChoiceAnswer.selected = isChoiceChecked

					clickedChoiceAnswer.answers?.forEach(
						(subChoice, subChoiceIndex: number) => {
							if (
								clickedChoiceDefinition?.choicesVariant === 'slider' &&
								numberOfChoices &&
								Math.floor((numberOfChoices - 1) / 2) === subChoiceIndex
							) {
								subChoice.selected = true
							} else {
								subChoice.selected = false
							}
						}
					)
				}
			} else {
				const clickedChoiceDefinition = getObjectById(
					choiceId,
					currentQuestion.choices
				)
				const numberOfChoices = clickedChoiceDefinition?.choices?.length

				const answers = currentQuestion.choices.map((choice) => ({
					id: choice.id,
					selected: choice.id === choiceId,
					answers: choice.choices?.map((subChoice, subChoiceIndex: number) => {
						if (
							clickedChoiceDefinition?.choicesVariant === 'slider' &&
							numberOfChoices &&
							Math.floor((numberOfChoices - 1) / 2) === subChoiceIndex
						) {
							return {
								id: subChoice.id,
								selected: true,
							}
						} else {
							return {
								id: subChoice.id,
								selected: false,
							}
						}
					}),
				}))

				if (answers) {
					const newPotentialAnswer: QuestionAnswerType = {
						id: NEW_ID,
						questionId: currentQuestion.id,
						type: currentQuestion.choicesVariant,
						answer: answers,
						tags: '',
					}

					draftState.potentialAnswers.push(newPotentialAnswer)
				}
			}
		}
	}
}

export default handlerReducer
