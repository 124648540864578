import * as React from 'react'
import { useFetch } from 'use-http'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'

import { getPrunedData } from '../utils/getPrunedData'

import { HandlerApiDataType } from '../types/index'

interface ISurveyContext {
	surveyData: HandlerApiDataType | null
	loading: boolean
	error: Error | null
}

const SurveyContext = React.createContext<ISurveyContext>({
	loading: false,
	error: null,
	surveyData: null,
})

export const SurveyFetcherProvider: React.FC = ({ children }) => {
	const [queryParams] = useQueryParams({
		userId: NumberParam,
		orgId: NumberParam,
		deptId: NumberParam,
		hash: StringParam,
		culture: StringParam,
	})

	const { userId, orgId, deptId, hash, culture } = queryParams

	const [surveyData, setSurveyData] = React.useState<HandlerApiDataType | null>(
		null
	)

	const { loading, error, data } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}/api/survey/${userId}/${orgId}/${deptId}/${hash}/${culture}`,
		{
			credentials: 'include',
		},
		[userId, orgId, deptId, hash, culture]
	)

	React.useEffect(() => {
		if (data) {
			const prunedData = getPrunedData(data)
			setSurveyData(prunedData)
		}
	}, [data])

	return (
		<SurveyContext.Provider value={{ surveyData, loading, error }}>
			{children}
		</SurveyContext.Provider>
	)
}

export default function useSurveyFetcher() {
	return React.useContext(SurveyContext)
}
