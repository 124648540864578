const equipmentOptions = [
	{ id: '60', description: 'Technogym' },
	{ id: '62', description: 'Dumbbells' },
	{ id: '95', description: 'Isotonic Line' },
	{ id: '100', description: 'Big ball' },
	{ id: '1074', description: 'Rehab Line' },
	{ id: '1076', description: 'T4r' },
	{ id: '261', description: 'VR' },
	{ id: '1023', description: 'Circuit' },
	{ id: '1065', description: 'Redcord' },
	{ id: '1091', description: 'Swim ring' },
	{ id: '1113', description: 'Discovery' },
	{ id: '1115', description: '6-Directions' },
	{ id: '63', description: 'Barbells' },
	{ id: '96', description: 'Selection Line' },
	{ id: '99', description: 'Small ball' },
	{ id: '1073', description: 'HUR' },
	{ id: '262', description: 'VR2' },
	{ id: '1024', description: 'Signature' },
	{ id: '1044', description: 'TRX' },
	{ id: '1100', description: 'Floating collar' },
	{ id: '1114', description: 'Vitality' },
	{ id: '1116', description: 'Catslide' },
	{ id: '260', description: 'Cybex' },
	{ id: '263', description: 'Eagle' },
	{ id: '1000', description: 'Cuff weight' },
	{ id: '1020', description: 'Kinesis' },
	{ id: '1025', description: 'DAP' },
	{ id: '1084', description: 'Stress ball' },
	{ id: '1066', description: 'Other slings' },
	{ id: '1092', description: 'Aquatic cuffs' },
	{ id: '270', description: 'Gervasport' },
	{ id: '1001', description: 'Weighted ankle cuffs' },
	{ id: '1026', description: 'Hammer Strength' },
	{ id: '1041', description: 'Pure Strength' },
	{ id: '1054', description: 'Medicine ball' },
	{ id: '1093', description: 'Wet vest' },
	{ id: '280', description: 'Olymp' },
	{ id: '1049', description: 'Weight vests' },
	{ id: '1062', description: 'Kinesis Stations' },
	{ id: '1094', description: 'Kickboard' },
	{ id: '290', description: 'Sportessa' },
	{ id: '1050', description: 'Weight plates' },
	{ id: '1095', description: 'Floaties' },
	{ id: '1008', description: 'Lifefitness' },
	{ id: '1098', description: 'Water dumbbells' },
	{ id: '1031', description: 'Matrix' },
	{ id: '1099', description: 'Pool noodle' },
	{ id: '1007', description: 'Hammer' },
	{ id: '1006', description: 'David' },
	{ id: '1005', description: 'Well Fit' },
	{ id: '1004', description: 'Competition Line' },
	{ id: '1017', description: 'Follo' },
	{ id: '1009', description: 'Other producers' },
	{ id: '113', description: 'Smith rack' },
	{ id: '1035', description: 'Gym 80' },
	{ id: '1037', description: 'Precor' },
	{ id: '1106', description: 'Mastercare' },
	{ id: '1075', description: 'NuStep' },
	{ id: '1061', description: 'Without Equipment' },
	{ id: '42', description: 'Machines' },
	{ id: '241', description: 'Balance cushion' },
	{ id: '91', description: 'Ball exercises' },
	{ id: '1088', description: 'Aquatic equipment' },
	{ id: '108', description: 'Bench, table, chair and wall' },
	{ id: '1085', description: 'Box' },
	{ id: '1022', description: 'BOSU' },
	{ id: '1077', description: 'Coreboard' },
	{ id: '1058', description: 'Foam roller' },
	{ id: '43', description: 'Free weights' },
	{ id: '1104', description: 'Parallel bars' },
	{ id: '1051', description: 'Skipping rope' },
	{ id: '1101', description: 'Hurdle' },
	{ id: '109', description: 'Indoor' },
	{ id: '44', description: 'Cable' },
	{ id: '1040', description: 'Kettlebells' },
	{ id: '1033', description: 'Crutches' },
	{ id: '1120', description: 'Letsip' },
	{ id: '1109', description: 'Mastercare' },
	{ id: '107', description: 'Mat and floor' },
	{ id: '1110', description: 'Motomed' },
	{ id: '1118', description: 'Pulpit Walker' },
	{ id: '1060', description: 'Prosthesis' },
	{ id: '1107', description: 'Prowler' },
	{ id: '1111', description: 'Bolster' },
	{ id: '1112', description: 'Wall bars' },
	{ id: '1048', description: 'Rings' },
	{ id: '1078', description: 'Walker' },
	{ id: '1034', description: 'Wheelchair' },
	{ id: '1056', description: 'Bed' },
	{ id: '1027', description: 'Slanting board' },
	{ id: '1045', description: 'Slides' },
	{ id: '1064', description: 'Suspension Training' },
	{ id: '1011', description: 'Nordic walking' },
	{ id: '1057', description: 'Step' },
	{ id: '1068', description: 'Stick' },
	{ id: '97', description: 'Elastic band' },
	{ id: '1055', description: 'Rope' },
	{ id: '1103', description: 'Theraputic putty' },
	{ id: '242', description: 'Trampoline' },
	{ id: '1082', description: 'Staircase' },
	{ id: '1046', description: 'TRX Rip Trainer' },
	{ id: '48', description: 'Outdoor' },
	{ id: '240', description: 'Balance board' },
]

export default equipmentOptions
