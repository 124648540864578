import lodash from 'lodash'

import IdGenerator from './IdGenerator'
import { getFormattedChoiceProperties } from './getFormattedChoiceProperties'

import {
	BasicDetailsPageType,
	BasicQuestionType,
	ChoicesPageType,
	ChoicesQuestionType,
	IntroPageType,
	LastPageType,
	SurveyDefinitionType,
	SurveyStartType,
	SurveySubmitType,
	TranslationsType,
	bmiTagRangeType,
	isBasicDetailsPageType,
	isBasicQuestionType,
	isChoicesPageType,
	isChoicesQuestionType,
	isIntroPageType,
	isLastPageType,
	isSurveyStartType,
	isSurveySubmitType,
} from '../types'

export const formatSurveyForExport = ({
	userId,
	orgId,
	deptId,
	surveyDefinition,
	conceptId,
}: {
	userId?: number
	orgId?: number | ''
	deptId?: number | ''
	surveyDefinition?: SurveyDefinitionType
	conceptId?: number
}) => {
	return {
		$type: 'Survey',
		ExternalId: surveyDefinition?.id,
		CreatedBy: surveyDefinition?.createdBy,
		Deleted: false,
		OrgId: orgId,
		DeptId: deptId,
		LastChangedBy: userId,
		Name: surveyDefinition?.name,
		SourceOrgId: surveyDefinition?.sourceOrgId,
		SourceDeptId: surveyDefinition?.sourceDeptId,
		PostProcessingRules: JSON.stringify(surveyDefinition?.postProcessingRules),
		SurveyPages: surveyDefinition?.questions.map((question) =>
			formatQuestionForServer(surveyDefinition?.questions, question)
		),
		ConceptId: conceptId,
	}
}

const formatQuestionForServer = (
	questions: Array<
		SurveyStartType | ChoicesQuestionType | BasicQuestionType | SurveySubmitType
	>,
	question:
		| SurveyStartType
		| ChoicesQuestionType
		| BasicQuestionType
		| SurveySubmitType
) => {
	if (isSurveyStartType(question)) {
		return {
			$type: getVariantForServer(question.choicesVariant),
			ExternalId: question.id,
			PageNumber: question.order,
			ShowPage: question.visible,
			Changed: !!question?.changed,
			HeaderTextKey: question.headerTextKey,
			NicNameTextKey: question.nicknameTextKey,
			IngressTextKey: question.ingressTextKey,
			AnsweredHeaderTextKey: question.answeredHeaderTextKey,
			AnsweredIngressTextKey: question.answeredIngressTextKey,
			HideWhenNotAnswered: question.hideWhenNotAnswered,
		}
	} else if (isChoicesQuestionType(question)) {
		return {
			$type: getVariantForServer(question.choicesVariant),
			ExternalId: question.id,
			PageNumber: question.order,
			ShowPage: question.visible,
			Changed: !!question?.changed,
			HeaderTextKey: question.headerTextKey,
			NicNameTextKey: question.nicknameTextKey,
			IngressTextKey: question.ingressTextKey,
			ImageId: question.imageId,
			VideoId: question.videoId,
			ObservationId: question.observationId,
			VerticalAlign: question.alignment === 'vertical',
			AnswerOptions: question.choices?.map((choice) => {
				const validChoiceOffset = questions[question.order + choice.offset + 1]
					? choice.offset
					: 0

				return {
					$type: 'AnswerOption',
					Order: choice.order,
					AllowOpenAnswer: false,
					ExternalId: choice.id,
					HeaderTextKey: choice.headerTextKey,
					NicNameTextKey: choice.nicknameTextKey,
					IngressTextKey: choice.ingressTextKey,
					SubOptionHeaderTextKey: choice.subQuestionHeaderTextKey,
					SubOptionNicknameTextKey: choice.subQuestionNicknameTextKey,
					SubOptionIngressTextKey: choice.subQuestionIngressTextKey,
					ImageId: choice.imageId,
					Properties: JSON.stringify(choice.properties),
					ShowIngress: true,
					Tag: choice.tags,
					TriggersPageOffset: validChoiceOffset > 0,
					PageOffset: validChoiceOffset,
					SubOptionType: choice.choicesVariant,
					SubOption: choice.choices?.map((subChoice) => {
						const validSubChoiceOffset = questions[
							question.order + subChoice.offset + 1
						]
							? subChoice.offset
							: 0

						return {
							$type: 'AnswerOption',
							Order: subChoice.order,
							AllowOpenAnswer: true,
							ExternalId: subChoice.id,
							HeaderTextKey: subChoice.headerTextKey,
							NicNameTextKey: subChoice.nicknameTextKey,
							IngressTextKey: subChoice.ingressTextKey,
							ImageId: subChoice.imageId,
							SubOptionType: subChoice.choicesVariant,
							Properties: JSON.stringify(subChoice.properties),
							ShowIngress: true,
							PageOffset: validSubChoiceOffset,
							TriggersPageOffset: validSubChoiceOffset > 0,
							Tag: subChoice.tags,
						}
					}),
				}
			}),
		}
	} else if (isBasicQuestionType(question)) {
		return {
			$type: getVariantForServer(question.choicesVariant),
			ExternalId: question.id,
			PageNumber: question.order,
			ShowPage: question.visible,
			Changed: !!question?.changed,
			HeaderTextKey: question.headerTextKey,
			NicNameTextKey: question.nicknameTextKey,
			IngressTextKey: question.ingressTextKey,
			ShowBirthdayQuestion: question.showBirthdayQuestion,
			ShowGenderQuestion: question.showGenderQuestion,
			ShowHeightQuestion: question.showHeightQuestion,
			ShowWeightQuestion: question.showWeightQuestion,
			FindBmi: question.calculateBmi,
			BmiSpecification: JSON.stringify(question.bmiTagRanges),
			ShowOnlyYear: question.showOnlyYear,
		}
	} else if (isSurveySubmitType(question)) {
		return {
			$type: 'LastPage',
			ExternalId: question.id,
			PageNumber: question.order,
			ShowPage: question.visible,
			Changed: !!question?.changed,
			HeaderTextKey: question.headerTextKey,
			NicNameTextKey: question.nicknameTextKey,
			IngressTextKey: question.ingressTextKey,
		}
	}
}

export const formatQuestionForLocalState = (
	question:
		| IntroPageType
		| BasicDetailsPageType
		| ChoicesPageType
		| LastPageType
) => {
	function setTagRangeId(bmiSpecificitaion: string) {
		try {
			const tagRanges: Array<bmiTagRangeType> = JSON.parse(bmiSpecificitaion)

			const newTagRanges = tagRanges.map((tagRange) => {
				const tagRangeWithId = lodash.cloneDeep(tagRange)

				if (!tagRange?.id) {
					tagRangeWithId.id = IdGenerator()
				}

				return tagRangeWithId
			})

			return newTagRanges
		} catch (error) {
			return []
		}
	}

	if (isBasicDetailsPageType(question)) {
		return {
			id: question?.ExternalId,
			order: question?.PageNumber,
			headerTextKey: question?.HeaderTextKey,
			nicknameTextKey: question?.NicNameTextKey,
			ingressTextKey: question?.IngressTextKey,
			visible: question?.ShowPage,
			choicesVariant: getVariantForLocalState(question['$type']),
			showBirthdayQuestion: question.ShowBirthdayQuestion,
			showGenderQuestion: question.ShowGenderQuestion,
			showHeightQuestion: question.ShowHeightQuestion,
			showWeightQuestion: question.ShowWeightQuestion,
			calculateBmi: question.FindBmi,
			bmiTagRanges: setTagRangeId(question.BmiSpecification),
			showOnlyYear: question.ShowOnlyYear,
			observationId: question.ObservationId,
		}
	} else if (isIntroPageType(question)) {
		return {
			id: question?.ExternalId,
			order: question?.PageNumber,
			headerTextKey: question?.HeaderTextKey,
			nicknameTextKey: question?.NicNameTextKey,
			ingressTextKey: question?.IngressTextKey,
			visible: question?.ShowPage,
			choicesVariant: getVariantForLocalState(question['$type']),
			imageId: question?.ImageId || 0,
			videoId: question?.VideoId || 0,
			answeredHeaderTextKey: question?.AnsweredHeaderTextKey,
			answeredIngressTextKey: question?.AnsweredIngressTextKey,
			hideWhenNotAnswered: question?.HideWhenNotAnswered,
		}
	} else if (isChoicesPageType(question)) {
		return {
			id: question?.ExternalId,
			order: question?.PageNumber,
			headerTextKey: question?.HeaderTextKey,
			nicknameTextKey: question?.NicNameTextKey,
			ingressTextKey: question?.IngressTextKey,
			visible: question?.ShowPage,
			choicesVariant: getVariantForLocalState(question['$type']),
			imageId: question?.ImageId || 0,
			videoId: question?.VideoId || 0,
			alignment: question?.VerticalAlign ? 'vertical' : 'horizontal',
			choices: question?.AnswerOptions?.sort((choiceA, choiceB) =>
				choiceA?.Order < choiceB?.Order ? -1 : 1
			).map((choice) => {
				const choiceProperties = getFormattedChoiceProperties(choice.Properties)

				return {
					id: choice?.ExternalId,
					order: choice.Order,
					properties: choiceProperties,
					headerTextKey: choice?.HeaderTextKey,
					nicknameTextKey: choice?.NicNameTextKey,
					ingressTextKey: choice?.IngressTextKey,
					imageId: choice?.ImageId,
					offset: choice?.PageOffset,
					tags: choice.Tag,
					subQuestionHeaderTextKey: choice?.SubOptionHeaderTextKey,
					subQuestionNicknameTextKey: choice?.SubOptionNicknameTextKey,
					subQuestionIngressTextKey: choice?.SubOptionIngressTextKey,
					choicesVariant: choice?.SubOptionType,
					choices: choice?.SubOption?.sort((subChoiceA, subChoiceB) =>
						subChoiceA?.Order < subChoiceB?.Order ? -1 : 1
					).map((subChoice) => {
						const subChoiceProperties = getFormattedChoiceProperties(
							subChoice.Properties
						)

						return {
							id: subChoice?.ExternalId,
							order: subChoice.Order, // Order is broken for sub-choices in the old Survey editor, so fixed here before using the data
							offset: subChoice?.PageOffset,
							properties: subChoiceProperties,
							headerTextKey: subChoice?.HeaderTextKey,
							nicknameTextKey: subChoice?.NicNameTextKey,
							ingressTextKey: subChoice?.IngressTextKey,
							imageId: subChoice?.ImageId,
							tags: subChoice?.Tag,
						}
					}),
				}
			}),
			observationId: question.ObservationId,
		}
	} else if (isLastPageType(question)) {
		return {
			id: question?.ExternalId,
			order: question?.PageNumber,
			headerTextKey: question?.HeaderTextKey,
			nicknameTextKey: question?.NicNameTextKey,
			ingressTextKey: question?.IngressTextKey,
			visible: question?.ShowPage,
			choicesVariant: 'SurveySubmit',
			imageId: question?.ImageId || 0,
			videoId: question?.VideoId || 0,
		}
	}
}

const getVariantForServer = (
	variant: string | undefined
): string | undefined => {
	if (variant === 'SurveyStart') return 'IntroPage'
	else if (variant === 'radio') return 'RadioButtonPage'
	else if (variant === 'checkbox') return 'MultipleChoicePage'
	else if (variant === 'slider') return 'SliderPage'
	else if (variant === 'basic') return 'BasicDetailsPage'
	else if (variant === 'SurveySubmit') return 'LastPage'
}

const getVariantForLocalState = (type: string) => {
	if (type === 'IntroPage') return 'SurveyStart'
	else if (type === 'BasicDetailsPage' || type === 'BasicDetailsAnswerPage')
		return 'basic'
	else if (type === 'MultipleChoicePage' || type === 'MultipleChoiceAnswerPage')
		return 'checkbox'
	else if (type === 'RadioButtonPage' || type === 'RadioButtonAnswerPage')
		return 'radio'
	else if (type === 'SliderPage' || type === 'SliderAnswerPage') return 'slider'
	else if (type === 'LastPage') return 'SurveySubmit'
	else return undefined
}

export const formatTranslationsForExport = (
	translations?: TranslationsType
) => {
	const translatedLanguages = translations && Object.entries(translations)

	const translationsForExport = translatedLanguages?.reduce(
		(formattedLanguages, [language, languageTranslations]) => {
			const unformattedTranslations = Object.entries(languageTranslations)
			const formattedTranslations = unformattedTranslations.reduce(
				(translationDefinitions, [translationKey, translationDefintion]) => {
					return {
						...translationDefinitions,
						[translationKey]: {
							$type: 'Translation',
							Culture: language,
							Key: translationDefintion?.key,
							Text: translationDefintion?.text,
						},
					}
				},
				{}
			)

			return {
				...formattedLanguages,
				[language]: formattedTranslations,
			}
		},
		{}
	)

	return translationsForExport
}
