import { SCREENING_PROPERTY_VALUE_TYPE } from '../types'

export const transformValuesToArray = (
	valueType: string,
	valueRange: string
) => {
	let valueArray: string[] = []

	if (valueType === SCREENING_PROPERTY_VALUE_TYPE.int) {
		const [startValue, endValue] = valueRange
			.split(',')
			.map((value: string) => parseInt(value))

		for (let iterator = startValue; iterator <= endValue; iterator++) {
			valueArray.push(iterator.toString())
		}
	} else if (valueType === SCREENING_PROPERTY_VALUE_TYPE.string) {
		valueArray = valueRange.split(',')
	}

	return valueArray
}
