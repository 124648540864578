import React, { useMemo } from 'react'
import {
	State,
	TRelationalDataHandlers,
} from '../../../../hooks/useHandleRelationalData'
import styled, { css } from 'styled-components'
import SearchInput from '../../SearchInput'
import { HealthDataModelType } from '../../globaltypes'
import RelationalItem from './RelationalItem'
import { useHealthDataContext } from '../../../../screens/HealthData'

const cssStyle = css`
	.content-type-h4 {
		color: red;
	}

	.group-title-button {
		display: flex;
		align-items: center;
	}

	.group-search-view-button {
		height: 2em;
		margin: 0 1em;
	}

	button {
		border: 1px solid black;
		border-radius: 3px;
	}
`

const Styler = styled.article`
	${cssStyle}
`

const RelationalUpdater = ({
	updateHandlers,
	state,
	context,
}: {
	updateHandlers: TRelationalDataHandlers
	state: State
	context: HealthDataModelType
}) => {
	const { addObservation, removeObservation, addGroup, removeGroup } =
		updateHandlers
		// relational data stored in the editRelationalData reducer
	const { observations: stateObservations, groups: stateGroups } = state
		// The lists of all relational data from context
	const { observations: obsList, groups: grpList } = useHealthDataContext().data
	const unassignedObservations = useMemo(() =>  obsList.filter((listItem) => listItem.groupId === 0 || listItem.groupId == null ), [obsList])

	if (context === HealthDataModelType.Group)
		return (
			<Styler>
				<hr />
				<h4 className={'content-type-h4'}>Observations:</h4>
				<SearchInput
					addItem={addObservation}
					searchItems={obsList}
					list={stateObservations}
				/>
				<div>
					{stateObservations?.map((observation) => (
						<RelationalItem
							key={`${observation.code}`}
							contentItem={observation}
							removeItem={removeObservation}
						/>
					))}
				</div>
			</Styler>
		)

	if (context === HealthDataModelType.Concept)
		return (
			<Styler>
			<hr />
			<h4 className={'content-type-h4'}>Groups: </h4>
			<SearchInput addItem={addGroup} searchItems={grpList} list={stateGroups} />
			<div>
				{stateGroups.length ? (
					stateGroups.map((group) => (
						<RelationalItem
							key={`${group.code}`}
							contentItem={group}
							removeItem={removeGroup}
						/>
					))
				) : (
					<p>* None *</p>
				)}
			</div>
			<hr />
				<h4 className={'content-type-h4'}>Observations:</h4>
				<SearchInput
					addItem={addObservation}
					searchItems={unassignedObservations}
					list={stateObservations}
				/>
				<div>
					{stateObservations.length ? (
						stateObservations?.map((observation) => (
							<RelationalItem
								key={`${observation.code}`}
								contentItem={observation}
								removeItem={removeObservation}
							/>
						))
					) : (
						<p>* None *</p>
					)}
				</div>
		</Styler>
		)

	return <p>Error occurred, whilst loading contents</p>
}

export default RelationalUpdater
