import React from 'react'

import { useHandlerDispatch, useHandlerState } from '../context/HandlerProvider'

import Button, { ButtonVariant } from '../components/Button'
import { css } from 'styled-components/macro'

const Navigation: React.FC<NavigationProps> = ({ translations }) => {
	const { confirmedAnswers, potentialAnswers, slug } = useHandlerState()
	const handlerDispatch = useHandlerDispatch()

	const onBackClick = () => {
		handlerDispatch({
			type: 'navigateBack',
		})

		window.scrollTo(0, 0)
	}

	const onNextClick = () => {
		handlerDispatch({
			type: 'navigateForward',
		})

		window.scrollTo(0, 0)
	}

	const backDisabled = !confirmedAnswers.length
	const nextDisabled = !potentialAnswers.find(
		(potentialAnswer) => potentialAnswer.questionId === slug
	)?.answer

	return (
		<div
			css={css`
				display: flex;
				justify-content: flex-end;
				gap: 1rem;
			`}
		>
			<Button
				variant={ButtonVariant.secondary}
				disabled={backDisabled}
				onClick={!backDisabled ? onBackClick : undefined}
			>
				{translations['ButtonBack']}
			</Button>
			<Button
				variant={ButtonVariant.primary}
				disabled={nextDisabled}
				onClick={!nextDisabled ? onNextClick : undefined}
			>
				{translations['ButtonSubmitTextKey']}
			</Button>
		</div>
	)
}

export default Navigation

type NavigationProps = {
	translations: {
		[translationKey: string]: string
	}
}
