import React from 'react'
import { RelationalData } from '../HealthDataTable'
import OverlayContainer from './OverlayContainer'
import { css } from 'styled-components/macro'

const RelationsView = ({
	contents,
	context,
	onClose,
}: {
	contents: RelationalData
	context: string
	onClose: () => void
}) => {
	return (
		<OverlayContainer>
			<div
				css={css`
					min-width: 40em;
					display: flex;
					flex-direction: column;
					align-items: center;
				`}
			>
				<h2>Contents</h2>
				{contents.observations.length ? (
					<table
						css={css`
							min-width: 30em;
						`}
					>
						<caption
							css={css`
								font-weight: 700;
								font-size: 1.3em;
							`}
						>
							Observations
						</caption>
						<thead>
							<tr>
								<th>Code</th>
								<th>Title</th>
							</tr>
						</thead>
						<tbody>
							{contents.observations.map((elm, i) => {
								return (
									<tr key={`observation-td-${i}`}>
										<td className='tableData'>
											{elm.code?.toString().toUpperCase()}
										</td>
										<td className='tableData'>{elm.title}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				) : null}

				{contents.groups.length ? (
					<table
						css={css`
							min-width: 30em;
						`}
					>
						<caption
							css={css`
								font-weight: 700;
								font-size: 1.3em;
							`}
						>
							Groups
						</caption>
						<thead>
							<tr>
								<th>Code</th>
								<th>Title</th>
							</tr>
						</thead>
						<tbody>
							{contents.groups.map((elm, i) => {
								return (
									<tr key={`group-td-${i}`}>
										<td className='tableData'>
											{elm.code?.toString().toUpperCase()}
										</td>
										<td className='tableData'>{elm.title}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				) : null}

				{!contents.groups.length && !contents.observations.length ? (
					<p>*None*</p>
				) : null}

				<button
					css={css`
						width: 5em;
						padding: 0.5em;
					`}
					onClick={() => onClose()}
				>
					Close
				</button>
			</div>
		</OverlayContainer>
	)
}
export default RelationsView
