export const getObjectsWithField = <Type extends Record<string, unknown>>(
	field: keyof Type,
	objects: Type[],
	objectsWithField: Type[] = []
): Type[] => {
	for (const object of objects) {
		if (field in object) {
			objectsWithField.push(object)
		}

		for (const value of Object.values(object)) {
			if (Array.isArray(value)) {
				objectsWithField.push(...getObjectsWithField(field, value))
			}
		}
	}

	return objectsWithField
}
