import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { css } from 'styled-components/macro'
import { SurveyHealthData } from '../../types'
import { ReactComponent as DeleteIcon } from '../../images/delete.svg'


type ConceptSelectorProps = {
	selected: string
	concepts: SurveyHealthData | null
	onUpdate: (title: string) => void
}

const ConceptSelector = ({
	concepts,
	onUpdate,
	selected,
}: ConceptSelectorProps) => {
	const [conceptOptions, setConceptOptions] = useState<string[]>([])
	const handleConceptReset = () => onUpdate('')

	useEffect(
		() =>
			setConceptOptions(() => (concepts ? concepts.map((c) => c.title) : [])),
		[concepts]
	)
	if (!concepts) return <Fragment />

	if (selected !== '')
		return (
	<SelectorContainer>
			<div
				css={css`
					width: fit-content;
					

					#concept-selector-container {
						display: flex;
						align-items: center;
					}

					#concept-display {
					color: white;
					margin: 0 1rem;
					padding: 0.5rem;
					border-radius: 0.2em;
					background-color: #28a745;
					text-transform: capitalize;
				}
				`}
			>
				<div
					id='concept-selector-container'
					css={css`
						display: flex;
					`}
				>
					<p>Selected Concept: </p>
				<p id='concept-display'>
					{selected}
				</p>

				<button id="concept-remove-btn" onClick={handleConceptReset}>
					<DeleteIcon />
				</button>

				</div>
			</div>
			</SelectorContainer>
		)

	return (
		<SelectorContainer>
			<Fragment>
				<div id='concept-selector-container'>
					<div className='option-selector'>
						<label htmlFor='concept-select'>Select a Concept</label>
						<select
							name='concept-select'
							id='concept-select'
							value={selected}
							onChange={(evt) => onUpdate(evt.target.value)}
						>
							<option value='' />
							{conceptOptions.map((value, i) => (
								<option key={`concept-option-${i}`} value={value}>
									{value}
								</option>
							))}
						</select>
					</div>
				</div>
			</Fragment>
			</SelectorContainer>
	)
}

const SelectorContainer = ({children} : {children: ReactNode}) => (
<div
			id='concept-selector-selector-container'
			css={css`
				border: 1px solid black;
				padding: 1rem;
				border-radius: 0.5rem;
				background-color: #2888da1a;

				.option-selector {
					display: flex;
					flex-direction: column;
				}

				#isHealthdata-toggle {
					margin: 0 0 0 1rem;
					width: 2rem;
				}

				#concept-select {
					text-transform: capitalize;
					width: 14rem;
				}

				@media (max-width: 790px) {
					#concept-selector-container {
						display: block;
					}
				}
			`}
		>
			{children}
		</div>
)

export default ConceptSelector
