import { getObjectById } from './getObject'
import { getObjectsWithField } from './getObjects'
import { getUpdatedTree } from './getUpdatedTree'

import {
	ChoiceGroupAnswerType,
	ChoiceType,
	RecommendationPropertyType,
	ScreeningPropertyType,
} from '../types'

export const getPropertiesAndTags = (
	answers: ChoiceGroupAnswerType[],
	choices: ChoiceType[],
	returnArray: {
		tags: string[]
		properties: {
			recommendation: RecommendationPropertyType[]
			screening: ScreeningPropertyType[]
		}
	} = { tags: [], properties: { recommendation: [], screening: [] } }
) => {
	for (const answer of answers) {
		if (answer.selected) {
			if (answer.answers && answer.answers.length > 0) {
				getPropertiesAndTags(answer.answers, choices, returnArray)
			}

			const choiceDefinition = getObjectById(answer.id, choices)

			if (choiceDefinition) {
				if (choiceDefinition.tags) {
					returnArray.tags.push(choiceDefinition.tags)
				}

				const { recommendation, screening } = choiceDefinition.properties

				if (recommendation) {
					for (const property of recommendation) {
						const existingProperty = returnArray.properties.recommendation.find(
							(existingProperty) => existingProperty.id === property.id
						)

						if (existingProperty) {
							const updatedProperty = {
								...existingProperty,
								grade: existingProperty.grade + property.grade,
							}

							const updatedRecommendations = getUpdatedTree(
								updatedProperty,
								returnArray.properties.recommendation
							)

							returnArray.properties.recommendation = updatedRecommendations
						} else {
							returnArray.properties.recommendation.push(property)
						}
					}
				}

				if (screening) {
					const propertiesWithValue = getObjectsWithField('value', screening)

					const propertyIds = new Set(
						returnArray.properties.screening.map((property) => property.id)
					)

					returnArray.properties.screening = [
						...returnArray.properties.screening,
						...propertiesWithValue.filter(
							(property) => !propertyIds.has(property.id)
						),
					]
				}
			}
		}
	}

	return returnArray
}
