import * as React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { css } from 'styled-components/macro'

import { SurveyQueryParams } from '../../types'

const NotFound: React.FC<NotFoundProps> = ({ linkHome }) => {
	const { search } = useLocation()
	const { orgToRequest, deptToRequest } = useParams<SurveyQueryParams>()

	return (
		<div
			css={css`
				margin: 0 auto;
			`}
		>
			<h3>404. Not found.</h3>
			{linkHome ? (
				<div>
					<p>
						Return to{' '}
						<Link
							to={{
								pathname: `/editor/${orgToRequest}/${deptToRequest}`,
								search: search,
							}}
						>
							editor
						</Link>
					</p>
				</div>
			) : (
				<p>The requested URL was not found on this server</p>
			)}
		</div>
	)
}

export default NotFound

type NotFoundProps = {
	children?: React.ReactNode
	linkHome?: boolean
}
