import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DefaultEntry } from '../../../../screens/HealthData'

const cssStyle = css`
	.rData-list-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid black;
		border-radius: 4px;
		margin-bottom: 0.4em;
		padding: 0 1em;
	}
	
	.rData-item-title {
		margin: 0.7em; 0;
	}

	.rData-item-button {
		height: 2em;
	}
`

const Styler = styled.div`
	${cssStyle}
`

type RelationalItemProps<T extends DefaultEntry> = {
	contentItem: T
	removeItem: (item: T) => void
}

const RelationalItem = <T extends DefaultEntry>({
	contentItem,
	removeItem,
}: RelationalItemProps<T>) => {
	const { code, title } = contentItem
	return (
		<Styler>
			<div className='rData-list-item'>
				<p className='rData-item-title'>{`${code.toUpperCase()} - ${title}`}</p>
				<button
					title='Remove Item'
					className={'rData-item-button'}
					type='button'
					onClick={() => removeItem(contentItem)}
				>
					<span className='removeIcon'></span>
				</button>
			</div>
		</Styler>
	)
}

export default RelationalItem
