import React from 'react'
import { css } from 'styled-components/macro'

const OverlayContainer = ({ children }: { children: React.ReactNode }) => (
	<section
		css={css`
			width: 100%;
			height: 100%;
			position: fixed;
			left: 0;
			top: 0;
			background-color: rgba(188, 181, 185, 0.8);
			z-index: 10;
			display: flex;
			justify-content: center;
			align-items: center;
		`}
	>
		<div
			css={css`
				z-index: 20;
				padding: 1em;
				background-color: white;
				text-align: center:
				border-radius: 5px;
				`}
		>
			{children}
		</div>
	</section>
)

export default OverlayContainer
