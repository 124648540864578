import { NEW_ID } from '../../constants'

import {
	AddNewTranslationPayloadType,
	NewSubchoicePayload,
	TranslationActionType,
	TranslationsType,
	UpdateTranslationsPayloadType,
} from '../../types'

export const translationsReducer = (
	draftState: TranslationsType | undefined,
	action: TranslationActionType
) => {
	if (!draftState) return
	if (action.type === 'updateTranslation') {
		if (!isUpdateTranslationPayload(action.payload)) return

		const { key, translateTo, text } = action.payload
		if (key) {
			draftState[translateTo][key] = {
				key: key,
				changed: true,
				text: text,
				timeStamp: Date.now(),
			}
		}
	} else if (action.type === 'addNewTranslation') {
		if (!isAddNewTranslationPayload(action.payload)) return

		const cultureToTranslateTo = draftState['en-US']

		if (typeof cultureToTranslateTo === 'undefined') return

		const { text, ...textKeys } = action.payload

		Object.values(textKeys).forEach((textKey) => {
			const newTranslation = {
				id: NEW_ID,
				key: textKey,
				changed: true,
				text: text || '',
				timeStamp: Date.now(),
			}

			cultureToTranslateTo[textKey] = newTranslation
		})
	} else if (action.type === 'deleteTranslation') {
		if (!isDeleteTranslationPayload(action.payload)) return

		const cultureToDeleteFrom = draftState['en-US']
		const { headerTextKey, ingressTextKey } = action.payload

		delete cultureToDeleteFrom?.[headerTextKey]
		delete cultureToDeleteFrom?.[ingressTextKey]
	}
}

const isDeleteTranslationPayload = (
	variableToCheck: unknown
): variableToCheck is NewSubchoicePayload =>
	(variableToCheck as NewSubchoicePayload).headerTextKey !== undefined &&
	(variableToCheck as NewSubchoicePayload).ingressTextKey !== undefined

const isAddNewTranslationPayload = (
	variableToCheck: unknown
): variableToCheck is AddNewTranslationPayloadType =>
	(variableToCheck as AddNewTranslationPayloadType).headerTextKey !==
		undefined ||
	(variableToCheck as AddNewTranslationPayloadType).nicknameTextKey !==
		undefined ||
	(variableToCheck as AddNewTranslationPayloadType).ingressTextKey !==
		undefined ||
	(variableToCheck as AddNewTranslationPayloadType).subQuestionHeaderTextKey !==
		undefined ||
	(variableToCheck as AddNewTranslationPayloadType)
		.subQuestionNicknameTextKey !== undefined ||
	(variableToCheck as AddNewTranslationPayloadType)
		.subQuestionIngressTextKey !== undefined

const isUpdateTranslationPayload = (
	variableToCheck: unknown
): variableToCheck is UpdateTranslationsPayloadType =>
	(variableToCheck as UpdateTranslationsPayloadType).text !== undefined &&
	(variableToCheck as UpdateTranslationsPayloadType).translateTo !==
		undefined &&
	(variableToCheck as UpdateTranslationsPayloadType).key !== undefined

export default translationsReducer
