import * as React from 'react'
import { useParams } from 'react-router-dom'

import { useEditorState } from '../../context/EditorProvider'

import Container from '../../components/Container'

import Question, { QuestionWrapper } from './Question'
import NotFound from '../NotFound'

import { SurveyQueryParams } from '../../types/index'

const QuestionEditor: React.FC = () => {
	const { surveyDefinition } = useEditorState()
	const { questionIndex } = useParams<SurveyQueryParams>()

	let question = undefined

	if (questionIndex && surveyDefinition) {
		question = surveyDefinition.questions[parseInt(questionIndex)]
	}

	if (!question)
		return (
			<QuestionWrapper>
				<Container>
					<NotFound linkHome />
				</Container>
			</QuestionWrapper>
		)

	return <Question question={question} conceptId={surveyDefinition?.conceptId ?? 0} />
}

export default QuestionEditor
