import {
	SurveyDefinitionQuestionType,
	isValidChoicesQuestionType,
	isValidBasicQuestionType,
	TagsSortedBySkipsType,
} from '../../../types'

export const sortTagsBySkips = (questions: SurveyDefinitionQuestionType[]) => {
	const tagsSortedBySkips = questions.reduce(
		(tagsSortedBySkips: TagsSortedBySkipsType[], question, questionIndex) => {
			const questionTagsSortedBySkips: TagsSortedBySkipsType = {}

			if (isValidChoicesQuestionType(question) && question.choices.length) {
				question.choices.forEach((choice) => {
					if (choice.choices?.length) {
						choice.choices.forEach((subChoice) => {
							if (subChoice.offset >= 0) {
								const choiceTag = `${choice.tags.toLowerCase()}${
									choice.tags && subChoice.tags ? ' ' : ''
								}${subChoice.tags.toLowerCase()}`

								let subChoiceOffset = subChoice.offset

								if (
									typeof questions[questionIndex + 1 + subChoiceOffset] ===
									'undefined'
								) {
									subChoiceOffset = 0
								}

								if (
									typeof questionTagsSortedBySkips[subChoiceOffset] ===
									'undefined'
								) {
									questionTagsSortedBySkips[subChoiceOffset] = []
								}

								if (
									questionTagsSortedBySkips[subChoiceOffset].includes(choiceTag)
								) {
									return
								}

								questionTagsSortedBySkips[subChoiceOffset].push(choiceTag)
							}
						})
					} else if (choice.offset >= 0) {
						let choiceOffset = choice.offset

						if (
							typeof questions[questionIndex + 1 + choiceOffset] === 'undefined'
						) {
							choiceOffset = 0
						}

						if (
							typeof questionTagsSortedBySkips[choiceOffset] === 'undefined'
						) {
							questionTagsSortedBySkips[choiceOffset] = []
						}

						if (
							questionTagsSortedBySkips[choiceOffset].includes(
								choice.tags.toLowerCase()
							)
						) {
							return
						}

						questionTagsSortedBySkips[choiceOffset].push(
							choice.tags.toLowerCase()
						)
					}
				})
			} else if (isValidBasicQuestionType(question)) {
				if (typeof questionTagsSortedBySkips[0] === 'undefined') {
					questionTagsSortedBySkips[0] = []
				}
				if (question.bmiTagRanges.length) {
					question.bmiTagRanges.forEach((bmiTagRange) => {
						if (
							questionTagsSortedBySkips[0].includes(
								bmiTagRange.bmiTag.toLowerCase()
							)
						) {
							return
						}

						questionTagsSortedBySkips[0].push(bmiTagRange.bmiTag.toLowerCase())
					})
				} else {
					questionTagsSortedBySkips[0] = ['']
				}
			}

			if (Object.keys(questionTagsSortedBySkips).length) {
				tagsSortedBySkips.push(questionTagsSortedBySkips)
			}

			return tagsSortedBySkips
		},
		[]
	)

	return tagsSortedBySkips
}
