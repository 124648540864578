import * as React from 'react'
import { css } from 'styled-components/macro'
import color from 'color'

import { CssFunction, StyleVariants } from '../types/styled'

export enum AlertVariant {
	default = 'default',
	error = 'error',
	success = 'success',
	warning = 'warning',
}

const Alert: React.FC<AlertProps> = ({
	variant = AlertVariant.default,
	children,
	containerCss,
	...props
}) => {
	return (
		<div
			className='alert'
			css={[
				css`
					position: relative;
					display: flex;
					flex-direction: column;
					margin-bottom: 1rem;
					border-radius: 2px;

					> * {
						margin-left: 1rem;
					}
				`,
				variantStyles[variant],
				containerCss,
			]}
			{...props}
		>
			{children}
		</div>
	)
}

export default Alert

type ReactDiv = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>

type AlertProps = ReactDiv & {
	variant?: AlertVariant
	containerCss?: CssFunction
	css?: never
}

const variantStyles: StyleVariants<AlertVariant> = {
	[AlertVariant.default]: css`
		background: ${color('#4157ff').lighten(0.3).hex()};
		border-left: 0.5rem solid #4157ff;
	`,
	[AlertVariant.error]: css`
		background: ${color('#ff0400').lighten(0.8).hex()};
		border-left: 0.5rem solid #ff0400;
	`,
	[AlertVariant.success]: css`
		background: ${color('#28a745').lighten(1).hex()};
		border-left: 0.5rem solid #28a745;
	`,
	[AlertVariant.warning]: css`
		background: ${color('#ffda47').lighten(0.28).hex()};
		border-left: 0.5rem solid #ffda47;
	`,
}
