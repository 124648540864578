import * as React from 'react'
import { css } from 'styled-components/macro'
import { useHandlerDispatch } from '../context/HandlerProvider'

import arrowDown from '../images/arrow-down.svg'
import { ReactComponent as CalendarIcon } from '../images/calendar.svg'

import { BasicQuestionType, BasicAnswerType } from '../types'

const BasicInfo: React.FC<BasicInfoProps> = ({
	currentQuestion,
	translations,
	potentialAnswer,
}) => {
	const handlerDispatch = useHandlerDispatch()

	const onBasicInfoChange = (name: string, newValue: string | number) => {
		handlerDispatch({
			type: 'updatePotentialAnswers',
			payload: {
				[name]: newValue,
			},
		})
	}

	let startYear = new Date().getFullYear()
	const endYear = new Date((startYear - 100).toString()).getFullYear()

	const validYears = []

	for (startYear; startYear > endYear; startYear--) {
		validYears.push(startYear)
	}

	return (
		<div
			css={css`
				padding: 1rem 0;
			`}
		>
			{currentQuestion.showGenderQuestion && (
				<div css={formSectionStyle}>
					<label htmlFor='gender-select'>
						{translations.BasicDetailsGenderKey}
					</label>
					<select
						css={selectStyle}
						id='gender-select'
						name='gender'
						value={potentialAnswer?.gender || ''}
						onChange={(event: React.FormEvent<HTMLSelectElement>) =>
							onBasicInfoChange(
								event?.currentTarget?.name,
								event.currentTarget.value
							)
						}
					>
						<option value='' disabled>
							{translations.BasicDetailsGenderSelectKey}
						</option>
						<option value='male'>
							{translations.BasicDetailsGenderMaleKey}
						</option>
						<option value='female'>
							{translations.BasicDetailsGenderFemaleKey}
						</option>
						<option value='other'>
							{translations['BasicDetailsGenderNoneKey']}
						</option>
					</select>
				</div>
			)}
			{currentQuestion.showBirthdayQuestion && (
				<div css={formSectionStyle}>
					{currentQuestion.showOnlyYear ? (
						<React.Fragment>
							<label htmlFor='birth-year'>
								{translations.BasicDetailsBirthYearKey}
							</label>
							<select
								css={selectStyle}
								id='birth-year'
								name='birthday'
								value={potentialAnswer?.birthday || ''}
								onChange={(event: React.FormEvent<HTMLSelectElement>) =>
									onBasicInfoChange(
										event?.currentTarget?.name,
										event.currentTarget.value
									)
								}
							>
								<option value=''></option>
								{validYears.map((validYear) => (
									<option key={validYear} value={validYear}>
										{validYear}
									</option>
								))}
							</select>
						</React.Fragment>
					) : (
						<React.Fragment>
							<label htmlFor='birthday'>
								{translations.BasicDetailsBirthdayKey}
							</label>
							<div
								css={css`
									position: relative;
									display: flex;
									justify-content: center;

									span {
										display: flex;
										align-items: center;
										justify-content: center;
										position: absolute;
										right: 1rem;
										top: 0;
										bottom: 0;
										pointer-events: none;
										border: none;
										background: transparent;
										padding: 0;
									}
								`}
							>
								<input
									css={[
										inputStyle,
										css`
											cursor: pointer;
											width: 100%;
										`,
									]}
									id='birthday'
									name='birthday'
									type='date'
									value={potentialAnswer?.birthday || ''}
									onChange={(event) =>
										onBasicInfoChange(
											event.currentTarget.name,
											event.currentTarget.value
										)
									}
								/>
								<span>
									{/*
										Set background-color to hide the X icon on FF.
										No selector exists for that element. [AW]
									*/}
									<CalendarIcon
										css={css`
											background-color: #ffffff;
										`}
									/>
								</span>
							</div>
						</React.Fragment>
					)}
				</div>
			)}
			{currentQuestion.showHeightQuestion && (
				<div css={formSectionStyle}>
					<label htmlFor='height'>
						{translations.BasicDetailsHeightKey} - cm
					</label>
					<input
						css={inputStyle}
						id='height'
						name='height'
						type='number'
						value={potentialAnswer?.height || ''}
						onChange={(event: React.FormEvent<HTMLInputElement>) =>
							onBasicInfoChange(
								event.currentTarget.name,
								parseInt(event.currentTarget.value)
							)
						}
					/>
				</div>
			)}
			{currentQuestion.showWeightQuestion && (
				<div css={formSectionStyle}>
					<label htmlFor='weight'>
						{translations.BasicDetailsWeightKey} - kg
					</label>
					<input
						css={inputStyle}
						id='weight'
						name='weight'
						type='number'
						value={potentialAnswer?.weight || ''}
						onChange={(event: React.FormEvent<HTMLInputElement>) =>
							onBasicInfoChange(
								event.currentTarget.name,
								parseInt(event.currentTarget.value)
							)
						}
					/>
				</div>
			)}
		</div>
	)
}

export default BasicInfo

type BasicInfoProps = {
	currentQuestion: BasicQuestionType
	translations: { [translationKey: string]: string }
	potentialAnswer: BasicAnswerType
}

/* Start of styling */
const formSectionStyle = css`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
`

const inputStyle = css`
	position: relative;
	border: 1px solid #c0c0c0;
	border-radius: 4px;
	background-color: #ffffff;
	padding: 0 1rem;
	/* -2 px due to border top and bottom */
	height: calc(45px - 2px);

	-webkit-appearance: none;

	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	::-webkit-calendar-picker-indicator {
		background-image: none;
	}

	&:focus {
		outline: 0;
		box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.secondaryColor};
	}
`

const selectStyle = css`
	border: 1px solid #c0c0c0;
	border-radius: 4px;
	background-color: #ffffff;
	padding: 0 1rem;
	height: 45px;
	position: relative;
	appearance: none;
	background: url(${arrowDown});
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1.5rem;
	background-color: white;

	&:focus {
		outline: 0;
		box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.secondaryColor};
	}
`
/* End of styling */
