import * as React from 'react'
import { css } from 'styled-components/macro'

import { CssFunction } from '../types/styled'

const Media: React.FC<MediaProps> = ({ imageId, videoId, containerCss }) => {
	if (!imageId && !videoId) return null

	return (
		<div
			css={[
				css`
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 1rem 0;

					> :not(:last-child) {
						margin-bottom: 1rem;
					}
				`,
				containerCss,
			]}
		>
			{videoId ? (
				<div
					css={[
						css`
							position: relative;
							width: 100%;
							height: 0;
							padding-bottom: 56.25%;
						`,
					]}
				>
					<iframe
						css={[
							css`
								border: none;
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
							`,
						]}
						title='video'
						src={`${process.env.REACT_APP_EXORLIVE_URL}/video/?ex=${videoId}&clean=true`}
						aria-hidden
					/>
				</div>
			) : null}
			{imageId ? (
				<img
					css={[
						css`
							display: block;
							height: auto;
							max-height: 200px;
							width: 100%;
							object-fit: contain;
						`,
					]}
					src={`${process.env.REACT_APP_EXORLIVE_MEDIA_URL}/image/${imageId}?env=${process.env.REACT_APP_EXORLIVE_ENV}`}
					alt='Question media'
				/>
			) : null}
		</div>
	)
}

export default Media

type MediaProps = {
	imageId?: number
	videoId?: number
	containerCss?: CssFunction
	imageCss?: CssFunction
	videoCss?: CssFunction
	iframeCss?: CssFunction
}
