import PageMenu from './PageMenu'
import Observation, { ObservationData } from './Observation'
import { Route, Switch } from 'react-router-dom'
import Group, { GroupData } from './Group'
import Concept, { ConceptData, ConceptEntry } from './Concept'
import useFetch, { CachePolicies } from 'use-http'
import React, {
	useCallback,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react'
import { sortByCode } from '../../utils/HealthData/sortByCode'

/*
 * This is the main component for the HealthData screen
 * The names follows the FHIR standard for objects in healthdata
 * See https://www.hl7.org/fhir/ for more information
 */

interface ContextProps {
	data: {
		observations: ObservationData
		groups: GroupData
		concepts: ConceptData
	}
	setData: React.Dispatch<React.SetStateAction<ContextProps['data']>>
}

export const HealthDataContext = createContext<ContextProps | undefined>(
	undefined
)

export const useHealthDataContext = () => {
	const context = useContext(HealthDataContext)

	if (!context) {
		throw new Error(
			'HealthDataContext must be used within a HealthDataContext Provider'
		)
	}

	return context
}

const HealthData = () => {
	const [, setRequestResult] = useState('')

	const [data, setData] = useState<ContextProps['data']>({
		observations: [],
		groups: [],
		concepts: [],
	})

	const { request: fetchRequest, error } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	type SortFn<K extends DefaultEntry | ConceptEntry> = (data: K[]) => K[]

	const sortGroupsOrObservations = <T extends DefaultEntry>(data: T[]): T[] =>
		sortByCode(data)

	const sortConcepts = <T extends ConceptEntry>(data: T[]): T[] =>
		data.sort((a: T, b: T) => a.title.localeCompare(b.title))

	const useGetData = <T extends DefaultEntry | ConceptEntry>(
		url: string,
		sortFn: SortFn<T>
	) => {
		const callback = useCallback(async () => {
			try {
				const result = await fetchRequest.get(url)
				const data: T[] = result
				if (data.length > 0) {
					return sortFn(data)
				}
				return data
			} catch (error) {
				return []
			}
		}, [sortFn, url])

		return callback
	}

	const getObservations = useGetData(
		'api/healthdata/observations',
		sortGroupsOrObservations
	)
	const getGroups = useGetData('api/healthdata/groups', sortGroupsOrObservations)
	const getConcepts = useGetData('api/healthdata/concepts', sortConcepts)

	useEffect(() => {
		const fetchData = async () => {
			const observations: ObservationData = await getObservations()
			const groups: GroupData = await getGroups()
			const concepts: ConceptData = await getConcepts()

			setData({ observations, groups, concepts })
		}
		fetchData()
		// eslint-disable-next-line
	}, [])

	return (
		<HealthDataContext.Provider value={{ data, setData }}>
			<PageMenu />
			<Switch>
				<Route path='/healthdata/observations'>
					<Observation
						setRequestResult={setRequestResult}
						sortObservations={sortGroupsOrObservations}
						error={error}
					/>
				</Route>

				<Route path='/healthdata/group'>
					<Group
						setRequestResult={setRequestResult}
						sortGroups={sortGroupsOrObservations}
						error={error}
					/>
				</Route>
				<Route path='/healthdata/concept'>
					<Concept
						setRequestResult={setRequestResult}
						sortConcepts={sortConcepts}
						error={error}
					/>
				</Route>
			</Switch>
		</HealthDataContext.Provider>
	)
}

export type DefaultEntry = {
	id: number
	code: string
	title: string
	interval: number
	category?: string
}
export default HealthData
