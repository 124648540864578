import * as React from 'react'
import { useImmerReducer } from 'use-immer'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { css } from 'styled-components/macro'
import { cloneDeep } from 'lodash'

import { useEditorState, useEditorDispatch } from '../../context/EditorProvider'
import translationsReducer from '../../context/reducers/translationsReducer'

import Container from '../../components/Container'
import Button from '../../components/Button'
import { ButtonVariant } from '../../components/Button'

import NotFound from '../NotFound'

import { CssFunction } from '../../types/styled'
import {
	TranslationsType,
	TranslationActionType,
	TranslationFieldNamesType,
	isBasicQuestionType,
	isSurveyStartType,
	isChoicesQuestionType,
	isSurveySubmitType,
	SurveyQueryParams,
	isTranslationFieldName,
} from '../../types'

const Translations: React.FC = () => {
	const history = useHistory()
	const { search } = useLocation()
	const { surveyDefinition, translations } = useEditorState()
	const dispatch = useEditorDispatch()
	const [translationState, translationDispatch] = useImmerReducer(
		translationsReducer,
		translations
	)

	const { orgToRequest, deptToRequest } = useParams<SurveyQueryParams>()
	const [visibleLanguages, setVisibleLanguages] = React.useState<Array<string>>(
		['en-US', 'nb-NO', 'sv-SE', 'da-DK', 'fi-FI']
	)

	const availableLanguages = {
		'en-US': 'English',
		'nb-NO': 'Norwegian',
		'sv-SE': 'Swedish',
		'da-DK': 'Danish',
		'fi-FI': 'Finnish',
	}

	if (!translations || !surveyDefinition?.questions) return null

	const toggleLanguageHandler = (language: string) => () => {
		const updatedvisibleLanguages = [...visibleLanguages]

		const languageIndex = updatedvisibleLanguages.findIndex(
			(availableLanguage) => availableLanguage === language
		)

		if (languageIndex > -1) updatedvisibleLanguages.splice(languageIndex, 1)
		else updatedvisibleLanguages.push(language)

		setVisibleLanguages(updatedvisibleLanguages)
	}

	const otherTranslations = cloneDeep(translations['en-US'])

	const onCancelClick = () => {
		history.replace({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})

		window.scrollTo(0, 0)
	}

	const onUpdateClick = () => {
		dispatch({
			type: 'saveTranslations',
			payload: translationState,
		})

		window.scrollTo(0, 0)

		history.push({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})
	}

	return (
		<React.Fragment>
			{translationState && (
				<div
					className='cancelUpdateTranslations'
					css={css`
						margin-left: auto;
						margin-right: auto;
						max-width: 1200px;
						padding: 10px 0;
					`}
				>
					<Button type='button' onClick={onCancelClick}>
						Cancel
					</Button>
					<Button
						type='button'
						variant={ButtonVariant.success}
						onClick={onUpdateClick}
					>
						Update
					</Button>
				</div>
			)}
			<Container>
				{translationState ? (
					<React.Fragment>
						<h1>Translations</h1>
						<div>
							<p>Toggle language visibility</p>
							{Object.entries(availableLanguages).map(
								([languageKey, languageValue], index) => {
									if (languageValue === 'English') return null

									return (
										<div
											css={css`
												display: flex;
												align-items: center;

												> input {
													margin-right: 0.5rem;
												}
											`}
											key={index}
										>
											<input
												id={`${languageValue}-toggle`}
												type='checkbox'
												checked={
													!!visibleLanguages.find(
														(language: string) => language === languageKey
													)
												}
												onChange={() => toggleLanguageHandler(languageKey)()}
											/>
											<label htmlFor={`${languageValue}-toggle`}>
												{languageValue}
											</label>
										</div>
									)
								}
							)}
						</div>
						<div
							css={css`
								grid-column: 1/4;
								position: sticky;
								top: 4.5rem;
								background-color: white;
							`}
						>
							<TranslationGrid numberOfColumns={visibleLanguages.length}>
								{visibleLanguages.map((languageKey) => {
									if (!isTranslationFieldName(languageKey)) return null

									return (
										<h4
											key={'languageHeader_' + languageKey}
											css={css`
												text-align: center;
											`}
										>
											{availableLanguages[languageKey]} ({languageKey})
										</h4>
									)
								})}
							</TranslationGrid>
						</div>
						<TranslationGrid numberOfColumns={visibleLanguages.length}>
							{surveyDefinition.questions.map((question, index) => {
								if (isSurveyStartType(question)) {
									const textKeys: {
										[key: string]: string
									} = {
										header: question.headerTextKey,
										ingress: question.ingressTextKey,
										[`answered_header`]: question.answeredHeaderTextKey,
										[`answered_ingress`]: question.answeredIngressTextKey,
									}

									if (question?.nicknameTextKey) {
										textKeys.nickname = question.nicknameTextKey
									}

									Object.values(textKeys).forEach(
										(textKey) => textKey && delete otherTranslations[textKey]
									)

									return QuestionTranslationsRenderer(
										index,
										textKeys,
										translationState,
										visibleLanguages,
										translationDispatch
									)
								} else if (isBasicQuestionType(question)) {
									const textKeys: {
										[key: string]: string
									} = {
										header: question.headerTextKey,
										ingress: question.ingressTextKey,
									}

									if (question?.nicknameTextKey) {
										textKeys.nickname = question.nicknameTextKey
									}

									Object.values(textKeys).forEach(
										(textKey) => textKey && delete otherTranslations[textKey]
									)

									return QuestionTranslationsRenderer(
										index,
										textKeys,
										translationState,
										visibleLanguages,
										translationDispatch
									)
								} else if (isChoicesQuestionType(question)) {
									let textKeys: {
										[key: string]: string
									} = {
										header: question.headerTextKey,
										ingress: question.ingressTextKey,
									}

									if (question?.nicknameTextKey) {
										textKeys.nickname = question.nicknameTextKey
									}

									if (question.choices) {
										question.choices.forEach((choice, choiceIndex) => {
											const choiceNumber = choiceIndex + 1

											textKeys = {
												...textKeys,
												[`choice_${choiceNumber}_header`]: choice.headerTextKey,
												[`choice_${choiceNumber}_ingress`]:
													choice.ingressTextKey,
											}

											if (choice?.nicknameTextKey) {
												textKeys[`choice_${choiceNumber}_nickname`] =
													choice.nicknameTextKey
											}

											if (choice?.choices?.length) {
												textKeys[`choice_${choiceNumber}_subquestion_header`] =
													choice?.subQuestionHeaderTextKey
														? choice.subQuestionHeaderTextKey
														: ''
												textKeys[
													`choice_${choiceIndex + 1}_subquestion_ingress`
												] = choice?.subQuestionIngressTextKey
													? choice.subQuestionIngressTextKey
													: ''
											}

											if (choice?.subQuestionNicknameTextKey) {
												textKeys[
													`choice_${choiceIndex + 1}_subquestion_nickname`
												] = choice.subQuestionNicknameTextKey
											}

											if (choice.choices) {
												choice.choices.forEach((subChoice, subChoiceIndex) => {
													const subChoiceNumber = subChoiceIndex + 1

													textKeys = {
														...textKeys,
														[`subchoice_${subChoiceNumber}_header`]:
															subChoice.headerTextKey,
														[`subchoice_${subChoiceNumber}_ingress`]:
															subChoice.ingressTextKey,
													}

													if (subChoice?.nicknameTextKey) {
														textKeys[`subchoice_${subChoiceNumber}_nickname`] =
															subChoice.nicknameTextKey
													}
												})
											}
										})
									}

									Object.values(textKeys).forEach((textKey) => {
										textKey && delete otherTranslations[textKey]
									})

									return QuestionTranslationsRenderer(
										index,
										textKeys,
										translationState,
										visibleLanguages,
										translationDispatch
									)
								} else if (isSurveySubmitType(question)) {
									const textKeys: {
										[key: string]: string
									} = {
										header: question.headerTextKey,
										ingress: question.ingressTextKey,
									}

									if (question?.nicknameTextKey) {
										textKeys.nickname = question.nicknameTextKey
									}

									Object.values(textKeys).forEach(
										(textKey) => textKey && delete otherTranslations[textKey]
									)

									return QuestionTranslationsRenderer(
										index,
										textKeys,
										translationState,
										visibleLanguages,
										translationDispatch
									)
								}

								return null
							})}
							<React.Fragment>
								<h4
									css={css`
										grid-column: 1 / -1;
									`}
								>
									Other
								</h4>
								{Object.keys(otherTranslations).map((translationKey, index) => {
									return (
										<React.Fragment key={index}>
											{visibleLanguages.map((languageKey) => {
												if (!isTranslationFieldName(languageKey)) return null

												return (
													<TranslationTextArea
														key={languageKey + translationKey}
														value={
															translationState[languageKey]?.[translationKey]
																?.text || ''
														}
														translationKey={translationKey}
														translateTo={languageKey}
														translationDispatch={translationDispatch}
													/>
												)
											})}
										</React.Fragment>
									)
								})}
							</React.Fragment>
						</TranslationGrid>
					</React.Fragment>
				) : (
					<NotFound linkHome />
				)}
			</Container>
		</React.Fragment>
	)
}

const QuestionTranslationsRenderer = (
	questionIndex: number,
	textKeys: { [key: string]: string },
	translationState: TranslationsType,
	visibleLanguages: Array<string>,
	translationDispatch: React.Dispatch<TranslationActionType>
) => {
	return (
		<React.Fragment key={questionIndex}>
			<h4
				css={css`
					grid-column: 1 / -1;
				`}
			>
				Question {questionIndex}
			</h4>
			{Object.entries(textKeys).map(([name, textKey], index) => (
				<React.Fragment key={`${index}${textKey}`}>
					<span
						css={css`
							grid-column: 1 / -1;
						`}
					>
						{name.split('_').join(' ')}
					</span>
					{visibleLanguages.map((languageKey) => {
						if (!isTranslationFieldName(languageKey)) return null

						if (!languageKey || !textKey) {
							if (languageKey === 'en-US') {
								return (
									<div
										css={css`
											grid-column: 1 / -1;
											background-color: #ffd8d8;
											padding: 15px;
											font-weight: bold;
										`}
										key={languageKey + textKey}
									>
										Missing translation from server
									</div>
								)
							} else {
								return null
							}
						}

						return (
							<TranslationTextArea
								key={languageKey + textKey}
								value={translationState[languageKey]?.[textKey]?.text || ''}
								translateTo={languageKey}
								translationKey={textKey}
								translationDispatch={translationDispatch}
							/>
						)
					})}
				</React.Fragment>
			))}
		</React.Fragment>
	)
}

const TranslationTextArea = React.memo(
	({
		value,
		translationKey,
		translateTo,
		translationDispatch,
	}: TranslationTextAreaProps) => {
		return (
			<textarea
				css={css`
					border: 1px solid #eee;
					padding: 0.5rem;

					background-color: ${value ? '#D2FFD2' : '#FFD8D8'};
				`}
				value={value}
				onChange={(event) => {
					translationDispatch({
						type: 'updateTranslation',
						payload: {
							key: translationKey,
							translateTo: translateTo,
							text: event.currentTarget.value,
						},
					})
				}}
			/>
		)
	}
)

const TranslationGrid: React.FC<{ numberOfColumns: number }> = ({
	numberOfColumns,
	children,
}) => {
	const interpolateGridColumns = (numberOfColumns: number): CssFunction => {
		switch (numberOfColumns) {
			case 1:
				return css`
					grid-template-columns: 0.33fr;
				`
			case 2:
				return css`
					grid-template-columns: 0.33fr 0.33fr;
				`
			case 3:
				return css`
					grid-template-columns: 1fr 1fr 1fr;
				`
			case 4:
				return css`
					grid-template-columns: 1fr 1fr 1fr 1fr;
				`
			default:
				return css`
					grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
				`
		}
	}
	return (
		<div
			css={[
				css`
					display: grid;
					grid-column-gap: 0.5rem;
					grid-row-gap: 0.5rem;
				`,
				interpolateGridColumns(numberOfColumns),
			]}
		>
			{children}
		</div>
	)
}

export default Translations

type TranslationTextAreaProps = {
	value: string
	translationKey: string
	translateTo: TranslationFieldNamesType
	translationDispatch: React.Dispatch<TranslationActionType>
}
