import * as React from 'react'
import { css } from 'styled-components/macro'
import { CssFunction } from '../types/styled'

type ReactInput = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>

type TextInputProps = {
	id: string
	inputCss?: CssFunction
	containerCss?: CssFunction
	label?: string
	children?: never
} & ReactInput

const TextInput: React.FC<TextInputProps> = ({
	id,
	inputCss,
	containerCss,
	label,
	...props
}) => (
	<div
		css={[
			css`
				display: flex;
				flex-direction: column;
				margin-top: 2rem;
			`,
			containerCss,
		]}
	>
		{label && (
			<label
				css={css`
					margin: 0 0 0.25rem;
					font-size: 0.9rem;
				`}
				htmlFor={id}
			>
				{label}
			</label>
		)}

		<input
			type='text'
			id={id}
			css={[
				css`
					border: 0;
					border-bottom: 2px solid #d3d3d3;
					padding: 10px;
				`,
				inputCss,
			]}
			{...props}
		/>
	</div>
)

export default TextInput
