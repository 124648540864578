import * as React from 'react'
import { css } from 'styled-components/macro'
import { useQueryParam, StringParam } from 'use-query-params'

import { NEW_ID } from '../../constants'

import { HandlerProvider, useHandlerState } from '../../context/HandlerProvider'

import useSurveyFetcher from '../../hooks/useSurveyFetcher'

import ProgressTracker from '../../components/ProgressTracker'

import Navigation from '../../components/Navigation'
import Media from '../../components/Media'
import SliderWithText from '../../components/SliderWithText'
import BasicInfo from '../../components/BasicInfo'
import ChoiceGroup from '../../components/ChoiceGroup'
import Spinner from '../../components/Spinner'

import SurveyStart from './SurveyStart'
import SurveySubmit from './SurveySubmit'

import {
	isTranslationFieldName,
	TranslationFieldNamesType,
	isSurveyStartType,
	isBasicQuestionType,
	isChoicesQuestionType,
	QuestionAnswerType,
	isChoiceGroupAnswerType,
	isSliderAnswerType,
	isBasicAnswerType,
	isSurveySubmitType,
	SurveyDefinitionQuestionType,
} from '../../types'
import Footer from '../../components/Footer'

const SurveyHandler: React.FC = () => {
	const [language] = useQueryParam('culture', StringParam)
	const { surveyData, loading, error } = useSurveyFetcher()

	React.useEffect(() => {
		if (isTranslationFieldName(language)) {
			document.documentElement.lang = language
		} else {
			document.documentElement.lang = 'en-US'
		}
	}, [language])

	if (loading)
		return (
			<div css={containerStyle}>
				<Spinner />
			</div>
		)

	if (error || !surveyData) {
		return (
			<div css={containerStyle}>
				<p>Error while loading the survey</p>
			</div>
		)
	}

	let currentLanguageTranslations = language
		? surveyData.translations?.[language as TranslationFieldNamesType]
		: undefined

	const { surveyDefinition, translations } = surveyData
	if (
		currentLanguageTranslations &&
		Object.keys(currentLanguageTranslations).length === 0
	) {
		currentLanguageTranslations = translations?.['en-US']
	}

	if (!currentLanguageTranslations || !surveyDefinition) {
		return (
			<div>
				<p>Survey incomplete. Contact your trainer for more information</p>
			</div>
		)
	}

	const questions = surveyDefinition.questions
	const initialPotentialAnswers = surveyDefinition?.questions.reduce(
		(validPotentialAnswers: Array<QuestionAnswerType>, question) => {
			if (question.choicesVariant !== 'radio') {
				if (isBasicQuestionType(question)) {
					const oldAnswer = surveyData.userAnswers?.questionAnswers?.find(
						(questionAnswer) => questionAnswer.questionId === question.id
					)

					if (oldAnswer && oldAnswer.type === question.choicesVariant) {
						validPotentialAnswers.push(oldAnswer)
					} else {
						validPotentialAnswers.push({
							id: NEW_ID,
							questionId: question.id,
							type: question.choicesVariant,
							answer: {
								birthday: '',
								gender: '',
								height: NaN,
								weight: NaN,
							},
							tags: '',
						})
					}
				} else if (
					question.choicesVariant === 'slider' &&
					isChoicesQuestionType(question)
				) {
					validPotentialAnswers.push({
						id: NEW_ID,
						questionId: question.id,
						type: question.choicesVariant,
						answer: {
							sliderIndex: Math.floor((question.choices.length - 1) / 2),
						},
						tags: '',
						properties: { recommendation: [], screening: [] },
					})
				} else if (isChoicesQuestionType(question)) {
					validPotentialAnswers.push({
						id: NEW_ID,
						questionId: question.id,
						type: question.choicesVariant,
						answer: question.choices.map((choice) => ({
							id: choice.id,
							selected: false,
							answers: choice.choices?.map((subChoice) => {
								return {
									id: subChoice.id,
									selected: false,
								}
							}),
						})),
						tags: '',
						properties: {
							recommendation: [],
							screening: [],
						},
					})
				}
			}

			return validPotentialAnswers
		},
		[]
	)

	function getFirstPage(
		questions: SurveyDefinitionQuestionType[],
		previouslyAnswered: boolean
	) {
		const surveyStart = questions[0]

		if (
			isSurveyStartType(surveyStart) &&
			surveyStart.hideWhenNotAnswered &&
			!previouslyAnswered
		) {
			return questions[1].id
		} else {
			return surveyStart.id
		}
	}

	const answerData = {
		surveyDefinition,
		potentialAnswers: initialPotentialAnswers || [],
		confirmedAnswers: [],
		slug: getFirstPage(questions, !!surveyData.userAnswers?.timeStamp),
	}

	return (
		<HandlerProvider answerData={answerData}>
			<SurveyPage
				translations={currentLanguageTranslations}
				previouslyAnswered={surveyData.userAnswers?.timeStamp}
			/>
		</HandlerProvider>
	)
}

const SurveyPage: React.FC<SurveyPageProps> = ({
	translations,
	previouslyAnswered,
}) => {
	const { surveyDefinition, potentialAnswers, slug } = useHandlerState()

	const questions = surveyDefinition.questions
	const currentQuestionIndex = questions.findIndex(
		(question) => question.id === slug
	)

	const currentQuestion = questions[currentQuestionIndex]

	if (!questions) {
		return (
			<p>
				Your survey is not ready yet. Contact your instructor for more
				information.
			</p>
		)
	}

	if (!currentQuestion) return null

	if (isSurveyStartType(currentQuestion)) {
		return (
			<div css={surveyContainerStyle}>
				<SurveyStart
					translations={translations}
					currentQuestion={currentQuestion}
					previouslyAnswered={previouslyAnswered}
				/>
			</div>
		)
	}

	if (isSurveySubmitType(currentQuestion)) {
		return (
			<div css={surveyContainerStyle}>
				<SurveySubmit
					translations={translations}
					currentQuestion={currentQuestion}
					surveyDefinition={surveyDefinition}
				/>
			</div>
		)
	}

	const potentialAnswer = potentialAnswers.find(
		(potentialAnswer) => potentialAnswer.questionId === currentQuestion?.id
	)

	const answers = potentialAnswer?.answer

	return (
		<div css={surveyContainerStyle}>
			<main>
				<ProgressTracker
					numberOfQuestions={
						questions.filter(
							(question) =>
								question.choicesVariant !== 'SurveyStart' &&
								question.choicesVariant !== 'SurveySubmit'
						).length
					}
					currentQuestionIndex={currentQuestionIndex + 1}
				/>
				<fieldset
					css={css`
						border: none;
						padding: 0;
						margin: 1rem 0;
						padding-bottom: calc(1rem + 89px);

						legend {
							display: block;
							font-weight: 700;
							font-size: 1.25rem;
							line-height: 1.75rem;
						}

						@media (min-width: 768px) {
							flex-grow: 0;
						}
					`}
				>
					<legend>{translations[currentQuestion.headerTextKey]}</legend>
					{translations[currentQuestion.ingressTextKey] ? (
						<p
							css={css`
								white-space: pre-line;
							`}
						>
							{translations[currentQuestion.ingressTextKey]}
						</p>
					) : null}
					{currentQuestion && isChoicesQuestionType(currentQuestion) ? (
						<Media
							imageId={currentQuestion.imageId}
							videoId={currentQuestion.videoId}
						/>
					) : null}
					{isChoicesQuestionType(currentQuestion) &&
					answers &&
					isSliderAnswerType(answers) ? (
						<SliderWithText
							translations={translations}
							sliderItems={currentQuestion.choices}
							potentialAnswerIndex={answers.sliderIndex}
						/>
					) : null}
					{currentQuestion &&
					isChoicesQuestionType(currentQuestion) &&
					((Array.isArray(answers) && isChoiceGroupAnswerType(answers[0])) ||
						typeof answers === 'undefined') ? (
						<ChoiceGroup
							translations={translations}
							choices={currentQuestion.choices}
							choicesVariant={currentQuestion.choicesVariant}
							answers={answers}
							alignment={currentQuestion.alignment}
						/>
					) : null}
					{isBasicQuestionType(currentQuestion) &&
					answers &&
					isBasicAnswerType(answers) ? (
						<BasicInfo
							currentQuestion={currentQuestion}
							translations={translations}
							potentialAnswer={answers}
						/>
					) : null}
				</fieldset>
			</main>
			<Footer>
				<Navigation translations={translations} />
			</Footer>
		</div>
	)
}

export default SurveyHandler

type SurveyPageProps = {
	translations: {
		[translationKey: string]: string
	}
	previouslyAnswered: number | undefined
}

/* Start of styling */

const surveyContainerStyle = css`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	margin: 0 auto;
	max-width: 768px;

	/* 100vh fallback as not every browser supports -webkit-fill-available */
	min-height: calc(100vh - 89px);

	/* Fixes issue with 100vh on iphone hiding the bottom content */
	min-height: calc(-webkit-fill-available - 89px);

	@supports (padding: max(0px)) {
		padding-left: max(1rem, env(safe-area-inset-left));
		padding-right: max(1rem, env(safe-area-inset-right));
	}
`

const containerStyle = css`
	margin-left: auto;
	margin-right: auto;
	max-width: 768px;
`

/* End of styling */
