import { ScreeningPropertyType, RecommendationPropertyType } from '../types'

export const getFormattedChoiceProperties = (properties: string) => {
	let choiceProperties: {
		screening: ScreeningPropertyType[]
		recommendation: RecommendationPropertyType[]
	} = { screening: [], recommendation: [] }

	try {
		const formattedProperties = JSON.parse(properties)

		if (Array.isArray(formattedProperties)) {
			choiceProperties = {
				...choiceProperties,
				recommendation: formattedProperties,
			}
		} else if (
			'screening' in formattedProperties &&
			'recommendation' in formattedProperties
		) {
			choiceProperties = {
				...choiceProperties,
				...formattedProperties,
			}
		}
	} catch (error) {}

	return choiceProperties
}
