import * as React from 'react'
import { css } from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

const Tab: React.FC<Tabs> = ({ tabs }) => {
	const history = useHistory()
	const [activeTab, setTab] = React.useState(
		history.location.pathname.slice(1).includes('editor') ? 'SE_I0' : 'HD_I1'
	)
	const initialPaths = tabs.reduce(
		(acc, tab) => ({ ...acc, [tab.tabId]: `/${tab.route}` }),
		{}
	)
	const pathRef = React.useRef<Record<string, string>>(initialPaths)

	const setActiveTab = (tab: TabProps) => {
		setTab(tab.tabId)
		if (
			tab.tabId === 'SE_I0' &&
			!history.location.pathname.includes('editor') &&
			!pathRef.current[tab.tabId].includes('editor')
		) {
			history.push(`/editor`)
		} else history.push(pathRef.current[tab.tabId] || `/${tab.route}`)
	}

	const buttonResetStyles = css`
		appearance: none;
		background: transparent;
		border: 0;
		margin: 0;
		padding: 0;
		cursor: pointer;
	`

	return (
		<div className='tabs'>
			{tabs.map((tab) => (
				<button
					tabIndex={0}
					key={tab.tabId}
					css={[
						buttonResetStyles,
						css`
							color: #ffff;

							display: inline-block;
							margin: 0 0.2rem;
							padding: 10px;
							border: 1px solid
								${activeTab === tab.tabId ? '#696969' : '#34373b'};
							border-radius: 5px;
							background-color: ${activeTab === tab.tabId ? '#696969' : ''};
							transition:
								border 0.3s ease,
								background-color 0.3s ease;
						`,
					]}
					onClick={() => {
						setActiveTab(tab)
					}}
				>
					{tab.tabName}
				</button>
			))}
		</div>
	)
}

export default Tab

type Tabs = {
	tabs: TabProps[]
}

export type TabProps = {
	tabId: string
	tabName: string
	route: string
}
