import { ChoiceGroupAnswerType } from '../types'

export const getAnswerDefinition = (
	id: string,
	answers: ChoiceGroupAnswerType[]
):
	| { answer: ChoiceGroupAnswerType; answers: ChoiceGroupAnswerType[] }
	| undefined => {
	for (const answer of answers) {
		if (answer.id === id) return { answer, answers }
		if (answer.answers) {
			const { answer: answerDefinition } = {
				...getAnswerDefinition(id, answer.answers),
			}

			if (answerDefinition) {
				return { answer: answerDefinition, answers: answer.answers }
			}
		}
	}

	return undefined
}
