import { uniqueId } from 'lodash'

import {
	TagRuleType,
	TagRulesActionType,
	isUpdateIndexPayload,
	isAddTagToInsertPayload,
	isDeleteTagPayload,
	isAddTagFilterPayload,
} from '../../types'

export const tagRulesReducer = (
	draftState: TagRuleType[] | undefined,
	action: TagRulesActionType
) => {
	if (!draftState) return
	else if (action.type === 'addNewRule') {
		draftState.push({
			id: uniqueId('tagRule_'),
			index: draftState.length,
			tagFilters: [],
			tagsToInsert: [],
		})
	} else if (action.payload) {
		if (action.type === 'deleteRule') {
			const { tagRuleIndex } = action.payload

			draftState.splice(tagRuleIndex, 1)
		} else if (action.type === 'updateIndex') {
			if (!isUpdateIndexPayload(action.payload)) return

			const { tagRuleIndex, newIndex } = action.payload

			const tagRuleToMove = draftState.splice(tagRuleIndex, 1)[0]
			draftState.splice(newIndex, 0, tagRuleToMove)
			draftState.forEach(
				(tagRule, tagRuleIndex) => (tagRule.index = tagRuleIndex)
			)
		} else if (action.type === 'addTagToInsert') {
			if (!isAddTagToInsertPayload(action.payload)) return

			const { tagRuleIndex, newTagToInsert } = action.payload

			draftState[tagRuleIndex].tagsToInsert.push(newTagToInsert)
		} else if (action.type === 'deleteTagToInsert') {
			if (!isDeleteTagPayload(action.payload)) return

			const { tagRuleIndex, tagIndex } = action.payload

			draftState[tagRuleIndex].tagsToInsert.splice(tagIndex, 1)
		} else if (action.type === 'addTagFilter') {
			if (!isAddTagFilterPayload(action.payload)) return

			const { tagRuleIndex, newFilterTag } = action.payload

			draftState[tagRuleIndex].tagFilters.push(newFilterTag)
		} else if (action.type === 'deleteTagFilter') {
			if (!isDeleteTagPayload(action.payload)) return

			const { tagRuleIndex, tagIndex } = action.payload

			draftState[tagRuleIndex].tagFilters.splice(tagIndex, 1)
		}
	}
}

export default tagRulesReducer
