import { HealthData } from '../../components/HealthData/HealthDataTable'

const getAvailableCode = (
	observations: HealthData[],
	input: string
): string => {
	if (input) {
		let codeIndex = 1
		let code = `${input}${codeIndex}`
		observations.forEach((observation) => {
			Object.entries(observation).forEach(([key, value]) => {
				if (key === 'code') {
					if (
						typeof value === 'string' &&
						value.startsWith(input.toLowerCase())
					) {
						code = `${input}${++codeIndex}`
					}
				}
			})
		})

		//TODO: Right now built for just increments. Potential break on edited codes that are higher in the order.
		// i.e tst1, tst2, tst4     =>   tst4 will fail because it already exists and locks the input.

		if (codeIndex === 0) code = `${input}${++codeIndex}`

		return code
	}
	return ''
}

export default getAvailableCode
