import * as React from 'react'
import { css } from 'styled-components/macro'

const Footer: React.FC = ({ children }) => (
	<div
		css={css`
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: white;
			padding: 1rem 1rem calc(1rem + env(safe-area-inset-bottom, 1rem));
			z-index: 100;

			button {
				flex-grow: 1;
			}

			@media (min-width: 768px) {
				background-color: transparent;
				position: static;
				padding: 1rem 0 calc(1rem + env(safe-area-inset-bottom, 1rem));

				button {
					flex-grow: 0;
				}
			}
		`}
	>
		{children}
	</div>
)

export default Footer
