import ageGroupOptions from './ageGroupOptions'
import equipmentOptions from './equipmentOptions'
import focusOptions from './focusOptions'
import startPositionOptions from './startPositionOptions'
import levelOptions from './levelOptions'
import formOptions from './formOptions'

const NEW_ID = '00000000-0000-0000-0000-000000000000'

export {
	NEW_ID,
	ageGroupOptions,
	equipmentOptions,
	focusOptions,
	startPositionOptions,
	levelOptions,
	formOptions,
}
