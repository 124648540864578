import * as React from 'react'
import { css } from 'styled-components/macro'

const Container: React.FC = ({ children }) => (
	<div
		css={css`
			margin-left: auto;
			margin-right: auto;
			margin-top: 1rem;
			max-width: 1200px;
			padding: 0 2rem;
			margin-bottom: 100px;
		`}
	>
		{children}
	</div>
)

export default Container
