import { NewTranslation, TranslationsType, isTranslationType } from '../types'

export const getNewTranslations = (translations: TranslationsType) => {
	const translatedLanguages = Object.entries(translations)
	const newTranslations: Array<NewTranslation | undefined> = []

	translatedLanguages.forEach(([language, translations]) => {
		if (Object.keys(translations).length > 0) {
			Object.values(translations).forEach((value) => {
				if (isTranslationType(value) && value.changed) {
					newTranslations.push({
						$type: 'Translation',
						Culture: language,
						Key: value.key,
						Text: value.text,
					})
				}
			})
		}
	})

	return newTranslations
}
