import { SurveyDefinitionFromServer } from '../types'
import { formatQuestionForLocalState } from './formatSurvey'

export const transformSurveyData = (rawSurveyData: string[]) => {
	const parsedData = rawSurveyData.map((dataEntry: string) =>
		JSON.parse(dataEntry)
	)

	const unPrunedSurveyDefinition: SurveyDefinitionFromServer = parsedData[0]
	const surveyTranslations = parsedData[1]

	const prunedData = {
		surveyDefinition: {
			id: unPrunedSurveyDefinition?.ExternalId,
			createdBy: unPrunedSurveyDefinition?.CreatedBy,
			name: unPrunedSurveyDefinition?.Name,
			sourceOrgId: unPrunedSurveyDefinition?.SourceOrgId,
			sourceDeptId: unPrunedSurveyDefinition?.SourceDeptId,
			conceptId: unPrunedSurveyDefinition?.ConceptId,
			postProcessingRules: unPrunedSurveyDefinition?.PostProcessingRules
				? JSON.parse(unPrunedSurveyDefinition?.PostProcessingRules)
				: { tagRules: [] },
			questions: unPrunedSurveyDefinition?.SurveyPages.map((surveyPage) => {
				return formatQuestionForLocalState(surveyPage)
			})
				.filter((question) => !!question?.choicesVariant)
				.sort((questionA, questionB) => {
					if (!questionA || !questionB) return NaN
					return questionA?.order > questionB?.order ? 1 : -1
				})
				.map((question, questionIndex: number) => {
					if (question) question.order = questionIndex

					return question
				}),
		},
		translations: Object.fromEntries(
			Object.entries(surveyTranslations).map(([key, value]) => {
				if (typeof value === 'object' && value) {
					if (Object.keys(value).length === 0) return [key, {}]

					const prunedTranslations = Object.fromEntries(
						Object.entries(value).map(([key, value]) => {
							return [
								key,
								{
									changed: false,
									id: value.ExternalId,
									key: key,
									text: value.Text,
									timeStamp: value.TimeStamp,
								},
							]
						})
					)

					return [key, prunedTranslations]
				}

				return [key, {}]
			})
		),
	}
	return prunedData
}
