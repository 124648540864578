import * as React from 'react'
import { css } from 'styled-components/macro'

const ProgressTracker: React.FC<ProgressTrackerProps> = ({
	numberOfQuestions,
	currentQuestionIndex,
}) => {
	const computedLength = (currentQuestionIndex / numberOfQuestions) * 100

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				align-items: center;
			`}
		>
			<progress
				css={css`
					appearance: none;
					border: 0;
					border-radius: 4px;
					background-color: #c0c0c0;
					width: 80%;
					height: 6px;

					&::-webkit-progress-bar {
						border-radius: 4px;
						background-color: #eee;
					}

					&::-webkit-progress-value {
						border-radius: 4px;
						background-color: ${({ theme }) => theme.secondaryColor};
					}

					&::-moz-progress-bar {
						border-radius: 4px;
						background-color: ${({ theme }) => theme.secondaryColor};
					}

					&::-ms-fill {
						border-radius: 4px;
						background-color: ${({ theme }) => theme.secondaryColor};
					}
				`}
				max={100}
				value={computedLength}
			>
				Testing
			</progress>
		</div>
	)
}

export default ProgressTracker

type ProgressTrackerProps = {
	currentQuestionIndex: number
	numberOfQuestions: number
}
