import * as React from 'react'
import { css } from 'styled-components/macro'
import * as _ from 'lodash'

import { useHandlerDispatch } from '../context/HandlerProvider'

import Media from '../components/Media'
import SliderWithText from '../components/SliderWithText'

import { ReactComponent as Circle } from '../images/circle.svg'
import { ReactComponent as CheckMark } from '../images/checkmark.svg'

import { ChoiceType, ChoiceGroupAnswerType } from '../types'
import { StyleVariants } from '../types/styled'

export enum ChoiceAlignment {
	horizontal = 'horizontal',
	horizontalSmall = 'horizontalSmall',
}

const ChoiceGroup: React.FC<ChoiceGroupProps> = ({
	translations,
	choicesVariant,
	choices,
	answers,
	alignment,
	parentChoiceIndex,
}) => {
	const handlerDispatch = useHandlerDispatch()

	const onCurrentChoiceUpdate = (
		choiceId: string,
		isChoiceChecked: boolean
	) => {
		handlerDispatch({
			type: 'updatePotentialAnswers',
			payload: { choiceId, isChoiceChecked, parentChoiceIndex, choicesVariant },
		})
	}

	const choiceGroupId = _.uniqueId('choiceGroup_')

	const isHorizontal = alignment === 'horizontal'
	const isHorizontalSmall =
		alignment === 'horizontal' && choicesVariant === 'checkbox'

	return (
		<div
			css={[
				css`
					padding: 1rem 0;
					display: flex;
					flex-direction: column;
					gap: 0.75rem;
				`,
				isHorizontal &&
					css`
						flex-direction: row;
						gap: 0.5rem;
					`,
				isHorizontalSmall &&
					css`
						border: 1px solid #c0c0c0;
						border-radius: 7px;
						gap: 0;
						padding-inline: 1rem;
					`,
			]}
		>
			{choices.map((choice, choiceIndex: number) => {
				const isChecked = !!answers?.find((answer) => answer.id === choice.id)
					?.selected

				return (
					<div
						key={choiceIndex}
						css={[
							choiceContainerStyle,
							isChecked &&
								css`
									&:after {
										content: '';
										position: absolute;
										left: -2px;
										right: -2px;
										top: -2px;
										bottom: -2px;
										border: 2px solid ${({ theme }) => theme.secondaryColor};
										border-radius: 7px;
										z-index: -100;
									}
								`,
							isHorizontal && variantStyles.horizontal,
							isHorizontalSmall && variantStyles.horizontalSmall,
							isChecked &&
								isHorizontalSmall &&
								css`
									outline: 2px solid ${({ theme }) => theme.textColorForPrimary};
									z-index: 1000;
								`,
						]}
						className={isHorizontalSmall ? 'horizontal-small' : ''}
					>
						<Choice
							choiceIndex={choiceIndex}
							choiceGroupId={choiceGroupId}
							variant={choicesVariant}
							translations={translations}
							answers={answers}
							choice={choice}
							checked={isChecked}
							alignment={alignment}
							onChoiceUpdateHandler={(id: string, checked: boolean) =>
								onCurrentChoiceUpdate(id, checked)
							}
						/>
					</div>
				)
			})}
		</div>
	)
}

const Choice: React.FC<ChoiceProps> = ({
	choiceIndex,
	choiceGroupId,
	variant,
	translations,
	choice,
	checked,
	answers,
	alignment,
	onChoiceUpdateHandler,
}) => {
	const header = translations[choice.headerTextKey]
	const ingress = translations[choice.ingressTextKey]

	const hasSubChoices = !!(
		choice.choices?.length &&
		choice.choicesVariant &&
		checked &&
		alignment !== 'horizontal'
	)

	const isHorizontalSmall = !!(
		alignment === 'horizontal' && variant === 'checkbox'
	)

	return (
		<React.Fragment>
			<div
				css={css`
					position: relative;
				`}
			>
				<input
					css={choiceInputStyle}
					type={variant}
					id={choice.id}
					name={choiceGroupId}
					value={header}
					checked={checked}
					onChange={(event) => {
						onChoiceUpdateHandler(
							event.currentTarget.id,
							event.currentTarget.checked
						)
					}}
				/>
				<label
					className='input-label'
					css={[
						css`
							position: relative;
							display: flex;
							align-items: center;
							padding: 1rem 0;
							flex-grow: 1;
						`,
						isHorizontalSmall &&
							css`
								padding: 0.5rem;
							`,
					]}
					htmlFor={choice.id}
				>
					{variant === 'checkbox' ? <CheckMark /> : <Circle />}
					{alignment === 'horizontal' && choice.imageId ? (
						<div
							css={css`
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								margin-right: 1rem;
								border: 1px solid #c0c0c0;
								border-radius: 7px;
								gap: 1rem;

								img {
									border-radius: 7px;
								}
							`}
						>
							<div
								css={css`
									width: 100px;
								`}
							>
								<Media
									imageId={choice.imageId}
									containerCss={css`
										padding: 0;
									`}
								/>
							</div>
						</div>
					) : null}
					<span
						css={css`
							display: flex;
							flex-direction: column;
							width: 100%;
							text-align: ${alignment === 'horizontal' ? 'center' : 'unset'};
						`}
					>
						<span css={[headerStyle]}>{header}</span>
						<span css={[ingressStyle]}>{ingress}</span>
					</span>
				</label>
			</div>
			{hasSubChoices ? (
				<SubQuestion
					choice={choice}
					translations={translations}
					answers={answers}
					parentChoiceIndex={choiceIndex}
					alignment={alignment}
				/>
			) : null}
		</React.Fragment>
	)
}

const SubQuestion: React.FC<SubQuestionProps> = ({
	choice,
	translations,
	answers,
	parentChoiceIndex,
	alignment,
}) => {
	const handlerDispatch = useHandlerDispatch()

	const onSliderUpdate = (choiceIndex: number) => {
		if (!answers) return null

		const subAnswers = answers?.findIndex((answer) => answer.selected)
		const newAnswer = answers[subAnswers].answers?.[choiceIndex]

		if (!newAnswer) return

		handlerDispatch({
			type: 'updatePotentialAnswers',
			payload: {
				choiceId: newAnswer.id,
				isChoiceChecked: true,
				parentChoiceIndex: subAnswers,
				choicesVariant: 'radio',
			},
		})
	}

	const subAnswers = answers?.[parentChoiceIndex]
	const sliderIndex = subAnswers?.answers?.findIndex(
		(subAnswer) => subAnswer.selected
	)

	if (!choice.choices || !choice.choicesVariant) return null

	return (
		<div
			css={css`
				display: 'flex'
				flex-direction: column;
				gap: 1rem;
				margin-inline: 1rem;
			`}
		>
			{choice.subQuestionHeaderTextKey || choice.subQuestionIngressTextKey ? (
				<div
					css={css`
						display: flex;
						flex-direction: column;
						margin: 0.5rem 0 2rem;
						padding-top: 1rem;
						border-top: 2px solid #c0c0c0;
					`}
				>
					{choice.subQuestionHeaderTextKey ? (
						<span css={headerStyle}>
							{translations[choice.subQuestionHeaderTextKey]}
						</span>
					) : null}
					{choice.subQuestionIngressTextKey ? (
						<span css={ingressStyle}>
							{translations[choice.subQuestionIngressTextKey]}
						</span>
					) : null}
				</div>
			) : null}
			{choice.choicesVariant === 'slider' ? (
				<SliderWithText
					translations={translations}
					sliderItems={choice.choices}
					potentialAnswerIndex={sliderIndex || 0}
					customSliderUpdate={onSliderUpdate}
				/>
			) : (
				<ChoiceGroup
					translations={translations}
					choicesVariant={choice.choicesVariant}
					choices={choice.choices}
					answers={subAnswers?.answers}
					parentChoiceIndex={parentChoiceIndex}
					alignment={alignment}
				/>
			)}
		</div>
	)
}

export default ChoiceGroup

type ChoiceProps = {
	choiceGroupId: string
	variant: string
	translations: {
		[translationKey: string]: string
	}
	choice: ChoiceType
	choiceIndex: number
	checked: boolean
	answers?: ChoiceGroupAnswerType[]
	alignment?: 'vertical' | 'horizontal'
	onChoiceUpdateHandler: (id: string, checked: boolean) => void
}

type ChoiceGroupProps = {
	translations: {
		[translationKey: string]: string
	}
	choicesVariant: string
	choices: ChoiceType[]
	answers?: ChoiceGroupAnswerType[]
	parentChoiceIndex?: number
	alignment?: 'vertical' | 'horizontal'
}

type SubQuestionProps = {
	parentChoiceIndex: number
	choice: ChoiceType
	translations: {
		[translationKey: string]: string
	}
	answers?: ChoiceGroupAnswerType[]
	alignment?: 'vertical' | 'horizontal'
}

/* Start of styling */

const headerStyle = css`
	margin-bottom: 0.25rem;
`

const ingressStyle = css`
	color: ${({ theme }) => theme.fadedTextColor};
	white-space: pre-line;
`

const choiceInputStyle = css`
	position: absolute;
	left: 1rem;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;

	&[type='radio'] {
		& + .input-label {
			> svg {
				margin: 0 1rem;
				height: 1rem;
				width: 1rem;
				border: 2px solid #949494;
				border-radius: 2rem;
				flex-shrink: 0;

				.circle {
					fill: transparent;
				}
			}
		}

		&:focus + .input-label {
			> svg {
				outline: none;
				border-color: #4d90fe;
				box-shadow: 0px 0px 5px #4d90fe;
			}
		}

		&:focus:not(:focus-visible) + .input-label {
			> svg {
				border-color: #949494;
				box-shadow: none;
			}
		}

		&:checked {
			& + .input-label {
				> svg {
					flex-shrink: 0;
					.circle {
						fill: ${({ theme }) => theme.secondaryColor};
						border: #949494;
					}
				}
			}

			&:focus:not(:focus-visible) + .input-label {
				> svg {
					border-color: #949494;
					box-shadow: none;
				}
			}
		}
	}

	&[type='checkbox'] {
		& + .input-label {
			> svg {
				margin: 0 1rem;
				height: 1rem;
				width: 1rem;
				border: 2px solid #949494;
				border-radius: 2px;
				flex-shrink: 0;

				.checkmark {
					fill: transparent;
				}

				.checkmark-background {
					fill: transparent;
				}
			}
		}

		&:focus + .input-label {
			> svg {
				outline: none;
				border-color: #4d90fe;
				box-shadow: 0px 0px 5px #4d90fe;
			}
		}

		&:focus:not(:focus-visible) + .input-label {
			> svg {
				border-color: #949494;
				box-shadow: none;
			}
		}

		&:checked {
			& + .input-label {
				> svg {
					flex-shrink: 0;
					border-color: ${({ theme }) => theme.secondaryColor};

					.checkmark {
						fill: ${({ theme }) => theme.textColorForSecondary};
					}

					.checkmark-background {
						fill: ${({ theme }) => theme.secondaryColor};
					}
				}
			}

			&:focus:not(:focus-visible) + .input-label {
				> svg {
					border-color: ${({ theme }) => theme.secondaryColor};
					box-shadow: none;
				}
			}
		}
	}
`

const variantStyles: StyleVariants<ChoiceAlignment> = {
	[ChoiceAlignment.horizontal]: css`
		display: flex;
		flex-grow: 1;
		margin: 0;

		> label {
			margin: 0;
			padding: 1rem 0.5rem;
			align-items: center;

			> svg {
				display: none;
			}
		}
	`,
	[ChoiceAlignment.horizontalSmall]: css`
		margin: 0;
		border-radius: 0;
		width: min-content;

		&:after {
			content: normal;
			border-radius: 0;
		}

		&:first-child {
			border-radius: 7px 0 0 7px;
			overflow: hidden;
		}

		&:last-child {
			border-radius: 0 7px 7px 0;
			overflow: hidden;
		}

		&:not(:last-child) {
			border-right: 1px solid #c0c0c0;
		}
	`,
}

const choiceContainerStyle = css`
	background-color: white;
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 7px;
	z-index: 100;
	gap: 0.75rem;

	&:after {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		top: -1px;
		bottom: -1px;
		border: 1px solid #c0c0c0;
		border-radius: 7px;
		z-index: -100;
	}
`

/* End of styling */
