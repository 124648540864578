import React from 'react'
import { HealthData } from '../../HealthDataTable'

const InputControlOptions = ({ id, providesFor, data }: ControlOptions) => {
	const seenCategories = new Set()
	const filtered = data.filter((entry) => {
		const currentCategory = entry[providesFor]?.toString().toLowerCase()
		const duplicate = seenCategories.has(currentCategory)
		seenCategories.add(currentCategory)
		return !duplicate
	})

	return (
		<datalist id={id}>
			{filtered.map((entry, index) => (
				<option
					key={`${id}_${index}}`}
					value={entry[providesFor]?.toString()}
				/>
			))}
		</datalist>
	)
}

export default InputControlOptions

interface ControlOptions {
	id: string
	providesFor: string
	data: HealthData[]
}
