import * as React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { css } from 'styled-components/macro'

import { useHandlerDispatch } from '../../context/HandlerProvider'

import Button, { ButtonVariant } from '../../components/Button'
import Footer from '../../components/Footer'

import { SurveyStartType } from '../../types'

const SurveyStart: React.FC<SurveyStartProps> = ({
	currentQuestion,
	previouslyAnswered,
	translations,
}) => {
	const handlerDispatch = useHandlerDispatch()
	const [language] = useQueryParam('culture', StringParam)
	const currentLanguage = language || 'en-US'

	const header =
		translations[
			previouslyAnswered
				? currentQuestion.answeredHeaderTextKey
				: currentQuestion.headerTextKey
		]
	const ingress =
		translations[
			previouslyAnswered
				? currentQuestion.answeredIngressTextKey
				: currentQuestion.ingressTextKey
		]

	return (
		<React.Fragment>
			<main>
				<h1>{header}</h1>
				{previouslyAnswered ? (
					<small>
						{translations['LabelLastAnswered']}:{' '}
						{new Date(previouslyAnswered).toLocaleDateString(currentLanguage, {
							hour: 'numeric',
							minute: 'numeric',
						})}
					</small>
				) : null}
				<p
					css={css`
						white-space: pre-line;
					`}
				>
					{ingress}
				</p>
			</main>
			<Footer>
				<Button
					buttonCss={css`
						width: 100%;
					`}
					variant={ButtonVariant.primary}
					onClick={() => handlerDispatch({ type: 'navigateForward' })}
				>
					{translations['ButtonStartTextKey']}
				</Button>
			</Footer>
		</React.Fragment>
	)
}

export default SurveyStart

type SurveyStartProps = {
	previouslyAnswered?: number
	translations: {
		[translationKey: string]: string
	}
	currentQuestion: SurveyStartType
}
