export const getUpdatedTree = <Type extends { id: string }>(
	object: Type,
	oldTree: Type[]
): Type[] => {
	const newTree: Type[] = []

	for (const node of oldTree) {
		let newNode = {
			...node,
		}

		if (object.id === node.id) {
			newNode = object
		} else {
			for (const [key, value] of Object.entries(node)) {
				if (Array.isArray(value)) {
					const updatedNode = getUpdatedTree<Type>(object, value)
					newNode = {
						...newNode,
						[key]: updatedNode,
					}
				}
			}
		}

		newTree.push(newNode)
	}

	return newTree
}
