import { getFormattedChoiceProperties } from './getFormattedChoiceProperties'

import {
	PageAnswer,
	AnswerOption,
	SurveyDefinitionFromServer,
	UserAnswersType,
	SurveyStartType,
	BasicQuestionType,
	ChoicesQuestionType,
	SurveySubmitType,
	isIntroPageType,
	isBasicDetailsPageType,
	isChoicesPageType,
	QuestionAnswerType,
	isQuestionAnswerType,
} from '../types'

const getVariant = (type: string) => {
	if (type === 'IntroPage') return 'SurveyStart'
	else if (type === 'BasicDetailsPage' || type === 'BasicDetailsAnswerPage')
		return 'basic'
	else if (type === 'MultipleChoicePage' || type === 'MultipleChoiceAnswerPage')
		return 'checkbox'
	else if (type === 'RadioButtonPage' || type === 'RadioButtonAnswerPage')
		return 'radio'
	else if (type === 'SliderPage' || type === 'SliderAnswerPage') return 'slider'
	else if (type === 'LastPage') return 'SurveySubmit'
	else return ''
}

const getGender = (gender: number | undefined) => {
	if (gender === 1) return 'male'
	else if (gender === 2) return 'female'
	return 'other'
}

const formatAnswer = (
	answer: PageAnswer,
	choices: AnswerOption[] | undefined = undefined
) => {
	if (answer.$type === 'BasicDetailsAnswerPage') {
		return {
			id: answer.ExternalId,
			questionId: answer.SurveyPageId,
			type: getVariant(answer.$type),
			tags: answer.Tags || '',
			answer: {
				birthday: answer.Birthday || '',
				gender: getGender(answer.Gender) || '',
				weight: answer.Weight || NaN,
				height: answer.Height || NaN,
			},
		}
	} else if (
		answer.$type === 'RadioButtonAnswerPage' ||
		answer.$type === 'MultipleChoiceAnswerPage'
	) {
		const answerArray = answer.Selected?.split(',')

		const checkedMap = choices
			? choices.map((choice) => ({
					id: choice.ExternalId,
					selected: !!answerArray?.find(
						(answerId: string) => choice.ExternalId === answerId
					),
					answers: choice.SubOption?.map((subChoice) => ({
						id: subChoice.ExternalId,
						selected: !!answerArray?.find(
							(answerId: string) => subChoice.ExternalId === answerId
						),
					})),
				}))
			: []

		return {
			id: answer.ExternalId,
			questionId: answer.SurveyPageId,
			type: getVariant(answer.$type),
			tags: answer.Tags || '',
			answer: checkedMap,
		}
	} else if (answer.$type === 'SliderAnswerPage') {
		return {
			id: answer.ExternalId,
			questionId: answer.SurveyPageId,
			type: getVariant(answer.$type),
			tags: answer.Tags || '',
			answer: {
				sliderIndex:
					typeof answer.SliderLevel === 'number' && answer.SliderLevel >= 0
						? answer.SliderLevel
						: 0,
			},
		}
	}
}

export const getPrunedData = (data: string[] | undefined) => {
	if (!data) return null
	const parsedData = data.map((dataEntry: string) => JSON.parse(dataEntry))
	const unPrunedSurveyDefinition: SurveyDefinitionFromServer = parsedData[0]
	const surveyTranslations = parsedData[1]
	const unPrunedUserAnswers: UserAnswersType = parsedData[2]
	const userRole = parsedData[3]

	const prunedData = {
		surveyDefinition: {
			id: unPrunedSurveyDefinition?.ExternalId,
			createdBy: unPrunedSurveyDefinition.CreatedBy,
			name: unPrunedSurveyDefinition?.Name,
			postProcessingRules: unPrunedSurveyDefinition?.PostProcessingRules
				? JSON.parse(unPrunedSurveyDefinition?.PostProcessingRules)
				: { tagRules: [] },
			sourceOrgId: unPrunedSurveyDefinition.SourceOrgId,
			sourceDeptId: unPrunedSurveyDefinition.SourceDeptId,
			questions: unPrunedSurveyDefinition?.SurveyPages.reduce(
				(
					formattedQuestions: Array<
						| SurveyStartType
						| BasicQuestionType
						| ChoicesQuestionType
						| SurveySubmitType
					>,
					surveyPage
				) => {
					if (isIntroPageType(surveyPage)) {
						formattedQuestions.push({
							id: surveyPage?.ExternalId,
							changed: false,
							order: surveyPage?.PageNumber,
							visible: surveyPage.ShowPage,
							headerTextKey: surveyPage.HeaderTextKey,
							nicknameTextKey: surveyPage.NicNameTextKey,
							ingressTextKey: surveyPage?.IngressTextKey,
							choicesVariant: surveyPage.$type,
							answeredHeaderTextKey: surveyPage.AnsweredHeaderTextKey,
							answeredIngressTextKey: surveyPage.AnsweredIngressTextKey,
							hideWhenNotAnswered: surveyPage.HideWhenNotAnswered,
						})
					} else if (isBasicDetailsPageType(surveyPage)) {
						formattedQuestions.push({
							id: surveyPage?.ExternalId,
							changed: false,
							order: surveyPage?.PageNumber,
							visible: surveyPage.ShowPage,
							headerTextKey: surveyPage?.HeaderTextKey,
							nicknameTextKey: surveyPage.NicNameTextKey,
							ingressTextKey: surveyPage?.IngressTextKey,
							choicesVariant: getVariant(surveyPage.$type),
							showGenderQuestion: surveyPage.ShowGenderQuestion,
							showBirthdayQuestion: surveyPage.ShowBirthdayQuestion,
							showWeightQuestion: surveyPage.ShowWeightQuestion,
							showHeightQuestion: surveyPage.ShowHeightQuestion,
							calculateBmi: surveyPage.FindBmi,
							bmiTagRanges: surveyPage.BmiSpecification
								? JSON.parse(surveyPage.BmiSpecification)
								: [],
							showOnlyYear: surveyPage.ShowOnlyYear,
							observationId: surveyPage.ObservationId
						})
					} else if (isChoicesPageType(surveyPage)) {
						formattedQuestions.push({
							id: surveyPage?.ExternalId,
							changed: false,
							order: surveyPage?.PageNumber,
							headerTextKey: surveyPage?.HeaderTextKey,
							nicknameTextKey: surveyPage.NicNameTextKey,
							ingressTextKey: surveyPage?.IngressTextKey,
							imageId: surveyPage?.ImageId,
							videoId: surveyPage?.VideoId,
							choicesVariant: getVariant(surveyPage.$type),
							visible: surveyPage.ShowPage,
							alignment: surveyPage?.VerticalAlign ? 'vertical' : 'horizontal',
							observationId: surveyPage.ObservationId,
							choices: surveyPage?.AnswerOptions?.sort(
								(answerOptionA, answerOptionB) =>
									answerOptionA.Order < answerOptionB.Order ? -1 : 1
							).map((choice) => {
								const choiceProperties = getFormattedChoiceProperties(
									choice.Properties
								)

								return {
									id: choice?.ExternalId,
									order: choice?.Order,
									offset: choice?.PageOffset,
									tags: choice.Tag,
									headerTextKey: choice?.HeaderTextKey,
									nicknameTextKey: surveyPage.NicNameTextKey,
									ingressTextKey: choice?.IngressTextKey,
									imageId: choice?.ImageId,
									properties: choiceProperties,
									subQuestionHeaderTextKey: choice?.SubOptionHeaderTextKey,
									subQuestionIngressTextKey: choice?.SubOptionIngressTextKey,
									choicesVariant: choice.SubOptionType,
									choices: choice?.SubOption?.sort((subChoiceA, subChoiceB) =>
										subChoiceA?.Order < subChoiceB?.Order ? -1 : 1
									).map((subChoice) => {
										const subChoiceProperties = getFormattedChoiceProperties(
											subChoice.Properties
										)

										return {
											id: subChoice?.ExternalId,
											order: subChoice?.Order,
											offset: subChoice?.PageOffset,
											tags: subChoice?.Tag,
											headerTextKey: subChoice?.HeaderTextKey,
											nicknameTextKey: surveyPage.NicNameTextKey,
											ingressTextKey: subChoice?.IngressTextKey,
											imageId: subChoice?.ImageId,
											properties: subChoiceProperties,
										}
									}),
								}
							}),
						})
					} else if (surveyPage.$type === 'LastPage') {
						formattedQuestions.push({
							id: surveyPage?.ExternalId,
							changed: false,
							order: surveyPage?.PageNumber,
							headerTextKey: surveyPage?.HeaderTextKey,
							nicknameTextKey: surveyPage.NicNameTextKey,
							ingressTextKey: surveyPage?.IngressTextKey,
							choicesVariant: 'SurveySubmit',
							visible: surveyPage.ShowPage,
						})
					}

					return formattedQuestions
				},
				[]
			),
			conceptId: unPrunedSurveyDefinition.ConceptId,
		},
		translations: surveyTranslations,
		userAnswers: {
			id: unPrunedUserAnswers?.ExternalId,
			timeStamp: unPrunedUserAnswers?.TimeStamp,
			deleted: unPrunedUserAnswers?.Deleted,
			questionAnswers: unPrunedUserAnswers?.PageAnswers.reduce(
				(formattedQuestionAnswers: QuestionAnswerType[], pageAnswer) => {
					const questionIndex = unPrunedSurveyDefinition.SurveyPages.findIndex(
						(surveyPage) => pageAnswer.SurveyPageId === surveyPage.ExternalId
					)
					const currentQuestion =
						unPrunedSurveyDefinition.SurveyPages[questionIndex]

					if (questionIndex < 0) return formattedQuestionAnswers

					if (isChoicesPageType(currentQuestion)) {
						const formattedQuestionAnswer = formatAnswer(
							pageAnswer,
							currentQuestion.AnswerOptions
						)

						if (isQuestionAnswerType(formattedQuestionAnswer)) {
							formattedQuestionAnswers.push(formattedQuestionAnswer)
						}
					} else {
						const formattedQuestionAnswer = formatAnswer(pageAnswer)

						if (isQuestionAnswerType(formattedQuestionAnswer)) {
							formattedQuestionAnswers.push(formattedQuestionAnswer)
						}
					}

					return formattedQuestionAnswers
				},
				[]
			),
		},
		loggedInRole: userRole?.LoggedInRole,
	}
	return prunedData
}
