export const getObjectById = <Type extends { id: string }>(
	id: string,
	objects: Type[]
): Type | undefined => {
	for (const object of objects) {
		if (object.id === id) {
			return object
		}

		for (const value of Object.values(object)) {
			if (Array.isArray(value)) {
				const nestedObject = getObjectById(id, value)

				if (typeof nestedObject !== 'undefined') {
					return nestedObject
				}
			}
		}
	}

	return undefined
}

export const getObjectByIdWithParent = <Type extends { id: string }>(
	id: string,
	objects: Type[]
): { parentObject?: Type; object?: Type } => {
	for (const object of objects) {
		if (object.id === id) {
			return { object }
		}

		for (const value of Object.values(object)) {
			if (Array.isArray(value)) {
				const nestedObject = getObjectByIdWithParent(id, value)

				if (nestedObject?.object || nestedObject?.parentObject) {
					return {
						parentObject: object,
						object: nestedObject?.object,
						...nestedObject,
					}
				}
			}
		}
	}

	return { parentObject: undefined, object: undefined }
}
