import * as React from 'react'
import { css } from 'styled-components/macro'
import { Dialog } from '@headlessui/react'
import Button, { ButtonVariant } from './Button'

import { ReactComponent as ClearIcon } from '../images/clear-icon.svg'

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
	title,
	open,
	setOpen,
	deptId,
	orgId,
	isCurrentSurvey,
	onConfirm,
}) => {
	const deleteMaster = orgId === 0 && deptId === 0
	const onConfirmDeleteClick = () => {
		if (
			typeof orgId === 'number' &&
			typeof deptId === 'number' &&
			orgId >= 0 &&
			deptId >= 0 &&
			!deleteMaster
		) {
			onConfirm({ orgId, deptId })
		}
		setOpen(false)
	}
	return (
		<Dialog
			css={css`
				position: absolute;
			`}
			open={open}
			onClose={() => setOpen(false)}
		>
			<div
				css={css`
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(0, 0, 0, 0.2);
				`}
				aria-hidden='true'
			/>
			<div
				css={css`
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				`}
			>
				<Dialog.Panel
					css={css`
						background-color: #ffffff;
						border: none;
						max-width: 550px;
						padding: 0;
						overflow: hidden;
						border-radius: 4px;
						box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
					`}
				>
					<div
						css={css`
							background-color: #34373b;
							padding: 1rem;
							display: flex;
							justify-content: space-between;
						`}
					>
						<Dialog.Title
							as='h4'
							css={css`
								margin: 0;
								color: ${({ theme }) => theme.textColorForSecondary};
								font-weight: 500;
							`}
						>
							{title || 'Delete survey'}
						</Dialog.Title>
						<Button
							variant={ButtonVariant.icon}
							buttonCss={css`
								padding: 0;
							`}
							onClick={() => setOpen(false)}
						>
							<ClearIcon
								css={css`
									fill: white;
									height: 24px;
									width: 24px;
								`}
							/>
						</Button>
					</div>
					<div
						css={css`
							padding: 2rem;
						`}
					>
						<p
							css={css`
								margin-top: 0;
							`}
						>
							{`Are you sure you wish to delete the survey for organization ${orgId} and department ${deptId}?${
								isCurrentSurvey
									? ' Your currently loaded survey will be deleted.'
									: ''
							}`}
						</p>
						<div
							css={css`
								display: flex;
								justify-content: flex-end;
								gap: 1rem;
								padding-top: 2rem;
							`}
						>
							<Button onClick={() => setOpen(false)}>Cancel</Button>
							<Button
								variant={ButtonVariant.danger}
								onClick={onConfirmDeleteClick}
							>
								Delete
							</Button>
						</div>
					</div>
				</Dialog.Panel>
			</div>
		</Dialog>
	)
}

export default ConfirmDeleteDialog

type deleteSurveyInput = {
	orgId: number
	deptId: number
}

type ConfirmDeleteDialogProps = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	title?: string
	orgId?: number
	deptId?: number
	isCurrentSurvey: boolean
	onConfirm: (deleteInput: deleteSurveyInput) => void
}
