import * as React from 'react'
import { css } from 'styled-components/macro'
import { useFetch } from 'use-http'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'

import {
	useHandlerState,
	useHandlerDispatch,
} from '../../context/HandlerProvider'

import Button, { ButtonVariant } from '../../components/Button'
import Alert, { AlertVariant } from '../../components/Alert'
import Footer from '../../components/Footer'

import {
	SurveyDefinitionType,
	SurveySubmitType,
	QuestionAnswerType,
	BasicAnswerType,
	isChoiceGroupAnswerType,
	isSliderAnswerType,
} from '../../types'

const parentWindow = window.parent !== window ? window.parent : window.opener

const getExorliveLocation = () => {
	//if (window.location.hostname === 'survey.exorlive.com') {
	//	return process.env.REACT_APP_EXORLIVE_URL
	//} else if (window.location.hostname === 'survey-test.exorlive.com') {
	//	return process.env.REACT_APP_EXORLIVE_API_URL
	//} else {
	//	return '*'
	//}

	return '*'
}

const SurveySubmit: React.FC<SurveySubmitProps> = ({
	translations,
	surveyDefinition,
	currentQuestion,
}) => {
	const handlerDispatch = useHandlerDispatch()
	const { confirmedAnswers } = useHandlerState()

	const [queryParams] = useQueryParams({
		userId: NumberParam,
		orgId: NumberParam,
		deptId: NumberParam,
		hash: StringParam,
		culture: StringParam,
	})

	const { userId, orgId, deptId, hash } = queryParams

	const [requestToSurvey, responseFromSurvey, isSurveyLoading, surveyError] =
		useFetch(process.env.REACT_APP_SURVEY_API_URL, {
			credentials: 'include',
		})

	React.useEffect(() => {
		if (responseFromSurvey.status === 202 && !isSurveyLoading) {
			parentWindow &&
				parentWindow.postMessage({ task: 'suggestions' }, getExorliveLocation())
		}
	}, [responseFromSurvey.status, isSurveyLoading])

	if (!confirmedAnswers) return null

	const getAnswerVariant = (variant: string): string => {
		if (variant === 'radio') return 'RadioButtonAnswerPage'
		else if (variant === 'checkbox') return 'MultipleChoiceAnswerPage'
		else if (variant === 'slider') return 'SliderAnswerPage'
		else if (variant === 'basic') return 'BasicDetailsAnswerPage'
		return ''
	}

	const getGender = (gender: string): number => {
		if (gender === 'male') return 1
		else if (gender === 'female') return 2
		return 0
	}

	const generatePageAnswer = (confirmedAnswer: QuestionAnswerType) => {
		if (
			Array.isArray(confirmedAnswer.answer) &&
			isChoiceGroupAnswerType(confirmedAnswer.answer[0])
		) {
			return {
				$type: getAnswerVariant(confirmedAnswer.type),
				SurveyPageId: confirmedAnswer.questionId,
				Selected: Array.isArray(confirmedAnswer?.tags)
					? confirmedAnswer.answer
							.reduce((result: Array<string>, answer) => {
								if (answer.selected) {
									result.push(answer.id)
								}

								if (answer?.answers) {
									answer.answers.forEach((subAnswer) => {
										if (subAnswer.selected) result.push(subAnswer.id)
									})
								}
								return result
							}, [])
							.join(',')
					: '',
				Tags: Array.isArray(confirmedAnswer?.tags)
					? confirmedAnswer.tags.filter(Boolean).join()
					: '',
				Properties: JSON.stringify(confirmedAnswer.properties) || '',
			}
		} else if (confirmedAnswer.type === 'slider') {
			if (!isSliderAnswerType(confirmedAnswer.answer)) return null

			return {
				$type: getAnswerVariant(confirmedAnswer.type),
				SurveyPageId: confirmedAnswer.questionId,
				SliderLevel: confirmedAnswer.answer.sliderIndex.toString(),
				Tags: confirmedAnswer.tags,
				Properties: JSON.stringify(confirmedAnswer.properties),
			}
		} else if (confirmedAnswer.type === 'basic') {
			const newAnswers = confirmedAnswer.answer as BasicAnswerType
			const answer: {
				$type: string
				SurveyPageId: string
				Properties: string
				Tags: string | Array<string>
				Gender?: number
				Birthday?: string
				Height?: number
				Weight?: number
			} = {
				$type: getAnswerVariant(confirmedAnswer.type),
				SurveyPageId: confirmedAnswer.questionId,
				Properties: '',
				Tags: confirmedAnswer.tags,
			}

			if (newAnswers.gender && getGender(newAnswers.gender) >= 0) {
				answer.Gender = getGender(newAnswers.gender)
				answer.Properties = answer.Properties.concat(
					`Gender:${getGender(newAnswers.gender)};`
				)
			}
			if (newAnswers.birthday) {
				answer.Birthday = newAnswers.birthday
				answer.Properties = answer.Properties.concat(
					`Birthday:${newAnswers.birthday};`
				)
			}
			if (newAnswers.height && newAnswers.height > 0) {
				answer.Height = newAnswers.height
				answer.Properties = answer.Properties.concat(
					`Height:${newAnswers.height};`
				)
			}
			if (newAnswers.weight && newAnswers.weight > 0) {
				answer.Weight = newAnswers.weight
				answer.Properties = answer.Properties.concat(
					`Weight:${newAnswers.weight};`
				)
			}

			if (answer.Properties === '') return null
			return answer
		}
	}

	const formattedSurveyAnswerData = {
		$type: 'AnsweredSurvey',
		OrgId: orgId,
		DeptId: deptId,
		Locked: true, // Not sure what this does [AW]
		PageAnswers: confirmedAnswers
			?.map((confirmedAnswer) => generatePageAnswer(confirmedAnswer))
			.filter(Boolean),
		SurveyId: surveyDefinition.id,
		TimeStamp: Date.now(),
		UserId: userId,
	}

	const sendAnsweredSurvey = () => {
		requestToSurvey.post(
			`/api/answer/${userId}/${orgId}/${deptId}/${hash}`,
			formattedSurveyAnswerData
		)
	}

	const onBackClick = () => {
		handlerDispatch({
			type: 'navigateBack',
		})

		window.scrollTo(0, 0)
	}

	return (
		<React.Fragment>
			<main>
				<div className='submitTextContainer'>
					<h1>{translations[currentQuestion.headerTextKey]}</h1>
					<p
						css={`
							white-space: pre-line;
						`}
					>
						{translations[currentQuestion.ingressTextKey]}
					</p>
					{!isSurveyLoading && surveyError ? (
						<Alert variant={AlertVariant.error}>
							<p>{translations['LabelGenericError']}</p>
						</Alert>
					) : null}
				</div>
			</main>
			<Footer>
				<div
					css={css`
						display: flex;
						justify-content: flex-end;
						margin-bottom: 1rem;
						gap: 1rem;
					`}
				>
					{isSurveyLoading ? (
						<React.Fragment>
							<Button type='button' variant={ButtonVariant.secondary} disabled>
								{translations['ButtonBack']}
							</Button>
							<Button type='button' variant={ButtonVariant.primary}>
								{translations['LabelLoading']}
							</Button>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Button
								type='button'
								variant={ButtonVariant.secondary}
								onClick={onBackClick}
							>
								{translations['ButtonBack']}
							</Button>
							<Button
								variant={ButtonVariant.primary}
								type='button'
								onClick={() => sendAnsweredSurvey()}
							>
								{translations['ButtonFinishKey']}
							</Button>
						</React.Fragment>
					)}
				</div>
			</Footer>
		</React.Fragment>
	)
}

export default SurveySubmit

type SurveySubmitProps = {
	translations: {
		[translationKey: string]: string
	}
	surveyDefinition: SurveyDefinitionType
	currentQuestion: SurveySubmitType
}
