import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'styled-components/macro'
import { highLightStyle } from '../../FormDialog'
import Threshold from '../../Threshold'
import InputControlOptions from './InputControlOptions'
import InfoIcon from '../../../InfoIcon'
import { EditDialogProps } from './types'
import { HealthData } from '../../HealthDataTable'

const ObservationInputEdit = ({
	editedRow,
	editRowData,
	data,
	relationalEditState: state,
	relationalDataHandlers,
}: EditDialogProps) => {
	const [highlightedElement, setHighlightedElement] = useState<string>('')
	const {
		createThresholdArray,
		addThreshold,
		updateThreshold,
		removeThreshold,
	} = relationalDataHandlers
	const { thresholds } = state

	const mapThresholdsToState = useCallback(
		(thresholds: HealthData[]) => {
			if (state.thresholds.length) return thresholds

			if (thresholds.length) {
				thresholds.forEach((threshold, index) => {
					updateThreshold({ threshold, index })
				})

				return thresholds
			}

			if (!thresholds.length) {
				createThresholdArray()
				return thresholds
			}
		},
		[createThresholdArray, state, updateThreshold]
	)

	const groupId = data.find(d => d.id === editedRow.id)?.groupId
	const belongsToGroup = () => (!groupId ? false : true)

	const getExistingThresholds = useCallback(() => {
		const existingThresholds = Object.entries(editedRow).find(
			([key]) => key === 'thresholds'
		)?.[1]

		if (typeof existingThresholds !== 'object')
			throw new Error('Error fetching existing thresholds')
		if (!existingThresholds) return []

		return existingThresholds
	}, [editedRow])

	useEffect(() => {
		const existingThresholds = getExistingThresholds()
		mapThresholdsToState(existingThresholds)
		//eslint-disable-next-line
	}, [])

	return (
		<>
			{Object.entries(editedRow)
				.slice(1)
				.map(([key, value], cellIndex) => {
					const uniqueId = `${key}${cellIndex}`
					let inputType = 'text'
					if (typeof value === 'number' && key === 'interval') {
						inputType = 'number'
					}
					return key === 'type' ? (
						<label
							htmlFor='type'
							key={`${uniqueId}_select`}
							onFocus={() => setHighlightedElement(uniqueId)}
							css={css`
								${highlightedElement === uniqueId ? highLightStyle : ''}
							`}
						>
							Type:
							<select
								title='type'
								id='type'
								onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
									editRowData(key, event)
								}
								required
							>
								<option value={value?.toString()}>
									{value?.toString().toUpperCase()}
								</option>
								<option value='number'>NUMBER</option>
								<option value='percent'>PERCENT</option>
								<option value='seconds'>SECONDS</option>
								<option value='minutes'>MINUTES</option>
								<option value='hours'>HOURS</option>
								<option value='repetitions'>REPETITIONS</option>
								<option value='mmol/l'>MMOL/L</option>
								<option value='kcal'>KCAL</option>
								<option value='strokes/min'>STROKES/MIN</option>
								<option value='meters/second'>SPEED (M/S) </option>
							</select>
						</label>
					) : key === 'thresholds' ? (
						<label
							htmlFor='thresholds_input'
							onFocus={() => setHighlightedElement('thresholds_input')}
							css={css`
								${highlightedElement === 'thresholds_input'
									? highLightStyle
									: ''}
							`}
							key={`${uniqueId}_thresholds`}
						>
							<InfoIcon description='Thresholds on a given min/max range with outcome. Leave max empty if there is no range.' />
							Thresholds
							<br />
							{typeof value === 'object' && (
								<Threshold
									addThreshold={addThreshold}
									removeThreshold={removeThreshold}
									updateThreshold={updateThreshold}
									thresholds={thresholds}
								/>
							)}
						</label>
					) : (
						<label
							htmlFor={`${uniqueId}_input`}
							key={`${uniqueId}_label`}
							onFocus={() => setHighlightedElement(uniqueId)}
							css={css`
								${highlightedElement === uniqueId ? highLightStyle : ''}
							`}
						>
							{key === 'code' && belongsToGroup()
								? `${key.toUpperCase()}: (Locked by group)`
								: `${key.toUpperCase()}:`}
							<input
								title={uniqueId}
								id={`${uniqueId}_input`}
								key={uniqueId}
								type={inputType}
								{...(key === 'category' && {
									list: 'categories',
								})}
								defaultValue={value?.toString().toUpperCase()}
								{...(typeof value === 'boolean' && {
									defaultChecked: value,
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									editRowData(key, event)
								}
								autoFocus={cellIndex === 0}
								required
								{...(inputType === 'number' && {
									step: '0.01',
								})}
								disabled={key === 'code' && belongsToGroup()}
							/>
							{key === 'category' && (
								<InputControlOptions
									id='categories'
									providesFor='category'
									data={data}
								/>
							)}
						</label>
					)
				})}
		</>
	)
}

export default ObservationInputEdit
