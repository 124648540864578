import { css } from 'styled-components/macro'
import OverlayContainer from './OverlayContainer'
import React from 'react'

const ConfirmDeleteRow = ({
	entryId,
	onClose,
	onConfirm,
}: {
	entryId: number | null
	onClose: () => void
	onConfirm: (id: number) => void
}) => {
	if (!entryId) throw new Error('No id found for removal')

	return (
		<OverlayContainer>
			<p>Are you sure you wish to delete this item?</p>
			<div
				css={css`
					display: flex;
					justify-content: space-evenly;
				`}
			>
				<button
					id={`delete-entry-${entryId}`}
					css={css`
						height: 2.5em;
						background-color: rgba(226, 29, 29, 0.8);
						border: 1px solid black;
						border-radius: 3px;
					`}
					onClick={() => onConfirm(entryId)}
				>
					Delete
				</button>
				<button
					css={css`
						height: 2.5em;
					`}
					onClick={onClose}
				>
					Back
				</button>
			</div>
		</OverlayContainer>
	)
}

export default ConfirmDeleteRow
